<template>
  <div>
    <div class="elevation-1">
      <HeaderForm title="Serviço" icon="mdi-message-cog-outline" :id="`${service.id}`" :name="service.title" :onClickBackButton="goToBackList" />
      <v-form ref="form" v-model="valid" :lazy-validation="lazy">
        <v-container fluid>
          <v-row>
            <v-col cols="12" xs="12" md="4">
              <v-text-field
                v-model="service.title"
                label="Titulo"
                outlined
                required
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" md="4">
              <v-text-field
                v-model="service.subject"
                label="Assunto"
                outlined
                required
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" md="4">
              <v-text-field
                v-model="service.from_name"
                label="Remetente"
                outlined
                required
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xs="12" md="4">
              <v-select
                :items="companies"
                v-model="service.companies_id"
                @change="handleChangeCompany"
                item-text="name"
                item-value="id"
                :rules="[rules.required]"
                label="Empresa"
                required
                outlined
              ></v-select>
            </v-col>
            <v-col cols="12" xs="12" md="4">
              <v-select
                :items="appboxes"
                v-model="service.app_boxes_id"
                item-text="title"
                item-value="id"
                :rules="[rules.required]"
                label="Appbox"
                required
                outlined
              ></v-select>
            </v-col>
            <v-col cols="12" xs="12" md="4">
              <v-select
                :items="filterCompany(layouts)"
                v-model="service.layouts_id"
                item-text="title"
                item-value="id"
                :rules="[rules.required]"
                label="Modelo de Email"
                required
                outlined
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-switch v-model="service.enabled" label="Ativo"></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                color="blue darken-3"
                dark
                class="mt-5"
                @click="validate"
              >
                <v-icon left>mdi-send</v-icon> Salvar
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </div>
  </div>
</template>
<script>
import HeaderForm from '@/components/HeaderForm';
import { mapMutations } from 'vuex';
import api from '@/api/service';
import apiCompany from "@/api/company";
import apiLayout from "@/api/layout";
import apiAppbox from "@/api/appbox";
export default {
  components: {
    HeaderForm,
  },
  data: () => ({
    modelService: {
      id: "",
      title: "",
      subject: "",
      from_name: "",
      companies_id: "",
      layouts_id: "",
      app_boxes_id: "",
      enabled: 1
    },
    service: {},
    companies: [],
    appboxes: [],
    layouts: [],
    valid: true,
    lazy: false,
    rules: {
      required: value => !!value || "Campo Obrigatorio"
    },
  }),
  created() {
    this.service = {...this.modelService};
    this.initialize();
  },
  methods: {
    ...mapMutations([
      'showLoading',
      'hideLoading',
      'showAlert',
    ]),
    async initialize() {
      this.showLoading();

      this.getCompanies();
      this.getAppboxes();
      this.getLayouts();

      if (this.$route.params.id) {
        this.getService(this.$route.params.id);
      }

      this.hideLoading();
    },
    async getService(id) {
      try {
        let response = await api.show(id);
        this.service = response.data;
      } catch (error) {
        this.service = {...this.modelService};
        this.showAlert({message: error.message, type: 'error'});
      } finally {
        //this.hideLoading();
      }
    },
    async getCompanies() {
      //this.showLoading();
      try {
        let response = await apiCompany.getAll();
        this.companies = response.filter(item => item.enabled === 1).sort((a,b) => a.name.localeCompare(b.name));
      } catch (error) {
        this.companies = [];
        this.showAlert({message: error.message, type: 'error'});
      } finally {
        //this.hideLoading();
      }
    },
    async getAppboxes() {
      //this.showLoading();
      try {
        let response = await apiAppbox.getAll();
        this.appboxes = response.filter(item => item.enabled === 1).sort((a,b) => a.title.localeCompare(b.title));
      } catch (error) {
        this.appboxes = [];
        this.showAlert({message: error.message, type: 'error'});
      } finally {
        //this.hideLoading();
      }
    },
    async getLayouts() {
      //this.showLoading();
      try {
        let response = await apiLayout.get();
        this.layouts = response.data.filter(item => item.enabled === 1).sort((a,b) => a.title.localeCompare(b.title));
      } catch (error) {
        this.layouts = [];
        this.showAlert({message: error.message, type: 'error'});
      } finally {
        //this.hideLoading();
      }
    },
    goToBackList() {
      this.$router.push(`/admin/servicos`);
    },
    validate() {
      if (this.$refs.form.validate()) {
        let data = {...this.service};
        data.enabled = (this.service.enabled ? 1 : 0);
        if (this.service.id === "") {
          this.post(data);
        } else {
          this.update(this.service.id, data);
        }
      }
    },
    async post(data) {
      this.showLoading();
      try {
        let res = await api.store(data);
        if (res) {
          this.showAlert({message: 'Serviço salvo com sucesso', type: 'success'});
          this.goToBackList();
        }
      } catch (error) {
        this.showAlert({message: error.message, type: 'error'});
      } finally {
        this.hideLoading();
      }
    },
    async update(id, data) {
      console.log(data);
      this.showLoading();
      try {
        let res = await api.update(id, data);
        if (res) {
          this.showAlert({message: 'Serviço atualizado com sucesso', type: 'success'});
          this.goToBackList();
        }
      } catch (error) {
        this.showAlert({message: error.message, type: 'error'});
      } finally {
        this.hideLoading();
      }
    },
    filterCompany(items) {
      if (this.service.companies_id !== '') {
        return items.filter(item => item.companies_id === this.service.companies_id);  
      }
      return items;
    },
    filterCompany(items) {
      if (this.service.companies_id !== '') {
        return items.filter(item => item.companies_id === this.service.companies_id);  
      }
      return items;
    },
    handleChangeCompany() {
      this.service.layouts_id = '';
    }
  }
};
</script>