<template>
    <div>
        <v-data-table :headers="headers" fixed-header multi-sort :loading="loadingTable"
            no-results-text="Nenhum registro encontrado" loading-text="Carregando" class="elevation-1" :footer-props="{
                itemsPerPageOptions: [10, 20, 30, 40, 50, 100, 200],
                itemsPerPageText: 'Itens por página',
                showCurrentPage: true
            }" :items="prepareData(filterData(items))">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>
                        <v-icon>mdi-close-octagon</v-icon>
                        Falhas de Envio
                    </v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <template>
                        <v-btn color="error" dark class="mb-2 mr-1" @click="clearAllJobs">
                            <v-icon>mdi-delete</v-icon> Excluir Todos
                        </v-btn>
                    </template>

                    <template>
                        <v-btn color="warning" dark class="mb-2" @click="retryAllJobs">
                            <v-icon>mdi-restart</v-icon> Reenviar Todos
                        </v-btn>
                    </template>
                </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip buttom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon medium class="mr-2" color="darken-2" @click="showBody(item, 'error')" v-bind="attrs"
                            v-on="on">
                            mdi-eye
                        </v-icon>
                    </template>
                    <span>Visualizar</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon medium class="mr-2" color="teal darken-2" @click="retryJob(item)" v-bind="attrs" v-on="on">
                            mdi-restart
                        </v-icon>
                    </template>
                    <span>Reenviar</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon medium class="mr-2" color="red darken-2" @click="deleteItem(item)" v-bind="attrs" v-on="on">
                            mdi-delete
                        </v-icon>
                    </template>
                    <span>Excluir</span>
                </v-tooltip>
            </template>
        </v-data-table>
        <template>
            <v-dialog v-model="dialog" width="100%" style="min-height: 600px" @click:outside="dialogClose">
                <v-card v-if="itemShow" max-width="100%">
                    <v-card-title>
                        <span class="text-h6">Job: {{ itemShow.uuid || '' }}</span>
                    </v-card-title>
                    <v-card-text v-if="itemShow"
                        style="max-width: 100%; white-space: normal; overflow-wrap: break-word; word-wrap: break-word;">
                        <code style="white-space: pre-wrap;" v-if="itemShow.exception != ''">{{ itemShow.exception }}</code>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="dialogClose">
                            OK
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </template>

        <DialogDelete message="Deseja realmente deletar esse Job?" :dialog="dialogDelete"
            :confirmDialog="handleConfirmDelete" :cancelDialog="handleCancelDelete" />
    </div>
</template>

<script>
import Table from "@/components/Table";
import DialogDelete from "@/components/DialogDelete";
import api from "@/api/job";
import { mapMutations } from "vuex";
export default {
    name: "FailedJobList",
    components: {
        Table,
        DialogDelete,
    },
    data: () => ({
        tab: null,
        ItemsTab: ['job', 'exception'],
        dialog: false,
        showDisabled: false,
        loadingTable: false,
        headers: [
            { text: "ID", value: "id", align: "end" },
            { text: "Fila", value: "queue", align: "start" },
            { text: "Job", value: "uuid", align: "start" },
            { text: "Data Erro", value: "failed_at" },
            {
                text: "Ações",
                value: "actions",
                align: "end",
                sortable: false,
                width: 200,
            },
        ],
        items: [],
        itemShow: [],
        dialogDelete: false,
        _itemDelete: {},
    }),
    created() {
        this.initialize();
    },
    watch: {
        tabs: () => {
            console.log(tabs);
        }
    },
    methods: {
        ...mapMutations(["showLoading", "setDrawer", "hideLoading", "showAlert"]),
        initialize() {
            this.setDrawer(false);
            this.getData();
        },
        async getData() {
            this.loadingTable = true;
            this.showLoading();
            this.items = [];
            try {
                let response = await api.get();
                this.items = response.data;
            } catch (error) {
                this.items = [];
                this.showAlert({ message: error.message, type: "error" });
            } finally {
                this.loadingTable = false;
                this.hideLoading();
            }
        },
        onClickRefreshTable() {
            this.getData();
        },
        filterData(data) {
            return data;
        },
        selectedShowDisabled() {
            this.showDisabled = !this.showDisabled;
        },
        prepareData(data) {
            return data.map((item) => ({
                ...item,
                failed_at: this.dateToBr(item.failed_at),
            }));
        },
        dateToBr(date) {
            if (!date) return date;
            let [fullDate, minutes] = date.split(" ");
            return `${fullDate.split("-").reverse().join("/")} ${minutes}`;
        },
        async retryJob(item) {
            this.showLoading();
            try {
                const resp = await api.retry(item.id);
                this.showAlert({
                    message: "O Job voltou foi enviado para processamento.",
                    type: "success",
                });
                this.getData();
            } catch (error) {
                const { message } = error.data;
                this.showAlert({
                    message: message,
                    type: "error",
                });
            } finally {
                this.hideLoading();
            }
        },
        async retryAllJobs() {
            this.showLoading();
            try {
                const resp = await api.retryAll(0);
                this.showAlert({
                    message: "Todos os Jobs foram enviados para a fila.",
                    type: "success",
                });
                this.getData();
            } catch (error) {
                const { message } = error.data;
                this.showAlert({
                    message: message,
                    type: "error",
                });
            } finally {
                this.hideLoading();
            }
        },
        async clearAllJobs() {
            this.showLoading();
            try {
                const resp = await api.deleteAll(0);
                this.showAlert({
                    message: "Todos os Jobs foram excluidos com sucesso.",
                    type: "success",
                });
                this.getData();
            } catch (error) {
                const { message } = error.data;
                this.showAlert({
                    message: message,
                    type: "error",
                });
            } finally {
                this.hideLoading();
            }
        },

        deleteItem(item) {
            this.dialogDelete = true;
            this._itemDelete = item;
        },
        async handleConfirmDelete() {
            this.dialogDelete = false;
            this.showLoading();

            try {
                let res = await api.delete(this._itemDelete.id);
                this.showAlert({
                    message: "Job deletado com sucesso",
                    type: "success",
                });
                this.getData();
            } catch (error) {
                const { message } = error.data;
                this.showAlert({
                    message: message,
                    type: "error",
                });

            } finally {
                this._itemDelete = {};
                this.hideLoading();
            }
        },
        handleCancelDelete() {
            this.dialogDelete = false;
            this._itemDelete = {};
        },
        dialogClose() {
            this.dialog = false;
            this.itemShow = [];
        },
        showBody(item, error) {
            this.dialog = true;
            this.itemShow = item;
            if (error != '') {
                this.itemShow['showError'] = true;
            }
        },
    },
};
</script>

<style>
.textarea {
    width: 100%;
    min-height: 30rem;
    font-family: "Lucida Console", Monaco, monospace;
    font-size: 0.8rem;
    line-height: 1.2;
}
</style>
