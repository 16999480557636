<template>
  <div>
    <div class="elevation-1">
      <HeaderForm
        title="Vincular Empresas"
        icon="mdi-account-multiple-outline"
        :id="`${user.id}`"
        :name="user.name"
        :onClickBackButton="goToBackList"
      />
      <v-container fluid>
        <v-row>
          <v-col cols="12" xs="12" md="6">
            <v-autocomplete
              :disabled="isUpdating"
              :loading="isLoading"
              :items="hideCompaniesExist(companies)"
              v-model="companySelected"
              no-data-text="Nenhuma Empresa para vincular"
              outlined
              dense
              :error-messages="errorMessageCompanyAutocomplete"
              color="blue-grey lighten-2"
              label="Selecione uma Empresa"
              item-text="name"
              item-value="id"
            >
              <template v-slot:item="data">
                <template>
                  <v-list-item-avatar>
                    {{ data.item.id }}
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.name">
                    </v-list-item-title>
                    <v-list-item-subtitle
                      v-html="data.item.email"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" xs="12" md="2">
            <v-btn color="success" @click="handleAddCompany">
              <v-icon left>mdi-plus</v-icon>
              Adicionar
            </v-btn>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col>
            <simple-table
              title="Empesas Vinculadas"
              :headers="headers"
              :items="user.companies"
              :sortBy="['name']"
            >
              <template v-slot:actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      medium
                      @click="deleteCompany(item)"
                      color="red"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>Desvincular</span>
                </v-tooltip>
              </template>
            </simple-table>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <DialogDelete
      message="Deseja realmente desvincular essa empresa?"
      :dialog="dialogDelete"
      textBntConfirm="Desvincular"
      :confirmDialog="handleConfirmDelete"
      :cancelDialog="handleCancelDelete"
    />
  </div>
</template>
<script>
import HeaderForm from "@/components/HeaderForm";
import SimpleTable from "@/components/SimpleTable";
import DialogDelete from "@/components/DialogDelete";
import { mapMutations } from "vuex";
import api from "@/api/user";
import apiCompany from "@/api/company";

export default {
  components: {
    HeaderForm,
    SimpleTable,
    DialogDelete,
  },
  data: () => ({
    modelUser: {
      id: "",
      name: "",
      email: "",
      companies: [],
      enabled: 1,
    },
    headers: [
      { text: "ID", value: "id", align: "end", width: 120 },
      { text: "Nome", value: "name", align: "start" },
      { text: "Email", value: "email", align: "start" },
      {
        text: "Ações",
        value: "actions",
        align: "end",
        sortable: false,
        width: 200
      }
    ],
    user: {},
    companySelected: null,
    companies: [],
    isLoading: true,
    isUpdating: true,
    dialogDelete: false,
    _companyDelete: {},
    errorMessageCompanyAutocomplete: '',
  }),
  created() {
    this.user = { ...this.modelUser };
    this.initialize();
  },
  methods: {
    ...mapMutations(["showLoading", "hideLoading", "showAlert"]),
    async initialize() {
      if (this.$route.params.id) {
        await this.getUser(this.$route.params.id);
        await this.getCompanies();
      } else {
        this.showAlert({ message: 'Acesso incorreto', type: "error" });
        this.$router.push(`/admin/usuarios`)
      }
    },
    async getUser(id) {
      try {
        this.showLoading();
        let response = await api.show(id);
        this.user = response.data;
      } catch (error) {
        this.user = { ...this.modelUser };
        this.showAlert({ message: error.message, type: "error" });
      } finally {
        this.hideLoading();
      }
    },
    async getCompanies() {
      this.isLoading = true;
      this.isUpdating = true;
      try {
        let response = await apiCompany.getAll();
        response = response.filter(item => item.enabled === 1).sort((a,b) => a.name.localeCompare(b.name));
        this.companies = response;
      } catch (error) {
        this.companies = [];
        this.showAlert({ message: error.message, type: "error" });
      } finally {
        this.isLoading = false;
        this.isUpdating = false;
      }
    },
    goToBackList() {
      this.$router.push(`/admin/usuarios`);
    },
    async handleAddCompany() {
      if (this.companySelected === null) {
        this.errorMessageCompanyAutocomplete = 'Selecione uma Empresa para poder Adicionar';
        // this.showAlert({ message: 'Selecione uma Empresa para poder continuar', type: "info" });
        return;
      }
      this.showLoading();
      let companiesSync = [...this.user.companies.map(item => item.id)];
      companiesSync.push(this.companySelected);
      try {
        let response = await api.postCompanies(this.user.id, {companies: companiesSync});
        this.user.companies = response;
        this.companySelected = null;
        this.showAlert({ message: 'Empresa Vinculada com sucesso', type: "success" });
      } catch (error) {
        this.showAlert({ message: error.message, type: "error" });
      } finally {
        this.hideLoading();
      }
    },
    hideCompaniesExist(data){
      let idsCompanies = this.user.companies.map(item => item.id);
      return data.filter(item => !idsCompanies.includes(item.id));
    },
    deleteCompany(item) {
      this._companyDelete = item;
      this.dialogDelete = true;
    },
    async handleConfirmDelete() {
      this.dialogDelete = false;
      let companiesSync = [...this.user.companies.map(item => item.id).filter(item => item !== this._companyDelete.id)];
      this.showLoading();
      try {
        let response = await api.postCompanies(this.user.id, {companies: companiesSync});
        this.user.companies = response;
        this.companySelected = null;
        this.showAlert({ message: 'Empresa Desvinculada com sucesso', type: "success" });
      } catch (error) {
        this.showAlert({ message: error.message, type: "error" });
      } finally {
        this.hideLoading();
      }
    },
    handleCancelDelete() {
      this.dialogDelete = false;
      this._companyDelete = {};
    }
  },
};
</script>
