<template>
  <div>
    <div class="elevation-1">
      <HeaderForm title="Usuário" icon="mdi-account-multiple-outline" :id="`${user.id}`" :name="user.name" :onClickBackButton="goToBackList" />
      <v-form ref="form" v-model="valid" :lazy-validation="lazy">
        <v-container fluid>
          <v-row>
            <v-col cols="12" xs="12" md="8">
              <v-text-field
                v-model="user.name"
                label="Nome"
                outlined
                required
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" md="4">
               <v-select
                :items="user_types"
                disabled
                v-model="user.user_types_id"
                item-text="name"
                item-value="id"
                :rules="[rules.required]"
                label="Tipo Usuário"
                required
                outlined
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
          </v-row>
          <v-row>
            <v-col cols="12" xs="12" md="8">
              <v-text-field
                v-model="user.email"
                :rules="[rules.required]"
                label="Email"
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" md="4">
              <v-text-field
                v-model="user.password"
                :rules="[rules.required]"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                label="Senha"
                outlined
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <!--
          <v-row>
            <v-col cols="12">
              <v-switch v-model="user.enabled" label="Ativo"></v-switch>
            </v-col>
          </v-row>
          -->
          <v-row>
            <v-col>
              <v-btn
                color="blue darken-3"
                dark
                class="mt-5"
                @click="validate"
              >
                <v-icon left>mdi-send</v-icon> Salvar
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </div>
  </div>
</template>
<script>
import HeaderForm from '@/components/HeaderForm';
import { mapMutations, mapState } from 'vuex';
import api from '@/api/user';
import apiUserType from "@/api/userType";
export default {
  components: {
    HeaderForm
  },
  data: () => ({
    placeholderPassword: '*******',
    modelUser: {
      id: "",
      name: "",
      email: "",
      user_types_id: 2,
    },
    user: {},
    showPassword: false,
    user_types: [],
    valid: true,
    lazy: false,
    rules: {
      required: value => !!value || "Campo Obrigatorio"
    }
  }),
  computed: {
    ...mapState({
      companySelected: state => state.companySelected
    })
  },
  created() {
    this.user = {...this.modelUser};
    this.initialize();
  },
  methods: {
    ...mapMutations([
      'showLoading',
      'hideLoading',
      'showAlert',
    ]),
    async initialize() {
      this.getUserTypes();
      if (this.$route.params.id) {
        this.getUser(this.$route.params.id);
      }
    },
    async getUser(id) {
      this.showLoading();
      try {
        let response = await api.show(id);
        this.user = response.data;
        this.user.password = this.placeholderPassword;
      } catch (error) {
        this.user = {...this.modelUser};
        this.showAlert({message: error.message, type: 'error'});
      } finally {
        this.hideLoading();
      }
    },
    async getUserTypes() {
      this.showLoading();
      try {
        let response = await apiUserType.get();
        this.user_types = response.data;
      } catch (error) {
        this.user_types = [];
        this.showAlert({message: error.message, type: 'error'});
      } finally {
        this.hideLoading();
      }
    },
    goToBackList() {
      this.$router.push(`/usuarios`);
    },
    validate() {
      if (this.$refs.form.validate()) {
        let data = {...this.user};
        if (this.user.id === "") {
          this.post(data);
        } else {
          if (data.password === this.placeholderPassword) {
            delete data.password;
          }
          this.update(this.user.id, data);
        }
      }
      
    },
    async post(data) {
      this.showLoading();
      try {
        let res = await api.store(data);
        if (res) {
          
          // Para adicionar o vinculo com a empresa atual logada
          let companiesSync = [];
          companiesSync.push(this.companySelected.id);
          await api.attachCompany(res.id, {companies: companiesSync});

          this.showAlert({message: 'Usuário salvo com sucesso', type: 'success'});
          this.goToBackList();
        }
      } catch (error) {
        this.showAlert({message: error.message, type: 'error'});
      } finally {
        this.hideLoading();
      }
    },
    async update(id, data) {
      this.showLoading();
      try {
        let res = await api.update(id, data);
        if (res) {
          this.showAlert({message: 'Usuário atualizado com sucesso', type: 'success'});
          this.goToBackList();
        }
      } catch (error) {
        this.showAlert({message: error.message, type: 'error'});
      } finally {
        this.hideLoading();
      }
    }
  }
};
</script>
