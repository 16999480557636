<template>
    <div>
        <div class="elevation-1">
            <v-toolbar flat color="white">
                <v-toolbar-title>
                    <v-icon>mdi-bullhorn-outline</v-icon>
                    Relatório da Campanha: {{ campaign.id }} - {{ campaign.title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn outlined color="grey darken-3" @click="goToBackList">
                    <v-icon left>mdi-chevron-left</v-icon> Voltar
                </v-btn>
            </v-toolbar>
            <v-container fluid>

                <div class="elevation-1">
                    <v-tabs v-model="keyTabReport">
                        <v-tab key="1">
                            <v-icon left>mdi-email-sync-outline</v-icon>
                            Disparos
                        </v-tab>
                        <v-tab key="2">
                            <v-icon left>mdi-email-open-multiple-outline</v-icon>
                            Detalhes do Disparo
                        </v-tab>
                        <v-tab-item>
                            <v-card>
                                <v-card-title>
                                    Disparos
                                    <v-spacer></v-spacer>
                                    <v-text-field v-model="searchActive" append-icon="mdi-magnify" label="Procurar"
                                        single-line hide-details></v-text-field>
                                    <v-divider class="mx-4" inset vertical></v-divider>
                                </v-card-title>
                                <v-data-table :headers="headersShoots" :items="prepareData(shoots)"
                                    :search="searchActive" sort-by="id" :sort-desc="true">
                                    <template v-slot:item.actions="{ item }">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon medium class="mr-2" color="green" v-bind="attrs" v-on="on"
                                                    @click="detail(item)">
                                                    mdi-eye
                                                </v-icon>
                                            </template>
                                            <span>Detalhes</span>
                                        </v-tooltip>
                                    </template>
                                </v-data-table>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item>
                            <v-card>
                                <v-card-title>
                                    Detalhes do Disparo: {{ shoot_selected.sequentialCode }} - {{
                                            dateToBr(shoot_selected.date_shoot)
                                    }}
                                    <v-spacer></v-spacer>
                                    <v-text-field v-model="searchInactive" append-icon="mdi-magnify" label="Procurar"
                                        single-line hide-details></v-text-field>
                                </v-card-title>
                                <v-data-table :headers="headersEmails" :items="prepareDataEmail(emails)"
                                    :search="searchInactive"></v-data-table>
                            </v-card>
                        </v-tab-item>
                    </v-tabs>
                </div>
            </v-container>
        </div>
        <DialogDelete message="Deseja realmente deletar essa Camapnha?" :dialog="dialogDelete"
            :confirmDialog="handleConfirmDelete" :cancelDialog="handleCancelDelete" />
    </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import DialogDelete from "@/components/DialogDelete";
import api from '@/api/campaign';

export default {
    components: {
        DialogDelete
    },
    data: () => ({
        modelCampaign: {
            id: "",
            title: "",
            subject: [],
            from_name: "",
            reply_to: "",
            body: '',
            type: "email",
            enabled: true,
            stopped: false,
            recurrence: {
                type_send: 'unique',
                pattern: 'daily',
                pattern_option: 1,
                interval: 1,
                frequency: 1,
                day: 1,
                week_days: [],
                ordinal: 1,
                month_day: 'monday',
                date: '',
                hour: '',
                start_date: '',
                start_hour: '',
                end_date: '',
                end_hour: '',
            },
            companies_id: '',
            contact_lists_id: "",
            layouts_id: "",
            app_boxes_id: "",
        },
        campaign: {},
        shoot_selected: {},
        keyTabReport: 0,
        searchActive: '',
        headersShoots: [
            { text: 'Cód', align: 'end', value: 'sequentialCode' },
            { text: 'Data Disparo', value: 'date_shootFormat' },
            { text: 'Hora Disparo', value: 'hour_shoot' },
            { text: 'Total Envios', value: 'totalJobs' },
            { text: 'Total Enviado', value: 'processedJobs' },
            { text: "% de Enviados", value: "progress" },
            { text: 'Total Erros', value: 'failedJobs' },
            { text: 'Total Desinscritos', value: 'unsubscribes' },
            { text: 'Total Lidos', value: 'opens' },
            { text: "Ações", value: "actions", align: 'end', sortable: false, width: 200 }
        ],
        shoots: [],
        searchInactive: '',
        headersEmails: [
            { text: 'ID', align: 'end', value: 'id' },
            { text: 'Destinatário', value: 'to' },
            { text: 'Data Envio', value: 'date_send' },
            { text: 'Hora Envio', value: 'hour_send' },
            { text: "Lido", value: "readed_format" },
            { text: "Data Leitura", value: "date_readed_format" },
            { text: "Desinscreveu?", value: "unsubscribe_format" },
            { text: "Data Desinscrito", value: "date_unsubscribe_format" },
            { text: 'Erro', value: 'error' },
        ],
        emails: [],
        dialogDelete: false,
        _itemDelete: {},
    }),
    created() {
        this.initialize();
    },
    computed: {
        ...mapState({
            companySelected: state => state.companySelected
        })
    },
    methods: {
        ...mapMutations([
            "showLoading",
            "hideLoading",
            "showAlert",
            "setDrawer",
        ]),
        async initialize() {
            this.setDrawer(false);
            if (this.$route.params.id) {
                this.getCampaign(this.$route.params.id);
                this.getShoots(this.$route.params.id);
            }
        },
        async getCampaign(id) {
            this.showLoading();
            try {
                let response = await api.show(id);
                this.campaign = response.data;
            } catch (error) {
                this.campaign = { ...this.modelCampaign };
                this.showAlert({ message: error.message, type: "error" });
            } finally {
                this.hideLoading();
            }
        },
        async getShoots(id) {
            this.showLoading();
            try {
                let response = await api.shoots(id);
                this.shoots = response;
            } catch (error) {
                this.showAlert({ message: error.message, type: "error" });
            } finally {
                this.hideLoading();
            }
        },
        async getEmailsShoot(id, shoots_id) {
            this.showLoading();
            try {
                let response = await api.emailsShoot(id, shoots_id);
                this.emails = response;
            } catch (error) {
                this.showAlert({ message: error.message, type: "error" });
            } finally {
                this.hideLoading();
            }
        },
        getColor(stopped) {
            return (stopped === 1 ? 'orange' : 'green');
        },
        getTextEnabled(stopped) {
            return (stopped === 1 ? 'Pausada' : 'Ativa');
        },
        prepareData(data) {
            return data.map(item => ({
                ...item,
                date_shootFormat: this.dateToBr(item.date_shoot).split(" ")[0],
                hour_shoot: this.dateToBr(item.date_shoot).split(" ")[1],
            }));
        },
        prepareDataEmail(data) {
            return data.map(item => ({
                ...item,
                date_send: this.dateToBr(item.created_at).split(" ")[0],
                hour_send: this.dateToBr(item.created_at).split(" ")[1],
                date_readed_format: this.dateToBr(item.date_readed),
                unsubscribe_format: item.unsubscribe === 1 ? 'Sim' : 'Não',
                readed_format: item.readed === 1 ? 'Sim' : 'Não',
                date_unsubscribe_format: this.dateToBr(item.date_unsubscribe),
                error: item.error != null ? 'Sim' : 'Não'
            }));
        },
        dateToBr(date) {
            if (!date) return date;
            let [fullDate, minutes] = date.split(" ");
            return `${fullDate
                .split("-")
                .reverse()
                .join("/")} ${minutes}`;
        },
        dateToBrTDate(date) {
            if (!date) return date;

            let [fullDate, minutes] = date.split("T");
            minutes = minutes.split('.')[0];
            return `${fullDate
                .split("-")
                .reverse()
                .join("/")} ${minutes}`;
        },
        editItem(item) {
            this.$router.push(`/campanhas/${item.id}/editar`);
        },
        deleteItem(item) {
            this.dialogDelete = true;
            this._itemDelete = item;
        },
        async handleConfirmDelete() {
            this.dialogDelete = false;
            this.showLoading();

            try {
                let res = await api.destroy(this._itemDelete.id);
                this.showAlert({
                    message: "Modelo de Email deletado com sucesso",
                    type: "success",
                });
                this.getData();
            } catch (error) {
                if (error.response.status === 400) {
                    this.showAlert({
                        message: error.response.data.message,
                        type: "error",
                    });
                } else {
                    this.showAlert({
                        message: error.message,
                        type: "error",
                    });
                }
            } finally {
                this._itemDelete = {};
                this.hideLoading();
            }
        },
        handleCancelDelete() {
            this.dialogDelete = false;
            this._itemDelete = {};
        },
        async changeStopped(id, stopped) {
            this.showLoading();
            try {
                let res = await api.updateStopped(id, { stopped: stopped });
                if (res) {
                    this.showAlert({
                        message: "Campanha Atualizada com sucesso",
                        type: "success",
                    });
                    this.getData();
                }
            } catch (error) {
                this.showAlert({ message: error.message, type: "error" });
            } finally {
                this.hideLoading();
            }
        },
        goToBackList() {
            this.$router.push("/campanhas");
        },
        detail(item) {
            this.shoot_selected = { ...item };
            this.keyTabReport = 1;
            this.getEmailsShoot(this.campaign.id, item.id);
        }
    }
};
</script>
