import baseApi from "./baseApi";

const endpoint = "token-api";

export default {
    get: page => baseApi.get(endpoint, page),
    show: id => baseApi.show(endpoint, id),
    store: data => baseApi.store(endpoint, data),
    update: (id, data) => baseApi.update(endpoint, id, data),
    updateToken: id => baseApi.post(`${endpoint}/${id}/token-update`),
    destroy: id => baseApi.destroy(endpoint, id),
};
