<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h2>Dashboard</h2>
                <hr />
            </div>
        </div>
        <div class="row">
            <div class="col">
                <v-card elevation="2">
                    <v-card-title>{{ countCampaigns }}</v-card-title>
                    <v-card-subtitle>Campanhas Ativas</v-card-subtitle>
                </v-card>
            </div>
            <div class="col">
                <v-card elevation="2">
                    <v-card-title>{{ countContactLists }}</v-card-title>
                    <v-card-subtitle>Lista de Contatos</v-card-subtitle>
                </v-card>
            </div>
            <!-- <div class="col">
                <v-card elevation="2">
                    <v-card-title>{{ countProducts }}</v-card-title>
                    <v-card-subtitle>Produtos Ativos</v-card-subtitle>
                </v-card>
            </div> -->
        </div>
    </div>
</template>

<script>
import { mapMutations } from "vuex";
import api from '@/api/dashboard';

export default {
    name: 'Dashboard',
    created() {
        document.title = "SGAMail - Dashboard";
        this.initialize();

        if (this.$store.state.isAdmin) {
            this.$router.push('/admin');
        }
    },
    methods: {
        ...mapMutations(["setDrawer", "showLoading", "hideLoading", "isAdmin"]),
        initialize() {
            this.setDrawer(false);
            this.showLoading();
            this.getDashboard();
        },
        async getDashboard() {
            try {
                const response = await api.dashboard();

                const {
                    countCampaigns,
                    countContactLists
                } = response.data;

                this.countCampaigns = countCampaigns;
                this.countContactLists = countContactLists;

                this.hideLoading()
            } catch (error) {
                this.hideLoading()
                console.error(error);
            }
        }
    },
    data: () => ({
        countCampaigns: 0,
        countContactLists: 0,
    })
}
</script>
