<template>
    <v-alert @mouseenter="mouseEnterAlert" @mouseleave="mouseLeaveAlert" border="top" :value.sync="showAlert"
        transition="scale-transition" :type="type">
        {{ text }}
        <template v-slot:append>
            <v-progress-circular v-if="!hover" :rotate="360" :size="28" :width="3" :value="value"
                color="purple lighten-5">
                {{ seconds }}
            </v-progress-circular>
            <v-btn v-if="hover" icon small @click="hideAlert()" color="white">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-alert>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
export default {
    data: () => ({
        value: 100,
        seconds: 10,
        localSeconds: 0,
        hover: false,
    }),
    computed: {
        ...mapState({
            timer: state => state.timerAlert,
            showAlert(state) {
                this.alert = state.alert;
                this.initAlert();
                return state.alert;
            },
            text: state => state.messageAlert,
            type: state => state.typeAlert
        })
    },
    watch: {

    },
    created() {

    },
    methods: {
        ...mapMutations([
            'hideAlert',
        ]),
        initAlert() {
            //cons
            this.seconds = this.timer;
            this.localSeconds = this.timer;
            if (this.alert) {
                this.counterAlertClose();
            }
        },
        mouseEnterAlert() {
            this.hover = true;
            clearInterval(this.closeAlert);
        },
        mouseLeaveAlert() {
            this.hover = false;
            this.counterAlertClose();
        },
        counterAlertClose() {
            this.closeAlert = setInterval(() => {
                this.seconds--;
                this.value = (this.seconds / this.localSeconds) * 100;
                if (this.seconds <= 0) {
                    clearInterval(this.closeAlert);
                    this.hideAlert();
                }
            }, 1000);
        }
    }
}
</script>
