import baseApi from "./baseApi";

const endpoint = "company";

export default {
    get: page => baseApi.get(endpoint, page),
    getAll: () => baseApi.get(`${endpoint}/all`),
    show: id => baseApi.show(endpoint, id),
    store: data => baseApi.store(endpoint, data),
    update: (id, data) => baseApi.update(endpoint, id, data),
    destroy: id => baseApi.destroy(endpoint, id),
    getAppboxes: id => baseApi.get(`${endpoint}/${id}/appbox`),
    postAppboxes: (id, data) => baseApi.store(`${endpoint}/${id}/appbox`, data),
    getProducts: id => baseApi.get(`${endpoint}/${id}/product`),
    postProducts: (id, data) => baseApi.store(`${endpoint}/${id}/product`, data),
    getSmsAppboxes: id => baseApi.get(`${endpoint}/${id}/sms-appbox`),
    postSmsAppboxes: (id, data) => baseApi.store(`${endpoint}/${id}/sms-appbox`, data),
    getUsers: id => baseApi.get(`${endpoint}/${id}/user`),
    postUsers: (id, data) => baseApi.store(`${endpoint}/${id}/user`, data),
    getToken: id => baseApi.post(`${endpoint}/${id}/token`),
    updateToken: id => baseApi.post(`${endpoint}/${id}/token-update`)
};
