<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :search="search"
    fixed-header
    multi-sort
    no-results-text="Nenhum registro encontrado"
    loading-text="Carregando"
    :sort-by="sortBy"
    class="elevation-1"
    :footer-props="{
      itemsPerPageOptions:[20, 30, 40, 50, 100, 200],
      itemsPerPageText: 'Itens por página',
      showCurrentPage: true
    }"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>
          {{title}}
        </v-toolbar-title>
        <v-spacer></v-spacer>
         <v-row align="center">
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Procurar"
            single-line
            hide-details
          ></v-text-field>
        </v-row>
      </v-toolbar>
    </template>
    <template v-slot:footer.page-text="items"> {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }} </template>
    <template v-slot:item.actions="{ item }">
      <slot name="actions" v-bind:item="item"></slot>
    </template>
  </v-data-table>
</template>
<script>
  export default {
    props: {
      title: String,
      headers: Array,
      sortBy: Array,
      items: Array
    },
    data: () => ({ search: '' }),

    computed: { },

    watch: { },

    created () {
    },

    methods: { },
  }
</script>