<template>
    <div>
        <Table title="Token API - Aplicações Externas" icon="mdi-account-multiple-outline" textNewButton="Novo Token"
            linkNewButton="/admin/api-token/novo" :headers="headers" :items="prepareData(filterData(items))"
            :onSelectedShowDisabled="selectedShowDisabled" :refreshTable="onClickRefreshTable" :sortBy="['id']"
            :showDisabled="false" :loading="loadingTable">
            <template v-slot:actions="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon medium class="mr-2" color="blue" @click="editItem(item)" v-bind="attrs" v-on="on">
                            mdi-pencil
                        </v-icon>
                    </template>
                    <span>Editar</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon medium @click="deleteItem(item)" color="red" v-bind="attrs" v-on="on">
                            mdi-delete
                        </v-icon>
                    </template>
                    <span>Deletar</span>
                </v-tooltip>
            </template>
        </Table>
        <DialogDelete message="Deseja realmente deletar esse Token?" :dialog="dialogDelete"
            :confirmDialog="handleConfirmDelete" :cancelDialog="handleCancelDelete" />
    </div>
</template>

<script>
import Table from "@/components/Table";
import DialogDelete from "@/components/DialogDelete";
import api from "@/api/apiToken";
import { mapMutations } from "vuex";
export default {
    name: "UserList",
    components: {
        Table,
        DialogDelete
    },
    data: () => ({
        showDisabled: false,
        loadingTable: false,
        headers: [
            { text: "ID", value: "id", align: "end", width: 120 },
            { text: "Nome", value: "name", align: "start", width: 200 },
            { text: "Token", value: "token", align: "start", cellClass: ['truncate'] },
            { text: "Status", value: "enabled", align: "center" },
            { text: "Ações", value: "actions", align: 'end', sortable: false, width: 200 }
        ],
        items: [],
        dialogDelete: false,
        _itemDelete: {},
    }),
    created() {
        this.initialize();
    },
    methods: {
        ...mapMutations(["showLoading", "setDrawer", "hideLoading", "showAlert"]),
        initialize() {
            this.setDrawer(false);
            this.getData();
        },
        async getData() {
            this.showLoading();
            this.loadingTable = true;
            this.items = [];
            try {
                let response = await api.get();
                this.items = response.data;
            } catch (error) {
                this.items = [];
                this.showAlert({ message: error.message, type: "error" });
            } finally {
                this.loadingTable = false;
                this.hideLoading();
            }
        },
        onClickRefreshTable() {
            this.getData();
        },
        filterData(data) {
            return data;
        },
        selectedShowDisabled() {
            this.showDisabled = !this.showDisabled;
        },
        prepareData(data) {
            return data.map(item => ({
                ...item,
                token: item.api_token,
                created_at: this.dateToBr(item.created_at),
                updated_at: this.dateToBr(item.updated_at)
            }));
        },
        dateToBr(date) {
            if (!date) return date;
            let [fullDate, minutes] = date.split(' ');
            return `${fullDate.split('-').reverse().join('/')} ${minutes}`;
        },
        editItem(item) {
            this.$router.push(`/admin/api-token/${item.id}/editar`);
        },
        deleteItem(item) {
            this.dialogDelete = true;
            this._itemDelete = item;
        },
        async handleConfirmDelete() {
            this.dialogDelete = false;
            this.showLoading();

            try {
                let res = await api.destroy(this._itemDelete.id);
                this.showAlert({
                    message: "Usuário deletado com sucesso",
                    type: "success",
                });
                this.getData();
            } catch (error) {
                if (error.response.status === 400) {
                    this.showAlert({
                        message: error.response.data.message,
                        type: "error",
                    });
                } else {
                    this.showAlert({
                        message: error.message,
                        type: "error",
                    });
                }
            } finally {
                this._itemDelete = {};
                this.hideLoading();
            }
        },
        handleCancelDelete() {
            this.dialogDelete = false;
            this._itemDelete = {};
        },
        validateTypeUser(item) {
            return item.user_types_id !== 1;
        }
    }
};
</script>

<style>
.truncate {
    text-overflow: ellipsis;
    max-width: 200px !important;
    white-space: break;
    word-wrap: break-word;
    overflow: hidden;
}
</style>
