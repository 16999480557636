<template>
  <div>
    <div class="elevation-1">
      <HeaderForm
        title="Vincular Usuários"
        icon="mdi-domain"
        :id="`${company.id}`"
        :name="company.name"
        :onClickBackButton="goToBackList"
      />
      <v-container fluid>
        <v-row>
          <v-col cols="12" xs="12" md="6">
            <v-autocomplete
              :disabled="isUpdating"
              :loading="isLoading"
              :items="hideUsersExist(users)"
              v-model="userSelected"
              no-data-text="Nenhum Usário para vincular"
              outlined
              dense
              :error-messages="errorMessageCompanyAutocomplete"
              color="blue-grey lighten-2"
              label="Selecione um Usuário"
              item-text="name"
              item-value="id"
            >
              <template v-slot:item="data">
                <template>
                  <v-list-item-avatar>
                    {{ data.item.id }}
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.name">
                    </v-list-item-title>
                    <v-list-item-subtitle
                      v-html="data.item.user_types.name"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" xs="12" md="2">
            <v-btn color="success" @click="handleAddUser">
              <v-icon left>mdi-plus</v-icon>
              Adicionar
            </v-btn>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col>
            <SimpleTable
              title="Usuários Vinculados"
              :headers="headers"
              :items="prepareData(company.users)"
              :sortBy="['title']"
            >
              <template v-slot:actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      medium
                      @click="deleteUser(item)"
                      color="red"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>Desvincular</span>
                </v-tooltip>
              </template>
            </SimpleTable>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <DialogDelete
      message="Deseja realmente desvincular esse Usuário?"
      :dialog="dialogDelete"
      textBntConfirm="Desvincular"
      :confirmDialog="handleConfirmDelete"
      :cancelDialog="handleCancelDelete"
    />
  </div>
</template>
<script>
import HeaderForm from "@/components/HeaderForm";
import SimpleTable from "@/components/SimpleTable";
import DialogDelete from "@/components/DialogDelete";
import { mapMutations } from "vuex";
import api from "@/api/company";
import apiUser from "@/api/user";

export default {
  components: {
    HeaderForm,
    SimpleTable,
    DialogDelete,
  },
  data: () => ({
    modalCompany: {
      id: "",
      name: "",
      email: "",
      appboxes: [],
      users: [],
      enabled: 1,
    },
    headers: [
      { text: "ID", value: "id", align: "end", width: 120 },
      { text: "Nome", value: "name", align: "start" },
      { text: "Email", value: "email", align: "start" },
      {
        text: "Ações",
        value: "actions",
        align: "end",
        sortable: false,
        width: 200
      }
    ],
    user: {},
    userSelected: null,
    users: [],
    isLoading: true,
    isUpdating: true,
    dialogDelete: false,
    _userDelete: {},
    errorMessageCompanyAutocomplete: '',
  }),
  created() {
    this.company = { ...this.modalCompany };
    this.initialize();
  },
  methods: {
    ...mapMutations(["showLoading", "hideLoading", "showAlert"]),
    async initialize() {
      if (this.$route.params.id) {
        await this.getCompany(this.$route.params.id);
        await this.getUsers();
      } else {
        this.showAlert({ message: 'Acesso incorreto', type: "error" });
        this.$router.push(`/admin/empresas`)
      }
    },
    async getCompany(id) {
      try {
        this.showLoading();
        let response = await api.show(id);
        this.company = response.data;
      } catch (error) {
        this.company = { ...this.modalCompany };
        this.showAlert({ message: error.message, type: "error" });
      } finally {
        this.hideLoading();
      }
    },
    async getUsers() {
      this.isLoading = true;
      this.isUpdating = true;
      try {
        let response = await apiUser.getAll();
        response = response.filter(item => item.user_types_id === 2).sort((a,b) => a.name.localeCompare(b.name));
        console.log(response);
        this.users = response;
      } catch (error) {
        this.users = [];
        this.showAlert({ message: error.message, type: "error" });
      } finally {
        this.isLoading = false;
        this.isUpdating = false;
      }
    },
    goToBackList() {
      this.$router.push(`/admin/empresas`);
    },
    async handleAddUser() {
      if (this.userSelected === null) {
        this.errorMessageCompanyAutocomplete = 'Selecione um Usuário para poder Adicionar';
        return;
      }
      this.showLoading();
      let usersSync = [...this.company.users.map(item => item.id)];
      usersSync.push(this.userSelected);
      try {
        let response = await api.postUsers(this.company.id, {users: usersSync});
        this.company.users = response;
        this.userSelected = null;
        this.showAlert({ message: 'Usuário Vinculado com sucesso', type: "success" });
      } catch (error) {
        this.showAlert({ message: error.message, type: "error" });
      } finally {
        this.hideLoading();
      }
    },
    hideUsersExist(data){
      let idsUsers = this.company.users.map(item => item.id);
      console.log(data)
      console.log(data.filter(item => !idsUsers.includes(item.id)))
      return data.filter(item => !idsUsers.includes(item.id));
    },
    deleteUser(item) {
      this._userDelete = item;
      this.dialogDelete = true;
    },
    async handleConfirmDelete() {
      this.dialogDelete = false;
      let usersSync = [...this.company.users.map(item => item.id).filter(item => item !== this._userDelete.id)];
      this.showLoading();
      try {
        let response = await api.postUsers(this.company.id, {users: usersSync});
        this.company.users = response;
        this.userSelected = null;
        this.showAlert({ message: 'Usuário Desvinculado com sucesso', type: "success" });
      } catch (error) {
        this.showAlert({ message: error.message, type: "error" });
      } finally {
        this.hideLoading();
      }
    },
    handleCancelDelete() {
      this.dialogDelete = false;
      this._userDelete = {};
    },
    prepareData(data) {
      return data;
      //return data.map(item => ({...item, user_type_name: item.user_types.name}))
    }
  },
};
</script>
