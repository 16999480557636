<template>
  <div>
    <div class="elevation-1">
      <HeaderForm title="Modelo de Whatsapp - Templates" icon="mdi-message-alert-outline" :id="`${layout.id}`" :name="layout.title" :onClickBackButton="goToBackList" />
      <v-form ref="form" v-model="valid" :lazy-validation="lazy">
        <v-container fluid>
          <v-row>
            <v-col cols="12" xs="12" md="8">
              <v-text-field
                v-model="layout.title"
                label="Titulo"
                outlined
                required
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" md="4">
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xs="12" md="8">
              <v-text-field v-model="templateId" label="ID do Template" outlined required :rules="[rules.required]"/>
            </v-col>
            <v-col cols="12" xs="12" md="4"></v-col>
          </v-row>
          <v-row>
            <v-col cols="8" xs="12" lg="8" md="8">
               <v-text-field 
                v-model="field" 
                label="Campo" 
                outlined
               ></v-text-field>
            </v-col>
            <v-col cols="2" md="2">
              <v-btn class="mt-2" color="green darken-1" @click="addField">
                <v-icon left>mdi-plus</v-icon> Adicionar
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="8" xs="12" lg="8" md="8">
              <template v-for="field in fields">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ field.value }}
                      
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon color="error" left title="Excluir" @click="removeField(field.key)"><v-icon>mdi-delete</v-icon></v-btn>
                  </v-list-item-action>
                </v-list-item>
              </template>              
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-switch v-model="layout.enabled" label="Ativo"></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                color="blue darken-3"
                dark
                class="mt-5"
                @click="validate"
              >
                <v-icon left>mdi-send</v-icon> Salvar
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </div>
  </div>
</template>
<script>
import HeaderForm from '@/components/HeaderForm';
import { mapMutations, mapState } from 'vuex';
import api from '@/api/layout-whatsapp';
import apiCompany from "@/api/company";
export default {
  components: {
    HeaderForm,
  },
  data: () => ({
    modelLayout: {
      id: "",
      title: "",
      body: "",
      enabled: 1
    },
    layout: {},
    fields: [],
    companies: [],
    valid: true,
    lazy: false,
    field: "",
    templateId: "",
    rules: {
      required: value => !!value || "Campo Obrigatorio"
    },
    fieldsRules: [
      function(fields) {
        if (fields.length === 0 && fields.length == 0) {
          console.log(fields);
          return "É necessário ao menos um campo.";
        }
        // Se todas as validações passarem, retorne verdadeiro
        return true;
      }
    ]
  }),
  computed: {
    ...mapState({
      companySelected: state => state.companySelected
    }),
  },
  created() {
    this.layout = {...this.modelLayout};
    this.initialize();
  },
  methods: {
    ...mapMutations([
      'showLoading',
      'hideLoading',
      'showAlert',
    ]),
    async initialize() {
      if (this.$route.params.id) {
        this.getLayout(this.$route.params.id);
      }
    },
    async getLayout(id) {
      this.showLoading();
      try {
        let response = await api.show(id);
        
        this.layout = response.data;

        const body = JSON.parse(response.data.body);

        if(body.length > 0 ){
          this.fields = body[0].fields;
          this.templateId = body[0].template_id;
        }

      } catch (error) {
        this.layout = {...this.modelLayout};
        this.showAlert({message: error.message, type: 'error'});
      } finally {
        this.hideLoading();
      }
    },
    goToBackList() {
      this.$router.push(`/modelo-whatsapp`);
    },
    validate() {
      if( !this.$refs.form.validate())
        return;
      
      let data = {...this.layout};
      data.enabled = (this.layout.enabled ? 1 : 0);
      data.body = [{template_id: this.templateId, fields: this.fields}];
      if (this.layout.id === "") {
        data.companies_id = this.companySelected.id;
        this.post(data);
      } else {        
        this.update(this.layout.id, data);
      }
    },
    addField() {
      const field = this.field;

      if( field == "" || field == undefined){
        return;
      }
      
      this.fields = [...this.fields, { key: field, value: field}];
      this.field = '';
      
      return;
    },

    removeField(key) {
      const filtered = this.fields.filter(field => field.key != key);
      this.fields = filtered;
      return;
    },
    async post(data) {
      this.showLoading();
      try {
        let res = await api.store(data);
        if (res) {
          this.showAlert({message: 'Modelo de Whatsapp salvo com sucesso', type: 'success'});
          this.goToBackList();
        }
      } catch (error) {
        this.showAlert({message: error.message, type: 'error'});
      } finally {
        this.hideLoading();
      }
    },
    async update(id, data) {
      this.showLoading();
      try {
        let res = await api.update(id, data);
        if (res) {
          this.showAlert({message: 'Modelo de Whatsapp atualizado com sucesso', type: 'success'});
          this.goToBackList();
        }
      } catch (error) {
        this.showAlert({message: error.message, type: 'error'});
      } finally {
        this.hideLoading();
      }
    }
  }
};
</script>