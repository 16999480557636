<template>
    <div>
        <div class="elevation-1">
            <HeaderForm title="Empresa" icon="mdi-domain" :id="`${company.id}`" :name="company.name"
                :onClickBackButton="goToBackList" />
            <v-form ref="form" v-model="valid" :lazy-validation="lazy">
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" xs="12" md="6">
                            <v-text-field label="CT" v-model="company.ct" :rules="[rules.required, rules.ct]" outlined
                                required></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" md="6">
                            <v-text-field label="CNPJ" :rules="[rules.required, rules.cnpj]" v-model="company.cnpj"
                                outlined required></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" xs="12" md="6">
                            <v-text-field v-model="company.name" label="Nome" outlined required
                                :rules="[rules.required]"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" md="6">
                            <v-text-field v-model="company.email" :rules="[rules.required]" label="Email" outlined
                                required></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row v-if="company.token_company">
                        <v-col cols="12" md="10">
                            <v-text-field v-model="company.token_company" label="Token" outlined readonly
                                filled></v-text-field>
                        </v-col>
                        <v-col>
                            <v-btn color="primary darken-3" large dark class="mt-1" @click="updateToken">
                                <v-icon>mdi-reload</v-icon> Atualizar Token
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-switch v-model="company.enabled" label="Ativo"></v-switch>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn color="blue darken-3" dark class="mt-5" @click="validate">
                                <v-icon left>mdi-send</v-icon> Salvar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </div>
    </div>
</template>
<script>
import HeaderForm from '@/components/HeaderForm';
import { mapMutations } from 'vuex';
import api from '@/api/company';
export default {
    components: {
        HeaderForm
    },
    data: () => ({
        modelCompany: {
            id: "",
            ct: "",
            cnpj: "",
            name: "",
            email: "",
            token_company: "",
            enabled: 1,
            mask: '##.###.###/####-##'
        },
        company: {},
        valid: true,
        lazy: false,
        rules: {
            required: value => !!value || "Campo Obrigatorio",
            ct: value => value.length == 6 || "O CT esta incorreto",
            cnpj: value => value.length == 14 || "O CNPJ esta incorreto"
        }
    }),
    created() {
        this.company = { ...this.modelCompany };
        this.initialize();
    },
    methods: {
        ...mapMutations([
            'showLoading',
            'hideLoading',
            'showAlert',
        ]),
        async initialize() {
            if (this.$route.params.id) {
                this.showLoading();
                try {
                    let response = await api.show(this.$route.params.id);
                    this.company = response.data;
                } catch (error) {
                    this.company = { ...this.modelCompany };
                    this.showAlert({ message: error.message, type: 'error' });
                } finally {
                    this.hideLoading();
                }
            }
        },
        goToBackList() {
            this.$router.push(`/admin/empresas`);
        },
        validate() {
            let data = { ...this.company };
            data.enabled = (this.company.enabled ? 1 : 0);
            if (this.company.id === "") {
                this.post(data);
            } else {
                this.update(this.company.id, data);
            }
        },
        async post(data) {
            this.showLoading();
            try {
                let res = await api.store(data);
                if (res) {
                    this.showAlert({ message: 'Empresa salva com sucesso', type: 'success' });
                    this.goToBackList();
                }
            } catch (error) {
                this.showAlert({ message: error.message, type: 'error' });
            } finally {
                this.hideLoading();
            }
        },
        async update(id, data) {
            this.showLoading();
            try {
                let res = await api.update(id, data);
                if (res) {
                    this.showAlert({ message: 'Empresa atualizada com sucesso', type: 'success' });
                    this.goToBackList();
                }
            } catch (error) {
                this.showAlert({ message: error.message, type: 'error' });
            } finally {
                this.hideLoading();
            }
        },
        async updateToken() {
            this.showLoading();
            const id = this.company.id;

            if (id == "") {
                return;
            }

            try {
                let res = await api.updateToken(id);
                if (res) {
                    if (res.token_company != "") {
                        this.company.token_company = res.token_company;
                        this.showAlert({
                            message: 'Token atualizado com sucesso.',
                            type: 'success',
                        });
                    } else {
                        this.showAlert({
                            message: 'Ocorreu um erro ao atualizar o token, tente novamente.',
                            type: 'error'
                        });
                    }
                }
            } catch (error) {
                this.showAlert({
                    message: error.message,
                    type: 'error'
                });
            } finally {
                this.hideLoading();
            }
        }
    }
};
</script>
