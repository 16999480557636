// Pages of Admin
import DashboardAdmin from '@/views/admin/dashboard/Dashboard';

/**
 * AppBox
 */
import AppboxAdmin from '@/views/admin/appbox/Appbox';
import AppboxAdminForm from '@/views/admin/appbox/AppboxForm';
import AppboxCompanySync from '@/views/admin/appbox/AppboxCompanySync';

import SmsAppboxAdmin from '@/views/admin/appbox/sms/SmsAppbox';
import SmsAppboxAdminForm from '@/views/admin/appbox/sms/SmsAppboxForm';
import SmsAppboxCompanySync from '@/views/admin/appbox/sms/SmsAppboxCompanySync';

/**
 * Company
 */
import CompanyAdmin from '@/views/admin/company/Company';
import CompanyAdminForm from '@/views/admin/company/CompanyForm';
import CompanyAppboxSync from '@/views/admin/company/CompanyAppboxSync';
import CompanyAppboxSmsSync from '@/views/admin/company/CompanyAppboxSmsSync';
import CompanyProductSync from '@/views/admin/company/CompanyProductSync';
import CompanyUserSync from '@/views/admin/company/CompanyUserSync';

/**
 * Layout
 */
import LayoutEmailAdmin from '@/views/admin/layout/LayoutEmail';
import LayoutEmailAdminForm from '@/views/admin/layout/LayoutEmailForm';
import LayoutSmsAdmin from '@/views/admin/layout/LayoutSms';
import LayoutSmsAdminForm from '@/views/admin/layout/LayoutSmsForm';

/**
 * Service
 */
import ServiceAdmin from '@/views/admin/service/Service';
import ServiceAdminForm from '@/views/admin/service/ServiceForm';

/**
 * User
 */
import UserAdmin from '@/views/admin/user/User';
import UserAdminForm from '@/views/admin/user/UserForm';
import UserCompanySync from '@/views/admin/user/UserCompanySync';
/**
 * Produtos
 */
import ProductAdmin from '@/views/admin/product/Product';
import ProductCompanySyncAdmin from '@/views/admin/product/ProductCompanySync';

import ApiToken from '@/views/admin/api-token/ApiToken';
import ApiTokenForm from '@/views/admin/api-token/ApiTokenForm';

import Job from '@/views/admin/job/ListJob';

import Transactions from '@/views/admin/transaction/Transactions';


const routes = [
    {
        path: '/admin',
        redirect: '/admin/dashboard'
    },
    {
        path: '/admin/dashboard',
        name: 'DashboardAdmin',
        component: DashboardAdmin,
        meta: {
            title: 'Dashboard'
        }
    },
    {
        path: '/admin/appbox',
        name: 'AppboxADminList',
        component: AppboxAdmin,
        meta: {
            title: 'App Boxes (E-mail)'
        }
    },
    {
        path: '/admin/appbox/novo',
        name: 'AppboxAdminForm',
        component: AppboxAdminForm,
        meta: {
            title: 'Cadastro de App Box (E-mail)'
        }
    },
    {
        path: '/admin/appbox/:id/editar',
        name: 'AppboxAdminEdit',
        component: AppboxAdminForm,
        meta: {
            title: 'Edição de App Box (E-mail)'
        }
    },
    {
        path: '/admin/appbox/:id/empresas',
        name: 'AppboxCompanySync',
        component: AppboxCompanySync,
        meta: {
            title: 'Vincular Empresa ao App Box (E-mail)'
        }
    },
    // -------------------------------------------

    {
        path: '/admin/sms/appbox',
        name: 'SmsAppboxAdminList',
        component: SmsAppboxAdmin,
        meta: {
            title: 'App Boxes (SMS)'
        }
    },
    {
        path: '/admin/sms/appbox/novo',
        name: 'SmsAppboxAdminForm',
        component: SmsAppboxAdminForm,
        meta: {
            title: 'Cadastro de App Box (SMS)'
        }
    },
    {
        path: '/admin/sms/appbox/:id/editar',
        name: 'SmsAppboxAdminEdit',
        component: SmsAppboxAdminForm,
        meta: {
            title: 'Edição de App Box (SMS)'
        }
    },
    {
        path: '/admin/sms/appbox/:id/empresas',
        name: 'SmsAppboxCompanySync',
        component: SmsAppboxCompanySync,
        meta: {
            title: 'Vincular Empresa ao App Box (SMS)'
        }
    },
    // ----------------------------------------
    {
        path: '/admin/empresas',
        name: 'CompanyAdminList',
        component: CompanyAdmin,
        meta: {
            title: 'Empresas'
        }
    },
    {
        path: '/admin/empresas/novo',
        name: 'CompanyAdminForm',
        component: CompanyAdminForm,
        meta: {
            title: 'Cadastro de Empresa'
        }
    },
    {
        path: '/admin/empresas/:id/editar',
        name: 'CompanyAdminEdit',
        component: CompanyAdminForm,
        meta: {
            title: 'Edição de Empresa'
        }
    },
    {
        path: '/admin/empresas/:id/appbox',
        name: 'CompanyAppboxSync',
        component: CompanyAppboxSync,
        meta: {
            title: 'Vincular AppBox a Empresa'
        }
    },
    {
        path: '/admin/empresas/:id/appbox-sms',
        name: 'CompanyAppboxSmsSync',
        component: CompanyAppboxSmsSync,
        meta: {
            title: 'Vincular AppBox a Empresa'
        }
    },
    {
        path: '/admin/empresas/:id/produtos',
        name: 'CompanyProductSync',
        component: CompanyProductSync,
        meta: {
            title: 'Vincular Produto a Empresa'
        }
    },
    {
        path: '/admin/empresas/:id/usuarios',
        name: 'CompanyUserSync',
        component: CompanyUserSync,
        meta: {
            title: 'Vincular Usuario a Empresa'
        }
    },
    {
        path: '/admin/modelo-email',
        name: 'LayoutEmailAdminList',
        component: LayoutEmailAdmin,
        meta: {
            title: 'Modelos de E-mails'
        }
    },
    {
        path: '/admin/modelo-email/novo',
        name: 'LayoutEmailAdminForm',
        component: LayoutEmailAdminForm,
        meta: {
            title: 'Cadastro de Modelo de E-mail'
        }
    },
    {
        path: '/admin/modelo-email/:id/editar',
        name: 'LayoutEmailAdminEdit',
        component: LayoutEmailAdminForm,
        meta: {
            title: 'Edição de Modelo de E-mail'
        }
    },
    {
        path: '/admin/modelo-sms',
        name: 'LayoutSmsAdminList',
        component: LayoutSmsAdmin,
        meta: {
            title: 'Modelos de SMS'
        }
    },
    {
        path: '/admin/modelo-sms/novo',
        name: 'LayoutSmsAdminForm',
        component: LayoutSmsAdminForm,
        meta: {
            title: 'Cadastro de Modelo de SMS'
        }
    },
    {
        path: '/admin/modelo-sms/:id/editar',
        name: 'LayoutSmsAdminEdit',
        component: LayoutSmsAdminForm,
        meta: {
            title: 'Edição de Modelo de SMS'
        }
    },
    {
        path: '/admin/servicos',
        name: 'ServiceAdminList',
        component: ServiceAdmin,
        meta: {
            title: 'Serviços'
        }
    },
    {
        path: '/admin/servicos/novo',
        name: 'ServiceAdminForm',
        component: ServiceAdminForm,
        meta: {
            title: 'Cadastro de Serviço'
        }
    },
    {
        path: '/admin/servicos/:id/editar',
        name: 'ServiceAdminEdit',
        component: ServiceAdminForm,
        meta: {
            title: 'Edição de Serviço'
        }
    },
    {
        path: '/admin/usuarios',
        name: 'UserAdminList',
        component: UserAdmin,
        meta: {
            title: 'Usuários'
        }
    },
    {
        path: '/admin/usuarios/novo',
        name: 'UserAdminForm',
        component: UserAdminForm,
        meta: {
            title: 'Cadastro de Usuário'
        }
    },
    {
        path: '/admin/usuarios/:id/editar',
        name: 'UserAdminEdit',
        component: UserAdminForm,
        meta: {
            title: 'Edição de Usuário'
        }
    },
    {
        path: '/admin/usuarios/:id/empresas',
        name: 'UserCompanySync',
        component: UserCompanySync,
        meta: {
            title: 'Vincular Empresa ao Usuário'
        }
    },
    {
        path: '/admin/produtos',
        name: 'ProductAdmin',
        component: ProductAdmin,
        meta: {
            title: 'Produtos'
        }
    },
    {
        path: '/admin/produtos/:id/empresas',
        name: 'ProductCompanySync',
        component: ProductCompanySyncAdmin,
        meta: {
            title: 'Vincular Empresas ao Produto',
        }
    },
    {
        path: '/admin/api-token',
        name: 'ApiToken',
        component: ApiToken,
        meta: {
            title: 'Tokens para API'
        }
    },
    {
        path: '/admin/api-token/novo',
        name: 'ApiTokenForm',
        component: ApiTokenForm,
        meta: {
            title: 'Cadastro de Token para API'
        }
    },
    {
        path: '/admin/api-token/:id/editar',
        name: 'ApiTokenFormEdit',
        component: ApiTokenForm,
        meta: {
            title: 'Edição de Token para API'
        }
    },
    {
        path: '/admin/falhas-envio',
        name: 'FailedJobList',
        component: Job,
        meta: {
            title: 'Falhas de Envio'
        }
    },
    {
        path: '/admin/log-disparos',
        name: 'AdminTransactionList',
        component: Transactions,
        meta: {
            title: 'Log de Disparos'
        }
    }
];

export default routes;
