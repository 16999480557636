<template>
    <div>
        <v-row>
            <v-col cols="12" xs="6" md="4">
                <v-select :items="fields_replace" class="selectFieldsReplace" item-text="text" item-value="value"
                    label="Campo para substituição" @change="insertDataEditor" v-model="field_replace"
                    outlined></v-select>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div>
                    <v-textarea label="Mensagem" counter :rules="rules" class="editor" v-model="bodyLayout" outlined
                        placeholder="Coloque aqui o Modelo de SMS"></v-textarea>
                </div>
            </v-col>
        </v-row>
    </div>
</template>
<script>

export default {
    name: 'SmsEditor',
    props: {
        smsBody: String,
        placeholder: {
            type: String,
            default: 'Coloque aqui o Modelo de SMS',
            required: false
        },
        updateBody: Function,
    },
    computed: {
        bodyLayout: {
            // getter
            get: function () {
                return this.smsBody
            },
            // setter
            set: function (newValue) {
                this.updateBody(newValue);
            }
        }
    },
    data: function () {
        return {
            body: "",
            rules: [v => v.length <= 140 || 'Max 140 caracteres'],
            fields_replace: [
                { value: '0', header: "Indentificação", divider: true },
                { value: '{{nome}}', text: "Nome" },
                { value: '{{documento}}', text: "Documento" },
                { value: '{{nascimento}}', text: "Nascimento" },
                { value: '0', header: "Contato", divider: true },
                { value: '{{celular}}', text: "Celular" },
                { value: '{{telefone}}', text: "Telefone" },
                { value: '{{email}}', text: "Email" },
                { value: '{{email2}}', text: "Email Secundario" },
                { value: '0', header: "Endereço", divider: true },
                { value: '{{cep}}', text: "CEP" },
                { value: '{{endereco}}', text: "Endereço" },
                { value: '{{numero}}', text: "Número" },
                { value: '{{complemento}}', text: "Complemento" },
                { value: '{{bairro}}', text: "Bairro" },
                { value: '{{estado}}', text: "Estado" },
                { value: '{{cidade}}', text: "Cidade" },
            ],
            field_replace: '',
        }
    },
    methods: {
        insertDataEditor() {
            const content = ` ${this.field_replace} `;
            this.updateBody(this.smsBody + content);
            this.field_replace = null;
        },
    }
}
</script>
