import axios from "axios";

// const url = process.env.VUE_APP_API_URL;
const url = "https://api.sgamail.com.br/api/";
// const url = 'http://localhost:81/api/'



const HTTP = axios.create({
  baseURL: url,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
    "Accept": "application/json"
  }
});

HTTP.interceptors.response.use(
  function (response) { return response; },
  function (error) {
    if (error.response) {
      if (error.response.status === 401) {
        throw new Error('Acesso nao autorizado');
      }
    }
    throw error.response;
  });

const getAuthorization = () => `Bearer ${getToken()}`;

const getToken = () => localStorage.getItem("user-token") || null;

const getCompanyToken = () => localStorage.getItem('company-token') || null;

// const getToken = () => 'fa8887dad6ba7a200db14ec11188d1041f23baaa95c2ee84bb285244549db1ec'

export { HTTP, url, getAuthorization, getCompanyToken };
