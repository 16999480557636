<template>
    <div>
        <v-data-table :headers="headers" fixed-header multi-sort :loading="loading"
            no-results-text="Nenhum registro encontrado" loading-text="Carregando" :sort-by="sortBy" class="elevation-1"
            :options.sync="options" :footer-props="{
                itemsPerPageOptions: [10, 20, 30, 40, 50, 100, 200],
                itemsPerPageText: 'Itens por página',
                showCurrentPage: true
            }" :server-items-length="totalItems" :items="prepareData(filterData(items.data))">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>
                        <v-icon>mdi-send</v-icon>
                        Log de Disparos
                    </v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-row align="center">
                        <v-checkbox v-if="showDisabled" v-model="selectedShowDisabled" @change="onSelectedShowDisabled"
                            label="Mostrar Inativos" hide-details class="shrink mr-2 mt-0" value="1"></v-checkbox>
                        <v-spacer></v-spacer>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Procurar" single-line
                            hide-details></v-text-field>
                    </v-row>
                </v-toolbar>
            </template>
            <template v-slot:item.status="{ item }">
                <v-chip :color="getColor(item)" dark>
                    {{ item.status }}
                </v-chip>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon medium class="mr-2" color="blue" @click="showBody(item)" v-bind="attrs" v-on="on">
                            mdi-eye
                        </v-icon>
                    </template>
                    <span>Visualizar Mensagem</span>
                </v-tooltip>
            </template>
            <template v-slot:footer.page-text="items">
                {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
            </template>
        </v-data-table>
        <template>
            <v-dialog v-model="dialog" width="600px" style="min-height: 600px" @click:outside="dialogClose">
                <v-card v-if="itemShow">
                    <v-card-title>
                        <span class="text-h5">Para: {{ itemShow.to }}</span>
                    </v-card-title>
                    <v-card-subtitle>
                        <span class="text-h6">Status: {{ itemShow.status }}</span>
                    </v-card-subtitle>
                    <v-divider></v-divider>
                    <v-card-text class="py-8 px-4">
                        <span v-html="itemShow.body"></span>

                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="dialogClose">
                            OK
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </template>
    </div>
</template>

<script>
import Table from "@/components/Table";
import DialogDelete from "@/components/DialogDelete";
import api from "@/api/transaction";
import { mapMutations, mapState } from "vuex";
export default {
    name: "TransactionList",
    components: {
        Table,
        DialogDelete
    },
    data: () => ({
        dialog: false,
        showDisabled: false,
        headers: [
            { text: "ID", value: "id" },
            { text: "Para", value: "to", align: "start" },
            { text: "Status", value: "status", align: "start" },
            { text: "Data de Criação", value: "created_at" },
            { text: "Data de Atualização", value: "updated_at" },
            {
                text: "Ações",
                value: "actions",
                align: "end",
                sortable: false,
                width: 100
            }
        ],
        items: {
            data: [],
        },
        totalItems: 0,
        options: {},
        itemShow: [],
        dialogDelete: false,
        _itemDelete: {},
        loading: false,
        search: '',
        sortBy: [],
    }),
    computed: {
        ...mapState({
            companySelected: state => state.companySelected
        })
    },
    created() {
        document.title = 'SGAMail - Log de Disparos';
        this.initialize();
    },
    watch: {
        options: {
            handler() {
                this.getData();
            },
            deep: true,
        },
    },
    methods: {
        ...mapMutations(["showLoading", "setDrawer", "hideLoading", "showAlert"]),
        initialize() {
            this.setDrawer(false);
            this.getData();
        },
        async getData() {
            this.loading = true;
            this.showLoading();
            this.items = [];
            const { page, itemsPerPage } = this.options;
            try {
                let response = await api.get({ page, itemsPerPage });
                this.items = response.data;
                this.totalItems = response.data.total;
            } catch (error) {
                this.items = [];
                this.showAlert({ message: error.message, type: "error" });
            } finally {
                this.hideLoading();
                this.loading = false;
            }
        },
        onClickRefreshTable() {
            this.getData();
        },
        filterData(data) {
            return data;
        },
        selectedShowDisabled() {
            this.showDisabled = !this.showDisabled;
        },
        prepareData(data) {
            if (data == undefined)
                return [];
            return data.map(item => ({
                ...item,
                status: this.getStatus(item),
                created_at: this.dateToBr(item.created_at),
                updated_at: this.dateToBr(item.updated_at)
            }));
        },
        dateToBr(date) {
            if (!date) return date;
            let [fullDate, minutes] = date.split(" ");
            return `${fullDate
                .split("-")
                .reverse()
                .join("/")} ${minutes}`;
        },
        showBody(item) {
            this.dialog = true;
            this.itemShow = item;
        },
        dialogClose() {
            this.dialog = false;
            this.itemShow = [];
        },
        getColor(item) {
            if (item.status == 'ENTREGUE') {
                return 'green';
            }

            if (item.status == 'REJEITADO' || item.status == 'ERRO') {
                return 'red';
            }

            if (item.type == 'sms') {
                return 'orange';
            } else {
                return 'green';
            }
        },
        getStatus(item) {
            switch (item.status) {
                case 'SENT':
                    return 'ENVIADO';
                    break;
                case 'SEND':
                    return 'ENVIADO';
                    break;
                case 'DELIVERED':
                    return 'ENTREGUE';
                    break;
                case 'REJECTED':
                    return 'REJEITADO';
                    break;
                case 'PROCESSING':
                    return 'EM PROCESSAMENTO';
                    break;
                case 'ERROR':
                    return 'ERRO';
                    break;
                default:
                    return 'SEM STATUS';
                    break;
            }
        }
    }
};
</script>

<style>
</style>
