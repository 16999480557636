<template>
    <div>
        <div class="elevation-1">
            <v-toolbar flat color="white">
                <v-toolbar-title>
                    <v-icon>mdi-bullhorn-outline</v-icon>
                    Campanha
                </v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-toolbar-title v-if="campaign.id">{{ campaign.id }} - {{ campaign.title }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn v-if="(focusStep == 4)" outlined color="blue darken-3" @click="save" class="mr-3">
                    <v-icon left>mdi-send</v-icon> Salvar
                </v-btn>
                <v-btn v-if="(focusStep < 4)" outlined color="blue darken-3" @click="nextStep" class="mr-3">
                    <v-icon left>mdi-send</v-icon> Próximo
                </v-btn>
                <v-btn outlined color="grey darken-3" @click="goToBackList">
                    <v-icon left>mdi-chevron-left</v-icon> Voltar
                </v-btn>
            </v-toolbar>
            <v-form ref="form" v-model="valid" :lazy-validation="lazy">
                <v-container fluid>
                    <v-row class="mb-10">
                        <v-col>
                            <v-stepper non-linear v-model="focusStep">
                                <v-stepper-header>
                                    <v-stepper-step editable step="1">
                                        Tipo de Campanha
                                    </v-stepper-step>

                                    <v-divider></v-divider>
                                    <v-stepper-step step="2" editable>
                                        Geral
                                    </v-stepper-step>

                                    <v-divider></v-divider>

                                    <v-stepper-step step="3" editable>
                                        {{ nameTypeCampaign }}
                                    </v-stepper-step>

                                    <v-divider></v-divider>

                                    <v-stepper-step step="4" editable>
                                        Recorrencia
                                    </v-stepper-step>
                                </v-stepper-header>
                                <br />
                                <v-stepper-items>
                                    <v-stepper-content step="1">
                                        <v-row>
                                            <v-col cols="12" xs="12" md="6" offset="3">
                                                <v-select :items="types" v-model="campaign.type" :rules="[rules.required]"
                                                    label="Tipo de Campanha" @change="changeTypeCampaign(campaign.type)"
                                                    required outlined></v-select>
                                            </v-col>
                                        </v-row>
                                    </v-stepper-content>
                                    <v-stepper-content step="2">
                                        <v-row>
                                            <v-col cols="12" xs="12" md="12">
                                                <v-text-field v-model="campaign.title" label="Titulo" outlined required
                                                    :rules="[rules.required]"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" xs="12" md="6">
                                                <v-select :items="contactLists" v-model="campaign.contact_list_id"
                                                    item-value="id" item-text="name" :rules="[rules.required]"
                                                    @change="$event => selectContactList($event)" label="Lista de Contatos"
                                                    required outlined></v-select>
                                            </v-col>
                                            <v-col cols="12" xs="12" md="6">
                                              <div v-if="(typeCampaign == 'email')">
                                                <v-select :items="appboxes" v-model="campaign.app_box_id"
                                                    item-value="id" :rules="[rules.required]" label="Appbox" required
                                                    outlined>
                                                    <template v-slot:selection="data">
                                                        {{ data.item.title }} -
                                                        {{ data.item.from_address }}
                                                    </template>
                                                    <template v-slot:item="data">
                                                        <template>
                                                            <v-list-item-content>
                                                                <v-list-item-title>
                                                                    {{ data.item.title }} - {{ data.item.from_address }}
                                                                </v-list-item-title>
                                                                <v-list-item-subtitle>
                                                                    {{ data.item.hostname }}
                                                                </v-list-item-subtitle>
                                                            </v-list-item-content>
                                                        </template>
                                                    </template>
                                                </v-select>
                                              </div>
                                              <div v-if="(typeCampaign == 'sms' || typeCampaign == 'whatsapp')">
                                                <v-select :items="appboxessms" v-model="campaign.app_box_id"
                                                    item-value="id" :rules="[rules.required]" label="Appbox" required
                                                    outlined>
                                                    <template v-slot:selection="data">
                                                        {{ data.item.name }} -
                                                        {{ data.item.from }}
                                                    </template>
                                                    <template v-slot:item="data">
                                                        <template>
                                                            <v-list-item-content>
                                                                <v-list-item-title>
                                                                    {{ data.item.name }} - {{ data.item.from }}
                                                                </v-list-item-title>
                                                                <v-list-item-subtitle>
                                                                    {{ data.item.url }}
                                                                </v-list-item-subtitle>
                                                            </v-list-item-content>
                                                        </template>
                                                    </template>
                                                </v-select>
                                              </div>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" xs="12" md="2">
                                                <v-switch v-model="campaign.enabled" label="Ativo"></v-switch>
                                            </v-col>
                                        </v-row>
                                    </v-stepper-content>
                                    <v-stepper-content step="3" v-if="(typeCampaign == 'email')">
                                        <v-row>
                                            <v-col cols="12" xs="12" md="4">
                                                <v-text-field v-model="campaign.subject" label="Assunto" outlined required
                                                    :rules="[rules.required]"></v-text-field>
                                            </v-col>
                                            <v-col cols="12" xs="12" md="4">
                                                <v-text-field v-model="campaign.reply_to" label="Email de Resposta" outlined
                                                    required :rules="[rules.required]"></v-text-field>
                                            </v-col>
                                            <v-col cols="12" xs="12" md="4">
                                                <v-text-field v-model="campaign.from_name" label="Nome do Remetente"
                                                    outlined required :rules="[rules.required]"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" xs="12" md="8">
                                                <v-select :items="layouts" v-model="campaign.layout_id"
                                                    :rules="[rules.required]" item-value="id" item-text="title"
                                                    label="Modelo de Email" @change="handleLayout" required outlined>
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                        <EmailEditor :body="campaign.body" :updateBody="changeCampaignBody"
                                            :required_fields="requiredFields" placeholder="Coloque aqui o Corpo do Email" />
                                    </v-stepper-content>
                                    <v-stepper-content step="3" v-if="(typeCampaign == 'sms')">
                                        <v-row>
                                            <v-col cols="12" xs="12" md="8">
                                                <v-select :items="layouts" v-model="campaign.layout_id"
                                                    :rules="[rules.required]" item-value="id" item-text="title"
                                                    label="Modelo de SMS" @change="handleLayout" required outlined>
                                                </v-select>
                                            </v-col>
                                        </v-row>

                                        <SmsEditor :smsBody="campaign.body" :updateBody="changeCampaignBody"
                                            placeholder="Coloque aqui o Corpo do SMS" />
                                    </v-stepper-content>
                                    <v-stepper-content step="3" v-if="(typeCampaign == 'whatsapp')">
                                        <v-row>
                                            <v-col cols="12" xs="12" md="8">
                                                <v-select :items="templatesWhatsapp" v-model="campaign.layout_id"
                                                    :rules="[rules.required]" item-value="id" item-text="title"
                                                    label="Template Whatsapp" @change="handleLayoutWhatsapp" required outlined>
                                                </v-select>
                                            </v-col>
                                        </v-row>

                                        <v-row>
                                          <v-col cols="12" xs="6" md="6">
                                            <template v-for="(field, key) in fieldsTemplatesWhatsapp">
                                              <v-list-item>
                                                <v-list-item-content>
                                                  <v-list-item-title></v-list-item-title>
                                                  <div style="display:flex; justify-content: space-between;">
                                                      <div> {{ field.value }}</div>
                                                      <div>
                                                        <v-select :items="fields_replace_whatsapp" class="selectFieldsReplace" item-text="text" item-value="value"
                                                          label="Campo para substituição" v-model="fieldsTemplatesWhatsapp[key].field"
                                                          outlined w-full></v-select>
                                                      </div>
                                                    </div>
                                                </v-list-item-content>
                                                <v-list-item-action>
                                                </v-list-item-action>
                                              </v-list-item>
                                            </template> 

                                          </v-col>
                                        </v-row>   
                                    </v-stepper-content>
                                    <v-stepper-content step="4">
                                        <v-row>
                                            <v-col>
                                                <v-radio-group v-model="campaign.recurrence.type_send" row>
                                                    <v-radio label="Disparo Manual" value="manual"></v-radio>
                                                    <v-radio label="Disparo Unico" value="unique"></v-radio>
                                                    <v-radio label="Disparo Recorrente" value="recurrence"></v-radio>
                                                </v-radio-group>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="campaign.recurrence.type_send === 'unique'">
                                            <v-col cols="12" xs="12" md="3" lg="2">
                                                <v-text-field label="Data de Envio" type="date" :rules="[rules.date]"
                                                    v-model="campaign.recurrence.date" dense outlined></v-text-field>
                                            </v-col>
                                            <v-col cols="12" xs="12" md="3" lg="2">
                                                <v-text-field label="Hora de Envio" type="time" dense outlined
                                                    v-model="campaign.recurrence.hour" :rules="[rules.hour]"></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="campaign.recurrence.type_send === 'recurrence'" class="ml-3">
                                            <v-col>
                                                <v-card elevation="2" class="px-10 mb-10">
                                                    <v-row>
                                                        <v-col cols="12" xs="12" md="3">
                                                            <p>Padrão de Recorrencia</p>
                                                            <v-radio-group v-model="campaign.recurrence.pattern">
                                                                <v-radio label="Diario" value="daily"></v-radio>
                                                                <v-radio label="Semanal" value="weekly"></v-radio>
                                                                <v-radio label="Mensal" value="monthly"></v-radio>
                                                            </v-radio-group>
                                                        </v-col>
                                                        <v-col md="1">
                                                            <v-divider class="mx-4" vertical></v-divider>
                                                        </v-col>
                                                        <v-col col="12" xs="12" md="8"
                                                            v-if="campaign.recurrence.pattern == 'daily'">
                                                            <v-row>
                                                                <v-col>
                                                                    <v-radio-group dense mandatory
                                                                        v-model="campaign.recurrence.frequency">
                                                                        <v-radio value="1">
                                                                            <template v-slot:label>
                                                                                A cada
                                                                                <v-text-field class="mx-4 field-xs"
                                                                                    v-model="campaign.recurrence.interval"
                                                                                    label="" dense outlined
                                                                                    hide-details></v-text-field>
                                                                                Dias
                                                                            </template>
                                                                        </v-radio>
                                                                    </v-radio-group>
                                                                </v-col>
                                                            </v-row>
                                                        </v-col>
                                                        <v-col col="12" xs="12" md="8"
                                                            v-if="campaign.recurrence.pattern === 'weekly'">
                                                            <v-row>
                                                                <v-col>
                                                                    <v-radio-group dense mandatory
                                                                        v-model="campaign.recurrence.frequency">
                                                                        <v-radio value="1">
                                                                            <template v-slot:label>
                                                                                A cada
                                                                                <v-text-field class="mx-4 field-xs"
                                                                                    v-model="campaign.recurrence.interval"
                                                                                    label="" dense outlined
                                                                                    hide-details></v-text-field>
                                                                                Semanas
                                                                            </template>
                                                                        </v-radio>
                                                                    </v-radio-group>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row class="mt-0">
                                                                <v-col>
                                                                    <p class="v-label theme--light">Quais dias da
                                                                        semana?</p>
                                                                </v-col>
                                                            </v-row>
                                                            <v-row class="mt-0">
                                                                <v-col>
                                                                    <v-checkbox class="mt-0"
                                                                        v-model="campaign.recurrence.week_days"
                                                                        label="Segunda" value="monday" hide-details>
                                                                    </v-checkbox>
                                                                </v-col>
                                                                <v-col>
                                                                    <v-checkbox class="mt-0"
                                                                        v-model="campaign.recurrence.week_days"
                                                                        label="Terça" value="tuesday" hide-details>
                                                                    </v-checkbox>
                                                                </v-col>
                                                                <v-col>
                                                                    <v-checkbox class="mt-0"
                                                                        v-model="campaign.recurrence.week_days"
                                                                        label="Quarta" value="wednesday" hide-details>
                                                                    </v-checkbox>
                                                                </v-col>
                                                                <v-col>
                                                                    <v-checkbox class="mt-0"
                                                                        v-model="campaign.recurrence.week_days"
                                                                        label="Quinta" value="thursday" hide-details>
                                                                    </v-checkbox>
                                                                </v-col>
                                                                <v-col>
                                                                    <v-checkbox class="mt-0"
                                                                        v-model="campaign.recurrence.week_days"
                                                                        label="Sexta" value="friday" hide-details>
                                                                    </v-checkbox>
                                                                </v-col>
                                                                <v-col>
                                                                    <v-checkbox class="mt-0"
                                                                        v-model="campaign.recurrence.week_days"
                                                                        label="Sabado" value="saturday" hide-details>
                                                                    </v-checkbox>
                                                                </v-col>
                                                                <v-col>
                                                                    <v-checkbox class="mt-0"
                                                                        v-model="campaign.recurrence.week_days"
                                                                        label="Domingo" value="sunday" hide-details>
                                                                    </v-checkbox>
                                                                </v-col>
                                                            </v-row>
                                                        </v-col>
                                                        <v-col col="12" xs="12" md="8"
                                                            v-if="campaign.recurrence.pattern === 'monthly'">
                                                            <v-row>
                                                                <v-col>
                                                                    <v-radio-group dense mandatory
                                                                        v-model="campaign.recurrence.frequency">
                                                                        <v-radio value="1">
                                                                            <template v-slot:label>
                                                                                Dia
                                                                                <v-text-field class="mx-4 field-xs" label=""
                                                                                    v-model="campaign.recurrence.day" dense
                                                                                    outlined hide-details>
                                                                                </v-text-field>
                                                                                de cada
                                                                                <v-text-field class="mx-4 field-xs"
                                                                                    v-model="campaign.recurrence.interval"
                                                                                    label="" dense outlined
                                                                                    hide-details></v-text-field>
                                                                                mês(es)
                                                                            </template>
                                                                        </v-radio>
                                                                        <v-radio value="2">
                                                                            <template v-slot:label>
                                                                                O
                                                                                <v-select :items="ordinal_numbers"
                                                                                    v-model="campaign.recurrence.ordinal"
                                                                                    class="mx-4 field-md" outlined dense
                                                                                    hide-details></v-select>
                                                                                <v-select :items="days_week"
                                                                                    v-model="campaign.recurrence.month_day"
                                                                                    class="mx-4 field-md" outlined dense
                                                                                    hide-details></v-select>
                                                                                de cada
                                                                                <v-text-field class="mx-4 field-xs"
                                                                                    v-model="campaign.recurrence.interval"
                                                                                    label="" dense outlined
                                                                                    hide-details></v-text-field>
                                                                                mês(es)
                                                                            </template>
                                                                        </v-radio>
                                                                    </v-radio-group>
                                                                </v-col>
                                                            </v-row>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col col="12" xs="12" md="12" class="mt-5">
                                                            <v-row>
                                                                <v-col col="12" xs="12" md="4">
                                                                    <v-text-field label="Iniciar Em" type="date"
                                                                        :rules="[rules.date]"
                                                                        v-model="campaign.recurrence.start_date" outlined
                                                                        dense>
                                                                    </v-text-field>
                                                                </v-col>
                                                                <v-col col="12" xs="12" md="2">
                                                                    <v-text-field label="Hora" type="time"
                                                                        v-model="campaign.recurrence.start_hour"
                                                                        :rules="[rules.hour]" dense outlined></v-text-field>
                                                                </v-col>
                                                                <v-col col="12" xs="12" md="4">
                                                                    <v-text-field label="Terminar Em" type="date"
                                                                        :rules="[rules.date]"
                                                                        v-model="campaign.recurrence.end_date" outlined
                                                                        dense>
                                                                    </v-text-field>
                                                                </v-col>
                                                                <v-col col="12" xs="12" md="2">
                                                                    <v-text-field label="Hora" type="time"
                                                                        v-model="campaign.recurrence.end_hour"
                                                                        :rules="[rules.hour]" dense outlined></v-text-field>
                                                                </v-col>
                                                            </v-row>
                                                        </v-col>
                                                    </v-row>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-stepper-content>
                                </v-stepper-items>
                            </v-stepper>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </div>
    </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import api from "@/api/campaign";
import apiContactList from "@/api/contact-list";
import apiLayout from "@/api/layout";
import apiAppbox from "@/api/appbox";
import apiAppboxSms from "../../api/appbox-sms";
import apiLayoutWhatsapp from "../../api/layout-whatsapp";
import HeaderForm from "@/components/HeaderForm";
import SimpleTable from "@/components/SimpleTable";
import DateTextField from '@/components/DateTextField';
import EmailEditor from "@/components/EmailEditor";
import SmsEditor from "@/components/SmsEditor";

export default {
    components: {
        HeaderForm,
        EmailEditor,
        SimpleTable,
        DateTextField,
        SmsEditor,
    },
    data: () => ({
        modelCampaign: {
            id: "",
            title: "",
            subject: "",
            from_name: "",
            reply_to: "",
            body: "",
            type: "email",
            enabled: true,
            stopped: false,
            recurrence: {
                type_send: 'manual',
                pattern: 'daily',
                pattern_option: 1,
                interval: 1,
                frequency: 1,
                day: 1,
                week_days: [],
                ordinal: 1,
                month_day: 'monday',
                date: '',
                hour: '',
                start_date: '',
                start_hour: '',
                end_date: '',
                end_hour: '',
            },
            company_id: "",
            contact_list_id: "",
            layout_id: "",
            app_box_id: "",
        },
        appboxes: [],
        appboxessms: [],
        contactLists: [],
        layouts: [],
        templatesWhatsapp: [],
        fieldsTemplatesWhatsapp: [],
        AllLayouts: [],
        campaign: {},
        types: ["email", "sms", "whatsapp"],
        nameTypeCampaign: "Modelo de Email",
        typeCampaign: "email",
        focusStep: 1,
        valid: true,
        lazy: false,
        requiredFields: [],
        rules: {
            required: value => !!value || "Campo Obrigatorio",
            rulesStep1: true,
            hour: value => {
                if (value.length < 5) return true;
                let isValidHour = /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/.test(value);
                return isValidHour ? true : 'Hora invalida';
            },
            date: value => {
                if (new Date(value) == 'Invalid Date')
                    return "Data Invalida";
                else
                    return true;
            },
        },
        ordinal_numbers: [
            { value: 1, text: 'Primeiro(a)' },
            { value: 2, text: 'Segundo(a)' },
            { value: 3, text: 'Terceiro(a)' },
            { value: 4, text: 'Quarto(a)' },
            { value: 5, text: 'Quinto(a)' },
        ],
        days_week: [
            { value: 'monday', text: 'Segunda' },
            { value: 'tuesday', text: 'Terça' },
            { value: 'wednesday', text: 'Quarta' },
            { value: 'thursday', text: 'Quinta' },
            { value: 'friday', text: 'Sexta' },
            { value: 'saturday', text: 'Sabado' },
            { value: 'sunday', text: 'Domingo' },
        ],
        email: true,
        fields_replace_whatsapp: [
                { value: '0', header: "Indentificação", divider: true },
                { value: '{{nome}}', text: "Nome" },
                { value: '{{documento}}', text: "Documento" },
                { value: '{{nascimento}}', text: "Nascimento" },
                { value: '0', header: "Contato", divider: true },
                { value: '{{celular}}', text: "Celular" },
                { value: '{{telefone}}', text: "Telefone" },
                { value: '{{email}}', text: "Email" },
                { value: '{{email2}}', text: "Email Secundario" },
                { value: '0', header: "Endereço", divider: true },
                { value: '{{cep}}', text: "CEP" },
                { value: '{{endereco}}', text: "Endereço" },
                { value: '{{numero}}', text: "Número" },
                { value: '{{complemento}}', text: "Complemento" },
                { value: '{{bairro}}', text: "Bairro" },
                { value: '{{estado}}', text: "Estado" },
                { value: '{{cidade}}', text: "Cidade" },
            ],
            field_replace_whatsapp: '',
    }),
    computed: {
        ...mapState({
            companySelected: state => state.companySelected
        })
    },
    created() {
        this.campaign = { ...this.modelCampaign };
        this.initialize();
    },
    methods: {
        ...mapMutations(["showLoading", "hideLoading", "showAlert"]),
        async initialize() {
            await this.getAppboxes();
            await this.getLayouts();
            await this.getContactLists();

            if (this.$route.params.id) {
                await this.getCampaign(this.$route.params.id);
                this.selectContactList(this.campaign.contact_list_id);
            }
            this.getAppboxes();
            this.getAppboxesSms();
            this.getLayouts();
            this.getLayoutWhatsapp();
            this.getContactLists();
        },
        async getCampaign(id) {
            this.showLoading();
            try {
                let response = await api.show(id);
                if( response.data.type == 'whatsapp'){
                  this.fieldsTemplatesWhatsapp = JSON.parse(response.data.body);                  
                }
                this.campaign = response.data;
                this.changeTypeCampaign(this.campaign.type);
            } catch (error) {
                this.campaign = { ...this.modelCampaign };
                this.showAlert({ message: error.message, type: "error" });
            } finally {
                this.hideLoading();
            }
        },
        async getAppboxes() {
            this.showLoading();
            try {
                let response = await apiAppbox.getAll();
                this.appboxes = response.filter(item => item.enabled == 1).sort((a, b) => a.title.localeCompare(b.title));

            } catch (error) {
                this.showAlert({ message: error.message, type: "error" });
            } finally {
                this.hideLoading();
            }
        },
        async getAppboxesSms(){
          try {
                let response = await apiAppboxSms.getAll();
                this.appboxessms = response.filter(item => item.enabled == 1).sort((a, b) => a.title.localeCompare(b.title));

            } catch (error) {
                this.showAlert({ message: error.message, type: "error" });
            } finally {
                this.hideLoading();
            }
        },
        selectContactList(id) {
            const contactListSelected = this.contactLists.filter(item => item.id == id);
            let fields = JSON.parse(contactListSelected[0].required_fields);
            this.requiredFields = fields.filter(item => item.visible === true);
        },
        async getLayouts() {
            this.showLoading();
            try {
                let response = await apiLayout.getAll();
                this.AllLayouts = response.filter(item => item.enabled == 1).sort((a, b) => a.title.localeCompare(b.title));
                this.listLayouts();
            } catch (error) {
                this.showAlert({ message: error.message, type: "error" });
            } finally {
                this.hideLoading();
            }
        },
        async getContactLists() {
            this.showLoading();
            try {
                let response = await apiContactList.getAll();
                this.contactLists = response.filter(item => item.enabled == 1).sort((a, b) => a.name.localeCompare(b.name));
            } catch (error) {
                this.showAlert({ message: error.message, type: "error" });
            } finally {
                this.hideLoading();
            }
        },
        async getLayoutWhatsapp(){
          try {
            let response = await apiLayoutWhatsapp.get();
            this.templatesWhatsapp = response.data;
          }catch(error){
            this.showAlert({message: error.message, type: "error"});
          } finally {
            this.hideLoading();
          }
        },
        goToBackList() {
            this.$router.push("/campanhas");
        },
        handleLayout() {
            if (this.campaign.layout_id === "") {
                this.campaign.body = '';
            } else {
                let layoutSelected = this.layouts.find(item => item.id === this.campaign.layout_id);
                this.campaign.body = layoutSelected.body;
            }
        },
        handleLayoutWhatsapp() {
          if( this.campaign.layout_id === "") {
            this.campaign.body = '';
          }else{
            let layoutSelected = this.templatesWhatsapp.find(item => item.id === this.campaign.layout_id);
            let body = JSON.parse(layoutSelected.body);
            this.fieldsTemplatesWhatsapp = body[0].fields;
          }
        },
        async save() {
            if (this.validSave()) {
                if (this.$route.params.id) {
                    this.update(this.$route.params.id, this.campaign);
                } else {
                    this.campaign.company_id = this.companySelected.id;
                    this.post(this.campaign);
                }
            }
        },
        async post(data) {
            this.showLoading();
            try {
                let res = await api.store(data);
                if (res) {
                    this.showAlert({
                        message: "Campanha salva com sucesso",
                        type: "success",
                    });
                    this.goToBackList();
                }
            } catch (error) {
                this.showAlert({ message: error.message, type: "error" });
            } finally {
                this.hideLoading();
            }
        },
        async update(id, data) {
            this.showLoading();
            try {
                let res = await api.update(id, data);
                if (res) {
                    this.showAlert({
                        message: "Campanha atualizada com sucesso",
                        type: "success",
                    });
                    this.goToBackList();
                }
            } catch (error) {
                this.showAlert({ message: error.message, type: "error" });
            } finally {
                this.hideLoading();
            }
        },
        listLayouts() {
            this.layouts = this.AllLayouts.filter(item => (item.layout_type == this.typeCampaign));
        },
        changeTypeCampaign(type) {
            if (type == 'email') {
                this.typeCampaign = 'email';
                this.nameTypeCampaign = 'Modelo de Email';
            }

            if (type == 'sms') {
                this.typeCampaign = 'sms';
                this.nameTypeCampaign = 'Modelo de SMS';
            }

            if( type == 'whatsapp') {
              this.typeCampaign = 'whatsapp';
              this.nameTypeCampaign = 'Template Whatsapp'
            }

            this.listLayouts();
        },
        validSave() {
            let error = "";
            let step = null;
            const { type } = this.campaign;
            if (this.focusStep == 1) {
                step = 1;
                if (type == "") {
                    error = "Selecione o tipo de Campanha";
                }
            }

            if (this.focusStep == 2) {
                const { title, contact_list_id, app_box_id } = this.campaign;
                step = 2;

                if (title == "") {
                    error = "Preencha o título";
                } else if (contact_list_id == "") {
                    error = "Selecione a Lista de Contatos";
                } else if (app_box_id == "" && type == "email") {
                    error = "Selecione a Appbox"
                }

            }

            if (this.focusStep == 3) {
                const { subject, reply_to, from_name, layout_id, body } = this.campaign;
                step = 3;
                if (subject == "" && type == 'email') {
                    error = "Preencha o Assunto";
                } else if (reply_to == "" && type == 'email') {
                    error = "Preencha o E-mail de Resposta";
                } else if (from_name == "" && type == 'email') {
                    error = "Preencha o Nome do Remetente";
                } else if (layout_id == "") {
                    error = "Selecione o Layout da Mensagem";
                } else if (body == "" && type != 'whatsapp') {
                    error = "Preencha o Corpo da Mensagem";
                }
            }

            if (this.focusStep == 4) {
                step = 4;
                const { type_send, date, hour } = this.campaign.recurrence;

                if (type_send == "unique") {
                    let today = new Date().getTime();;
                    let afterDate = new Date(date + ' ' + hour).getTime();
                    if (date == "") {
                        error = "Preencha a Data do disparo único";
                    } else if (hour == '') {
                        error = "Preencha a Hora do disparo único";
                    } else if (today > afterDate) {
                        error = "A data não pode ser menor ou igual a atual.";
                    }
                } else if (type_send == "recurrence") {
                    const { start_date, start_hour, end_date, end_hour, pattern, interval, week_days, day } = this.campaign.recurrence;
                    if (start_date == "" || start_hour == "" || end_date == "" || end_hour == "") {
                        error = "Preencha a Data e Hora inicial e final da campanha";
                    } else if ((pattern == "daily" || pattern == "weekly" || pattern == "montly") && (interval == "" || interval == 0)) {
                        this.campaign.recurrence.interval = 1;
                    } else if (pattern == 'weekly' && week_days.length == 0) {
                        error = "Selecione os dias da semana para enviar a campanha";
                    } else if (day == "" || day == 0) {
                        this.campaign.recurrence.day = 1;
                    }
                }
            }

            if (error !== "") {
                this.showAlert({
                    message: error,
                    type: "error"
                });
                this.focusStep = step;
                return false;
            }

            if( type == 'whatsapp'){
              this.campaign.body = [...this.fieldsTemplatesWhatsapp];
            }

            return true;
        },
        handleInputDate(val) {
            this.campaign.recurrence.date = val;
        },
        handleInputStartDate(val) {
            l;
        },
        handleInputEndDate(val) {
            this.campaign.recurrence.end_date = val;
        },
        changeCampaignBody(newValue) {
            this.campaign.body = newValue;
        },
        nextStep() {
            if (this.validSave()) {
                this.focusStep++;
            }
        }
    }
};

</script>
<style  scoped>
.field-xs {
    width: 50px;
    max-width: 60px;
}

.field-md {
    width: 140px;
    max-width: 160px;
}
</style>
