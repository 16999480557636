<template>
    <v-container style="height: 90vh;">
        <v-row class="d-flex justify-center text-center" style="height: 100%;">
            <v-col cols="4" align-self="center" v-if="isUnsubscribe">
                <div class="mb-8">
                    <img src="https://sgasoft.com.br/skin/frontend/solusoft/default/images/logo-sga-soft.jpg" />
                </div>
                <div>
                    <h2>Sua inscrição foi cancelada com sucesso</h2>
                    <p>
                        Você cancelou a inscrição com sucesso e seu e-mail foi removido da lista.
                        Lamentamos muito ver você partir.
                    </p>
                </div>
            </v-col>
            <v-col cols="4" align-self="center" v-if="errorMessage">
                <div class="mb-8">
                    <img src="https://sgasoft.com.br/skin/frontend/solusoft/default/images/logo-sga-soft.jpg" />
                </div>
                <div>
                    <h2>Ops... Ocorreu um problema</h2>
                    <p>
                        Pedimos desculpas, mas ocorreu um problema ao cancelar a sua inscrição. Tente novamente mais
                        tarde.
                    </p>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import api from '@/api/unsubscribe';
import { mapMutations } from "vuex";

export default {
    name: 'Unsubscribe',
    created() {
        this.initialize();
        document.title = document.title + ' -  Desinscrição';
    },
    data: () => ({
        ...mapMutations(["showLoading", "hideLoading"]),
        isUnsubscribe: false,
        errorMessage: false
    }),
    methods: {
        ...mapMutations(["setDrawer"]),
        initialize() {
            const { token } = this.$route.params;
            if (token == null || token == undefined)
                this.$router.push('/login');

            this.unsubscribe(token);
        },
        async unsubscribe(token) {
            this.showLoading();
            try {
                const response = await api.unsubscribe(token);
                if (response.status == 200 || response.status == 201) {
                    this.hideLoading();
                    this.isUnsubscribe = true;
                } else {
                    this.hideLoading();
                    this.errorMessage = true;
                }
            } catch (error) {
                this.hideLoading();
                this.errorMessage = true;
            }
        }
    },
}
</script>
