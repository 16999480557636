<template>
    <div>
        <div class="elevation-1">
            <v-toolbar flat color="white">
                <v-toolbar-title>
                    <v-icon>mdi-contacts-outline</v-icon>
                    Lista de Contatos
                </v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-toolbar-title v-if="contactList.id">{{ contactList.id }} - {{ contactList.name }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn outlined color="blue darken-3" @click="saveContactList" class="mr-3"
                    v-if="focusStep == 3 && contactList.type_import == 'manual'">
                    <v-icon left>mdi-send</v-icon> Salvar
                </v-btn>
                <v-btn outlined color="blue darken-3" @click="saveContactList" class="mr-3"
                    v-if="focusStep == 4 && contactList.type_import == 'import'">
                    <v-icon left>mdi-send</v-icon> Salvar
                </v-btn>
                <v-btn outlined color="blue darken-3" @click="nextStep" class="mr-3"
                    v-if="focusStep < 4 && contactList.type_import != 'manual'">
                    <v-icon left>mdi-send</v-icon> Próximo
                </v-btn>
                <v-btn outlined color="grey darken-3" @click="goToBackList">
                    <v-icon left>mdi-chevron-left</v-icon> Voltar
                </v-btn>
            </v-toolbar>
            <v-form ref="form" v-model="valid" :lazy-validation="lazy">
                <v-container fluid>
                    <v-row class="mb-10">
                        <v-col>
                            <v-stepper non-linear v-model="focusStep">
                                <v-stepper-header>
                                    <v-stepper-step editable step="1">
                                        Dados da Lista de Contatos
                                    </v-stepper-step>

                                    <v-divider></v-divider>

                                    <v-stepper-step step="2" editable>
                                        Configuração de Campos
                                    </v-stepper-step>

                                    <v-divider></v-divider>

                                    <v-stepper-step step="3" editable>
                                        Importar Contatos
                                    </v-stepper-step>

                                    <v-divider></v-divider>

                                    <v-stepper-step step="4" editable v-if="contactList.type_import === 'import'">
                                        Contatos </v-stepper-step>
                                </v-stepper-header>

                                <v-stepper-items>
                                    <v-stepper-content step="1">
                                        <v-row>
                                            <v-col cols="12" xs="12" md="8">
                                                <v-text-field v-model="contactList.name" label="Titulo" outlined required
                                                    :rules="[rules.required]"></v-text-field>
                                            </v-col>
                                            <v-col cols="12" xs="12" md="4">
                                                <v-select :items="list_types" v-model="contactList.list_type"
                                                    :rules="[rules.required]" label="Tipo de Lista" required outlined>
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" xs="12" md="2">
                                                <v-switch v-model="contactList.enabled" label="Ativo"></v-switch>
                                            </v-col>
                                        </v-row>
                                    </v-stepper-content>
                                    <v-stepper-content step="2">
                                        <v-row>
                                            <v-col cols="12" xs="12" md="3" lg="2">
                                                <v-list dense>
                                                    <v-list-item-group no-action v-model="stepSelected" color="primary">
                                                        <v-list-item v-for="(item, i) in stepConfigFields" :key="i"
                                                            selectable>
                                                            <v-list-item-content>
                                                                <v-list-item-title v-text="item.text">
                                                                </v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-list-item-group>
                                                </v-list>
                                            </v-col>
                                            <v-col cols="12" xs="12" md="9" lg="10">
                                                <v-data-table :headers="headersConfigFields"
                                                    :items="filterItems(contactList.required_fields)" :search="searchString"
                                                    fixed-header hide-default-footer disable-sort
                                                    no-results-text="Nenhum registro encontrado" loading-text="Carregando"
                                                    class="elevation-1">
                                                    <template v-slot:top>
                                                        <v-toolbar flat>
                                                            <v-toolbar-title>
                                                                {{ titleTableConfigFields }}
                                                            </v-toolbar-title>
                                                            <v-spacer></v-spacer>
                                                        </v-toolbar>
                                                    </template>
                                                    <template v-slot:item.required="{ item }">
                                                        <v-switch :disabled="checkDisabledItem(item)"
                                                            @change="handleChangeItem('required', item)"
                                                            v-model="item.required"></v-switch>
                                                    </template>
                                                    <template v-slot:item.visible="{ item }">
                                                        <v-switch :disabled="checkDisabledItem(item)"
                                                            @change="handleChangeItem('visible', item)"
                                                            v-model="item.visible"></v-switch>
                                                    </template>
                                                </v-data-table>
                                            </v-col>
                                        </v-row>
                                    </v-stepper-content>
                                    <v-stepper-content step="3">
                                        <v-row>
                                            <v-col>
                                                <v-radio-group v-model="contactList.type_import" class="ml-5">
                                                    <v-radio value="import">
                                                        <template v-slot:label>
                                                            <div><strong>Importar</strong> Lista de Contatos</div>
                                                        </template>
                                                    </v-radio>
                                                    <v-radio value="manual">
                                                        <template v-slot:label>
                                                            <div>Inserir Contatos <strong>Manualmente</strong></div>
                                                        </template>
                                                    </v-radio>
                                                </v-radio-group>
                                            </v-col>
                                        </v-row>
                                        <div v-if="contactList.type_import === 'import'">
                                            <v-row>
                                                <v-col cols="12" xs="12" md="6">
                                                    <v-file-input show-size outlined dense v-model="fileUpload"
                                                        truncate-length="40" label="Selecione o Arquivo"></v-file-input>
                                                </v-col>
                                                <v-col cols="12" xs="12" md="3">
                                                    <v-btn color="success" @click="handleProcessFile">
                                                        <v-icon left>mdi-upload</v-icon>
                                                        Processar
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                            <v-divider></v-divider>

                                            <v-row v-if="fileProcessing" class="my-10">
                                                <v-col col="12" xs="8">
                                                    <span>Arquivo:</span>
                                                    <span>{{ fileUploadName }}</span>
                                                </v-col>
                                                <v-col col="12" xs="4">
                                                    <span>Status: </span>
                                                    <strong>Processando</strong>
                                                </v-col>
                                            </v-row>

                                            <v-row v-if="fileProccessed" class="my-4">
                                                <v-col>
                                                    <v-btn @click="dialogImportContacts = true" dark color="primary">
                                                        <v-icon>mdi-cog</v-icon> Configurar Importação
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-stepper-content>
                                    <v-stepper-content step="4">
                                        <SimpleTable v-if="!showCardDuplicates" title="Contatos" :headers="headers"
                                            :items="prepareContacts(contacts)" :sortBy="['id']">
                                            <template v-slot:actions="{ item }">
                                                <v-tooltip bottom>
                                                    <template v-if="checkDuplicate(item)" v-slot:activator="{ on, attrs }">
                                                        <v-icon medium class="mr-2" @click="deleteContact(item)"
                                                            color="info" v-bind="attrs" v-on="on">
                                                            mdi-information-outline
                                                        </v-icon>
                                                    </template>
                                                    <span>Registro Duplicado</span>
                                                </v-tooltip>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon medium @click="deleteContact(item)" color="red"
                                                            v-bind="attrs" v-on="on">
                                                            mdi-delete
                                                        </v-icon>
                                                    </template>
                                                    <span>Deletar</span>
                                                </v-tooltip>
                                            </template>
                                        </SimpleTable>
                                        <v-card v-if="showCardDuplicates" class="mx-auto my-10 pb-3" elevation="6"
                                            max-width="660">
                                            <v-toolbar color="info" dark class="headline text-center">
                                                Atenção, Contatos Duplicados
                                            </v-toolbar>
                                            <v-list-item three-line>
                                                <v-list-item-content>
                                                    <v-list-item-subtitle class="title my-5 text-center">
                                                        Foram encontrados <strong>{{
                                                            contactsDuplicates.length
                                                        }}</strong>
                                                        contatos já existentes neste arquivo de
                                                        <strong>{{ contacts.length }}</strong> registros importados. O
                                                        que
                                                        deseja fazer?
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-card-actions>
                                                <v-btn color="info" outlined @click="updateDuplication">
                                                    Atualizar Duplicados
                                                </v-btn>
                                                <v-btn color="warning" outlined @click="ignorarDuplication">
                                                    Ignorar Duplicados
                                                </v-btn>
                                                <v-btn @click="abortImport" color="error" outlined>
                                                    Desistir da Importação
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-stepper-content>
                                </v-stepper-items>
                            </v-stepper>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </div>
        <v-dialog v-model="dialogImportContacts" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-btn icon dark @click="dialogImportContacts = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Configurar Importação</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn dark text @click="dialogImportContacts = false">
                            Salvar
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-divider></v-divider>
                <v-data-table dense :headers="headersConfig" :items="itemsHeadersConfig" :search="searchString" fixed-header
                    disable-sort hide-default-footer no-results-text="Nenhum registro encontrado" loading-text="Carregando"
                    class="elevation-1 mt-1 px-2" :items-per-page="itemsPerPageImportContacts">
                    <template v-slot:item.checked="{ item }">
                        <v-switch v-model="item.checked" dense @change="
                            handleChangeHeadersConfig('checked', item)
                        "></v-switch>
                    </template>
                    <template v-slot:item.field="{ item }">
                        <v-select class="pt-5" :items="listFieldRequired" @change="handleChangeHeadersConfig('field', item)"
                            :disabled="!item.checked" v-model="item.field" outlined></v-select>
                    </template>
                </v-data-table>

            </v-card>

        </v-dialog>
    </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import api from "@/api/contact-list";
import apiCustomFields from "@/api/custom-field";
import HeaderForm from "@/components/HeaderForm";
import SimpleTable from "@/components/SimpleTable";
import { contactFields } from "@/helpers/contactFields.js";

export default {
    components: {
        HeaderForm,
        SimpleTable
    },
    data: () => ({
        modelContactList: {
            id: "",
            name: "",
            required_fields: [],
            type_import: 'import',
            list_type: "email",
            enabled: true,
            company_id: "",
            company: {}
        },
        fileUpload: null,
        list_types: ["email", "sms", "whatsapp"],
        focusStep: 1,
        valid: true,
        lazy: false,
        showCardDuplicates: false,
        contactsDuplicates: [],
        rules: {
            required: value => !!value || "Campo Obrigatorio",
            rulesStep1: true
        },
        contacts: [],
        contactList: {},
        searchString: "",
        headersConfig: [
            {
                text: "Manter Campo Importação",
                value: "checked",
                align: "start",
                width: 250
            },
            { text: "Coluna Arquivo Importação", value: "name", align: "start" },
            { text: "Campo Sistema", value: "field", align: "start" }
        ],
        itemsPerPageImportContacts: 10,
        itemsHeadersConfig: [],
        headers: [],
        stepConfigFields: [
            { text: "Identificação", icon: "mdi-clock" },
            { text: "Contato", icon: "mdi-clock" },
            { text: "Endereço", icon: "mdi-clock" },
            { text: "Customizados", icon: "mdi-clock" }
        ],
        headersConfigFields: [
            { text: "Campo", value: "name", align: "left" },
            { text: "Descrição", value: "description", align: "left" },
            { text: "Mostrar", value: "visible", align: "start", width: 150 },
            { text: "Obrigatório", value: "required", align: "start", width: 150 }
        ],
        itemsConfigFields: [],
        stepConfigSelected: "Identificação",
        preDefinedFields: contactFields,
        stepSelected: 0,
        fileProccessed: false,
        fileProcessing: false,
        fileUploadName: "",
        dialogImportContacts: false,
    }),
    watch: {
        stepSelected: function (newValue, oldValue) {
            this.stepConfigSelected = this.stepConfigFields[newValue]["text"];
        }
    },
    computed: {
        ...mapState({
            companySelected: state => state.companySelected
        }),

        titleTableConfigFields: function () {
            return `Campos ${this.stepConfigSelected}`;
        },
        listFieldRequired: function () {
            if (
                this.contactList.id !== "" &&
                this.contactList.config_fields.length > 0
            ) {
                return this.contactList.config_fields.map(item => ({
                    value: item.key,
                    text: item.name,
                    disabled: true,
                }));
            } else {
                if (typeof this.contactList.required_fields != 'object')
                    this.contactList.required_fields = JSON.parse(this.contactList.required_fields);

                return this.contactList.required_fields
                    .filter(item => item.required)
                    .map(item => ({
                        value: item.key,
                        text: item.name
                    }));
            }
        }
    },
    created() {
        this.contactList = { ...this.modelContactList };
        this.initialize();
    },
    methods: {
        ...mapMutations(["showLoading", "hideLoading", "showAlert"]),
        initialize() {
            if (this.$route.params.id) {
                this.getContactList(this.$route.params.id);
            } else {
                this.contactList.required_fields = [...this.preDefinedFields];
                this.getCustomFields();
            }
        },
        async getCustomFields() {
            this.showLoading();
            try {
                let response = await apiCustomFields.getAll();

                let itemsCustomFields = response.data.map(item => ({
                    ...item,
                    id: item.id,
                    key: item.name.toLowerCase().replaceAll(' ', '_') + '_' + item.id,
                    name: item.name,
                    description: "",
                    visible: false,
                    required: false,
                    group: "Customizados",
                    type: item.type,
                    options: "",
                    default: "",
                    custom: true,
                }));
                this.contactList.required_fields = [...this.contactList.required_fields, ...itemsCustomFields];
            } catch (error) {
                this.showAlert({ message: error.message, type: "error" });
            } finally {
                this.hideLoading();
            }
        },
        async getContactList(id) {
            this.showLoading();
            try {
                let response = await api.show(id);
                this.contactList = response.data;
                this.contactList.required_fields = JSON.parse(
                    this.contactList.required_fields
                );
                this.contactList.config_fields = JSON.parse(
                    this.contactList.config_fields
                );
            } catch (error) {
                this.contactList = { ...this.modelContactList };
                this.showAlert({ message: error.message, type: "error" });
            } finally {
                this.hideLoading();
            }
        },
        goToBackList() {
            this.$router.push("/lista-contatos");
        },
        goToListContats(id) {
            this.$router.push(`/lista-contatos/${id}/contatos`);
        },
        async handleProcessFile() {
            if (this.fileUpload === null) {
                this.showAlert({
                    message: "Selecione um Arquivo para Importar",
                    type: "error"
                });
                return false;
            }
            let formData = new FormData();
            formData.append("file", this.fileUpload);
            formData.append("id", this.contactList.id);
            this.fileUploadName = this.fileUpload.name;
            this.fileProcessing = true;
            try {
                let response = await api.storeFile(formData);
                setTimeout(() => {
                    this.itemsHeadersConfig = this.formatHeaderConfig(response.headers);
                    this.proccessContacts(response);
                }, 500);
            } catch (error) {
                this.showAlert({
                    message: "Ocorreu um erro ao processar o arquivo, tente novamente",
                    type: "error"
                });
            }
        },
        formatHeaderConfig(data) {
            this.fileProcessing = false;
            this.fileProccessed = true;
            this.dialogImportContacts = true;
            this.fileUploadName = "";
            this.itemsPerPageImportContacts = data.length;

            return data.map(header => ({
                checked: true,
                name: header,
                field: ""
            }));
        },
        proccessContacts(data) {
            this.headers = [
                { text: "ID", value: "id", align: "end", width: 100 },
                {
                    text: "Ações",
                    value: "actions",
                    align: "end",
                    sortable: false,
                    width: 200
                }
            ];
            this.contacts = data.content;
            this.contactsDuplicates = data.contactsThatExists;
            this.showCardDuplicates = data.contactsThatExists.length > 0;
        },
        filterItems(data) {
            if (!Array.isArray(data)) {
                return [];
            }
            return data
                .filter(item => item.group === this.stepConfigSelected)
                .map(item => ({
                    ...item
                }));
        },
        setItensStepCogFields(fields) {
            this.itemsConfigFields = fields.map((item, index) => ({
                ...item,
                id: item.key
            }));
        },
        checkDisabledItem(item) {
            // Se já houver ID (edição) E já tiver sido vinculado alguns campos, os campos serão desabilitados
            if (
                this.contactList.id !== "" &&
                this.contactList.config_fields.length > 0
            ) {
                return true;
            }
            return (
                (item.key === "name" && item.group === "Identificação") ||
                (item.key === "email" &&
                    item.group === "Contato" &&
                    this.contactList.list_type === "email") ||
                (item.key === "cell_phone" &&
                    item.group === "Contato" &&
                    this.contactList.list_type === "sms")
            );
        },
        getFieldsRequired() {
            return this.contactList.required_fields
                .filter(item => item.required)
                .map(item => ({
                    value: item.key,
                    text: item.name
                }));
        },
        async saveContactList() {
            if (!this.$refs.form.validate()) {
                this.showAlert({
                    type: "error",
                    message: "Existem erros no formulario"
                });
                if (this.contactList.name === "") {
                    this.focusStep = 1;
                    return;
                }
                if (this.contactList.list_type === "") {
                    this.focusStep = 1;
                    return;
                }
            } else {
                this.contactList.required_fields = JSON.stringify(
                    this.contactList.required_fields
                );
                this.contactList.company_id = this.companySelected.id;

                if (this.contactList.id === "") {
                    //Salvar os campos configurados
                    let requiredFieldsParse = JSON.parse(
                        this.contactList.required_fields
                    );
                    if (this.contactList.type_import === 'import') {

                        let configFields = this.itemsHeadersConfig
                            .filter(item => item.checked && item.field !== "")
                            .map(header => {
                                let fieldSync = requiredFieldsParse.find(
                                    field => field.key === header.field
                                );
                                return {
                                    name: fieldSync.name,
                                    key: fieldSync.key,
                                    value: header.name
                                };
                            });
                        this.contactList.config_fields = JSON.stringify(configFields);
                    } else {
                        this.contactList.config_fields = JSON.stringify({});
                    }
                    await this.post(this.contactList);
                } else {
                    await this.update(this.contactList.id, this.contactList);
                }
            }
        },
        async post(data) {
            this.showLoading();
            try {
                let res = await api.store(data);
                if (res) {
                    if (typeof this.contactList.required_fields != 'object')
                        this.contactList.required_fields = JSON.parse(
                            this.contactList.required_fields
                        );

                    let contactsSave = this.getContactsFilter();

                    if (contactsSave.length > 0) {
                        await this.postContacts(res.id, contactsSave);
                    }
                    this.showAlert({
                        message: "Lista de Contatos salva com sucesso",
                        type: "success"
                    });
                    this.goToListContats(res.id);
                }
            } catch (error) {
                this.showAlert({ message: error.message, type: "error" });
            } finally {
                this.hideLoading();
            }
        },
        async update(id, data) {
            this.showLoading();
            try {
                let res = await api.update(id, data);
                if (res) {
                    this.contactList.required_fields = JSON.parse(
                        this.contactList.required_fields
                    );
                    let contactsSave = this.getContactsFilter();
                    if (contactsSave.length > 0) {
                        await this.postContacts(id, contactsSave);
                    }

                    this.showAlert({
                        message: "Lista de Cotnatos atualizada com sucesso",
                        type: "success"
                    });
                    this.goToListContats(id);
                }
            } catch (error) {
                this.showAlert({ message: error.message, type: "error" });
            } finally {
                this.hideLoading();
            }
        },
        getContactsFilter() {
            if (this.contactList.type_import === 'manual') {
                return [];
            }
            let headersChecked = this.itemsHeadersConfig.filter(item => item.checked);
            let contactsFilter = this.contacts.map(contact => {
                let keys = headersChecked.map(item => item.field);
                let contactFiltered = {};
                for (let index = 0; index < headersChecked.length; index++) {
                    if (headersChecked[index]["field"] != "") {
                        contactFiltered[headersChecked[index]["field"]] = contact[headersChecked[index]["name"]];
                    }
                }
                return contactFiltered;
            });
            return contactsFilter;
        },
        async postContacts(id, data) {
            try {
                let res = await api.postContacts(id, data);
            } catch (error) {
                throw error;
            }
        },
        handleChangeItem(type, item) {
            let copyItem = { ...item };
            if (type === "required" && item.required) {
                copyItem.visible = true;
            }
            if (type === "visible" && !item.visible) {
                copyItem.required = false;
            }

            let index = this.contactList.required_fields.findIndex(
                field => field.key === item.key
            );
            this.$set(this.contactList.required_fields, index, copyItem);
        },
        handleChangeHeadersConfig(type, item) {
            // Headers do arquivo importado
            let copyItem = { ...item };
            if (type === "checked" && !item.checked) {
                copyItem.field = "";
            }
            let index = this.itemsHeadersConfig.findIndex(
                field => field.name === item.name
            );
            this.setHeaderContacts();
        },
        prepareContacts(data) {
            return data.map((item, index) => ({ ...item, id: ++index }));
        },
        deleteContact(contact) {
            let index = this.contacts.findIndex(
                item =>
                    JSON.stringify({ ...item, id: contact.id }) ===
                    JSON.stringify(contact)
            );
            this.contacts.splice(index, 1);
        },
        setHeaderContacts() {
            // Processa os contatos de acordo com as informações selecionada nas configurações
            // e exibe na quarta tela.
            this.headers = [
                { text: "ID", value: "id", align: "end", width: 100 },
                ...this.itemsHeadersConfig
                    .filter(item => item.checked && item.field !== "")
                    .map(header => ({
                        text: this.contactList.required_fields.find(
                            field => field.key === header.field
                        ).name,
                        value: header.name,
                        align: "start"
                    })),
                {
                    text: "Ações",
                    value: "actions",
                    align: "end",
                    sortable: false,
                    width: 200
                }
            ];
        },
        updateDuplication() {
            this.showCardDuplicates = false;
        },
        ignorarDuplication() {
            this.showCardDuplicates = false;
            let structFields = {};
            for (let index = 0; index < this.itemsHeadersConfig.length; index++) {
                const element = this.itemsHeadersConfig[index];
                structFields[element.field] = element.name;
            }
            this.contacts = this.contacts.filter(
                contact =>
                    this.contactsDuplicates.find(
                        duplicate =>
                            (duplicate.email === contact[structFields['email']] && duplicate.email !== null) ||
                            (duplicate.cell_number === contact[structFields['cell_number']] && duplicate.cell_number !== null)
                    ) === undefined
            );
        },
        abortImport() {
            this.showCardDuplicates = false;
            this.contacts = [];
            this.contactsDuplicates = [];
            this.itemsHeadersConfig = [];
            this.fileUpload = null;
            this.fileProccessed = false;
            this.fileProcessing = false;
            this.fileUploadName = "";
            this.focusStep = 3;
            this.headers = [];
        },
        checkDuplicate(contact) {
            let keyEmail = this.itemsHeadersConfig.find(item => item.field === 'email');
            let keyCellNumber = this.itemsHeadersConfig.find(item => item.field === 'cell_number');
            let hasDuplicate = this.contactsDuplicates.find(
                item =>
                    (keyEmail !== undefined && item.email === contact[keyEmail.name] && item.email !== null) ||
                    (keyCellNumber !== undefined && item.cell_number === contact[keyCellNumber.name] && item.cell_number !== null)
            ) !== undefined;
            return hasDuplicate;
            return false;
        },
        nextStep() {
            this.focusStep++;
        }
    }
};
</script>
