import baseApi from "./baseApi";

const endpoint = "layout";

export default {
    get: page => baseApi.get(endpoint, page),
    getAll: type => baseApi.get(`${endpoint}/all`, type),
    show: id => baseApi.show(endpoint, id),
    store: data => baseApi.store(endpoint, data),
    update: (id, data) => baseApi.update(endpoint, id, data),
    storeFile: (data) => baseApi.storeFile(`${endpoint}/upload-image`, data),
    deleteImage: (url) => baseApi.destroy(`${endpoint}/delete-image`, url),
    destroy: id => baseApi.destroy(endpoint, id),
};
