import baseApi from "./baseApi";

const endpoint = "product";

export default {
    get: page => baseApi.get(endpoint, page),
    show: id => baseApi.show(endpoint, id),
    getCompanies: id => baseApi.get(`${endpoint}/${id}/company`),
    postCompanies: (id, data) => baseApi.store(`${endpoint}/${id}/company`, data),
};
