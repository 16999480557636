<template>
    <div>
        <v-data-table :headers="headers" :items="prepareData(filterData(items))" :search="search" fixed-header multi-sort
            no-results-text="Nenhum registro encontrado" loading-text="Carregando" :sort-by="['id']" class="elevation-1"
            :loading="loadingTable" :footer-props="{
                itemsPerPageOptions: [20, 30, 40, 50, 100, 200],
                itemsPerPageText: 'Itens por página',
                showCurrentPage: true
            }">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>
                        <v-icon>mdi-contacts-outline</v-icon>
                        Contatos
                    </v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-toolbar-title v-if="contactList.id">{{ contactList.id }} - {{ contactList.name }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-row align="center">
                        <v-spacer></v-spacer>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Procurar" single-line
                            hide-details></v-text-field>
                    </v-row>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-btn outlined color="blue darken-3" @click="exportCSV()" target="_blank">
                        <v-icon left>mdi-download</v-icon> Exportar CSV
                    </v-btn>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-btn dark color="green darken-3" @click="addContactManual" target="_blank">
                        <v-icon left>mdi-plus</v-icon> Adicionar Contato
                    </v-btn>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-btn outlined color="grey darken-3" @click="goToBackList">
                        <v-icon left>mdi-chevron-left</v-icon> Voltar
                    </v-btn>
                </v-toolbar>
            </template>
            <template v-slot:footer.page-text="items"> {{ items.pageStart }} - {{ items.pageStop }} de {{
                items.itemsLength
            }} </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon medium class="mr-2" color="blue darken-4" @click="reportEmail(item)" v-bind="attrs"
                            v-on="on">
                            mdi-file
                        </v-icon>
                    </template>
                    <span>Relatorio do Contato</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon medium class="mr-2" color="blue" @click="editItem(item)" v-bind="attrs" v-on="on">
                            mdi-pencil
                        </v-icon>
                    </template>
                    <span>Editar</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon medium @click="deleteItem(item)" color="red" v-bind="attrs" v-on="on">
                            {{ getIcon(item) }}
                        </v-icon>
                    </template>
                    <span>{{ getLabel(item) }}</span>
                </v-tooltip>
            </template>
            <template v-slot:no-data>
                <v-btn color="primary" @click="onClickRefreshTable">
                    Recarregar
                </v-btn>
            </template>
        </v-data-table>

        <v-dialog v-model="dialogDelete" persistent max-width="550">
            <v-card>
                <v-card-title class="headline red lighten-3">

                </v-card-title>
                <v-card-text class="mt-10 mb-5 headline text-center">Deseja realmente {{ lblEnabledButton }} esse
                    Contato?
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" outlined @click="handleConfirmDelete">
                        Confirmar
                    </v-btn>
                    <v-btn color="gray darken-1" outlined @click="handleCancelDelete">
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Dialog para inserção manual de contatos -->
        <v-dialog v-model="dialogContact" persistent scrollable max-width="1000px">
            <v-card>
                <v-card-title>
                    <span class="text-h5" v-if="newContact.id == ''">Adicionar Contato</span>
                    <span class="text-h5" v-if="newContact.id != ''">Editar Contato</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row v-if="hasKeyDoc()">
                            <v-col>
                                <v-radio-group v-model="newContact.doc_type" row>
                                    <v-radio label="Pessoa Fisica (CPF)" value="F"></v-radio>
                                    <v-radio label="Pessoa Juridica (CNPJ)" value="J"></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row v-if="hasKeyDoc()">
                            <v-col>
                                <v-text-field v-model="newContact.doc"
                                    v-mask="(newContact.doc_type === 'F' ? '###.###.###-##' : '##.###.###/####-##')"
                                    :label="(newContact.doc_type === 'F' ? 'Documento (CPF)' : 'Documento (CNPJ)')">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row v-for="field in fieldsContactForm" :key="field.key">
                            <v-col cols="12">
                                <v-text-field v-if="field.type != 'checkbox'" :type="field.type"
                                    v-model="newContact[field.key]" v-mask="getMaskField(field)"
                                    :label="field.name"></v-text-field>
                                <v-checkbox v-if="field.type == 'checkbox'" :type="field.type"
                                    v-model="newContact[field.key]" v-mask="getMaskField(field)"
                                    :label="field.name"></v-checkbox>
                            </v-col>
                        </v-row>
                    </v-container>
                    <small>*campo obrigatorio</small>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey darken-4" outlined @click="dialogContact = false">
                        Fechar
                    </v-btn>
                    <v-btn color="green darken-1" dark @click="saveContact">
                        Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import api from "@/api/contact-list";
import apiContact from '@/api/contact';
import { contactFields } from "@/helpers/contactFields.js";
import { mapMutations } from "vuex";
export default {
    name: "LayoutEmailList",
    components: {},
    data: () => ({
        modelContactList: {
            id: "",
            name: "",
            required_fields: [],
            config_fields: [],
            list_type: "email",
            enabled: true,
            companies_id: "",
            company: {},
        },
        modelContact: {
            id: '',
            name: null,
            email: null,
            doc_type: 'F',
            doc: '',
            address: null,
            number: null,
            complement: null,
            neighborhood: null,
            zip_code: null,
            city: null,
            state: null,
            country: null,
            birthdate: null,
            note: null,
            second_email: null,
            phone_number: null,
            cell_number: null,
            contact_list_id: null,
        },
        loadingTable: false,
        newContact: {},
        showDisabled: false,
        headers: [],
        preDefinedFields: contactFields,
        contactList: {},
        search: '',
        items: [],
        dialogDelete: false,
        dialogContact: false,
        fieldsContactForm: [],
        _itemDelete: {},
        lblEnabledButton: 'Desativar',
        iconEnabledButton: 'mdi-delete',
        rulesType: {
            birthdate: value => {
                if (value.length < 10) return true;
                let isValid = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/.test(value);
                return isValid ? true : "Data Invalida";
            },
            email: value => {
                let isValid = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value)
                return isValid ? true : 'Email Invalido';
            }
        },
    }),
    created() {
        this.contactList = { ...this.modelContactList };
        this.initialize();
    },
    methods: {
        ...mapMutations(["showLoading", "hideLoading", "showAlert", "setDrawer"]),
        initialize() {
            this.setDrawer(false);
            if (this.$route.params.id) {
                this.getContactList(this.$route.params.id);
            }
            //this.getData();
        },
        async getContactList(id) {
            this.contactList = [];
            this.showLoading();
            this.items = [];
            this.loadingTable = true;
            try {
                let response = await api.show(id);
                this.contactList = response.data;
                this.contactList.config_fields = JSON.parse(this.contactList.config_fields);
                if (Object.keys(this.contactList.config_fields).length == 0) {
                    let requiredFields = JSON.parse(this.contactList.required_fields);
                    requiredFields = requiredFields.filter(item => item.visible === true);
                    this.headers = [
                        { text: "ID", value: "id", align: "end", width: 120 },
                        ...requiredFields.map(item => ({ text: item.name, value: item.key, align: "start" })),
                        { text: "Disparos", value: "countSend", align: 'end', width: 120 },
                        { text: "Lidos", value: "countRead", align: 'end', width: 120 },
                        { text: "Ações", value: "actions", align: 'end', sortable: false, width: 200 }
                    ];
                } else {
                    this.headers = [
                        { text: "ID", value: "id", align: "end", width: 120 },
                        ...this.contactList.config_fields.map(item => ({ text: item.name, value: item.key, align: "start" })),
                        { text: "Disparos", value: "countSend", align: 'end', width: 120 },
                        { text: "Lidos", value: "countRead", align: 'end', width: 120 },
                        { text: "Ações", value: "actions", align: 'end', sortable: false, width: 200 }
                    ];
                }

                this.filterFieldsToForm();

                await this.getContacts(id);
            } catch (error) {
                this.contactList = { ...this.modelContactList };
                this.showAlert({ message: error.message, type: 'error' });
            } finally {
                this.hideLoading();
                this.loadingTable = false;
            }
        },
        async getContacts(id, loading = false) {
            if (loading) {
                this.showLoading();
                this.loadingTable = true;
                this.items = [];
            }
            try {
                let response = await api.getContacts(id);
                this.items = response.data
                    .filter(item => item.enabled === 1)
                    .map(item => {
                        if (item.birthdate !== null) {
                            return {
                                ...item,
                                birthdate: item.birthdate.split('-').reverse().join('/'),
                            }
                        }
                        return item;
                    });

            } catch (error) {
                this.items = [];
                this.showAlert({ message: error.message, type: 'error' });
            } finally {
                if (loading) {
                    this.hideLoading();
                    this.loadingTable = false;
                }
            }
        },
        onClickRefreshTable() {
            this.showLoading();
            this.getContacts(this.$route.params.id);
            this.hideLoading();
        },
        goToBackList() {
            this.$router.push("/lista-contatos");
        },
        async exportCSV() {
            let response = await api.export(this.contactList.id);
            var encodedUri = encodeURI(`data:text/csv;charset=utf-8,${response}`);
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "contatos.csv");
            document.body.appendChild(link);
            link.click();
        },
        filterData(data) {
            return data;
        },
        selectedShowDisabled() {
            this.showDisabled = !this.showDisabled;
        },
        prepareData(data) {
            return data.map(item => ({
                ...item,
                created_at: this.dateToBr(item.created_at),
                updated_at: this.dateToBr(item.updated_at)
            }));
        },
        dateToBr(date) {
            if (!date) return date;
            let [fullDate, minutes] = date.split(" ");
            return `${fullDate
                .split("-")
                .reverse()
                .join("/")} ${minutes}`;
        },
        editItem(item) {
            if (item.customFields != [] && item.customFields.length > 0) {
                item.customFields.forEach(field => {
                    item = { ...item, [field.key]: field.value };
                });
            }
            this.newContact = { ...item };
            this.dialogContact = true;
        },
        deleteItem(item) {
            this.lblEnabledButton = item.enabled == 1 ? 'Desativar' : 'Ativar';
            this.dialogDelete = true;
            this._itemDelete = item;
        },
        async handleConfirmDelete() {
            this.dialogDelete = false;
            this.showLoading();

            try {
                let data = { ...this._itemDelete };
                data.enabled = (data.enabled == 1 ? 0 : 1);
                let res = await apiContact.update(this._itemDelete.id, data);
                this.showAlert({
                    message: "Contato Desativado com sucesso",
                    type: "success",
                });
                this.getContacts(this.contactList.id, true);
                //this.getData();
            } catch (error) {
                if (typeof error.response !== 'undefined' && typeof error.response.status !== 'undefined') {
                    if (error.response.status === 400) {
                        this.showAlert({
                            message: error.response.data.message,
                            type: "error",
                        });
                    } else {
                        this.showAlert({
                            message: error.message,
                            type: "error",
                        });
                    }
                } else {
                    this.showAlert({
                        message: error.message,
                        type: "error",
                    });
                }

            } finally {
                this._itemDelete = {};
                this.hideLoading();
            }
        },
        handleCancelDelete() {
            this.dialogDelete = false;
            this._itemDelete = {};
        },
        reportEmail(item) {
            this.$router.push(`/lista-contatos/${this.contactList.id}/contatos/${item.id}/detalhes`);
        },
        addContactManual() {
            this.newContact = { ...this.modelContact };
            this.dialogContact = true;
        },
        getMaskField(field) {
            if (field.type === 'Cpf') {
                return '###.###.###-##';
            } else if (field.type === 'Date') {
                return '##/##/####';
            } else if (field.type === 'Cellphone') {
                return '(##) #####-####';
            } else if (field.type === 'Telephone') {
                return '(##) #####-####';
            } else if (field.type === 'Cep') {
                return '##.###-###';
            } else {
                return null;
            }
        },
        getRulesType(field) {
            if (field.key === 'birthdate') {
                let validBirth = value => {
                    if (value.length < 10) return true;
                    let isValid = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/.test(value);
                    return isValid ? true : "Data Invalida";
                }
                return validBirth;
            } else if (field.key === 'email') {
                let validEmail = value => {
                    let isValid = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value)
                    return isValid ? true : 'Email Invalido';
                }
                return validEmail;
            } else {
                return true;
            }
        },
        async saveContact() {
            this.newContact.contact_list_id = this.contactList.id;
            this.showLoading();

            try {
                let res = {};
                if (this.newContact.id !== "") {
                    res = await apiContact.update(this.newContact.id, this.newContact);
                } else {
                    res = await apiContact.store(this.newContact);
                }

                if (res) {
                    this.dialogContact = false;
                    this.showAlert({ message: (this.newContact.id !== "" ? 'Contato atualizado com sucesso' : 'Contato salvo com sucesso'), type: 'success' });
                    this.getContactList(this.$route.params.id);
                }
            } catch (error) {
                this.showAlert({ message: error.message, type: 'error' });
            } finally {
                this.hideLoading();
            }
        },
        filterFieldsToForm() {
            let fieldsDatabase = [...JSON.parse(this.contactList.required_fields)];
            fieldsDatabase = fieldsDatabase.filter(item => item.visible === true);
            // let fields = this.preDefinedFields.filter(item => fieldsDatabase.find(field => field.key === item.key) !== undefined);
            this.fieldsContactForm = [...fieldsDatabase];
        },
        filterDoc(items) {
            return items.filter(item => item.key !== 'doc');
        },
        hasKeyDoc() {
            if (Object.keys(this.fieldsContactForm).length === 0) {
                return false;
            }
            return this.fieldsContactForm.find(item => item.key === 'doc') !== undefined;
        },
        getLabel(item) {
            return item.enabled == 1 ? 'Desativar' : 'Ativar';
        },
        getIcon(item) {
            return item.enabled == 1 ? 'mdi-delete' : 'mdi-play';
        }
    }
};
</script>

<style>
</style>
