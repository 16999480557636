<template>
    <div>
        <v-data-table fixed-header :headers="headers" :search="search" :loading="loading"
            no-results-text="Nenhum registro encontrado" loading-text="Carregando" class="elevation-1" :footer-props="{
                itemsPerPageOptions: [10, 20, 30, 40, 50, 100, 200],
                itemsPerPageText: 'Itens por página',
                showCurrentPage: true
            }" :items="prepareData(items)" no-data-text="Nenhum campo cadastrado">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>
                        <v-icon>mdi-send</v-icon>
                        Campos Adicionais
                    </v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-row>
                        <v-spacer></v-spacer>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Procurar" single-line
                            hide-details></v-text-field>
                    </v-row>
                    <v-divider class="ml-6 mr-3" inset vertical></v-divider>
                    <v-btn color="green" dark class="mb-2" @click="openDialog">
                        <v-icon left>
                            mdi-plus
                        </v-icon>
                        Adicionar
                    </v-btn>
                </v-toolbar>
            </template>
            <template v-slot:item.enabled="{ item }">
                <v-chip :color="getColor(item.enabled)" dark>
                    {{ item.enabled == 1 ? 'Ativo' : 'Inativo' }}
                </v-chip>
            </template>
            <template v-slot:item.type="{ item }">
                {{ getTypeField(item.type) }}
            </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon medium class="mr-2" color="blue" @click="editItem(item)" v-bind="attrs" v-on="on">
                            mdi-pencil
                        </v-icon>
                    </template>
                    <span>Editar</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon medium class="mr-2" color="red" @click="deleteItem(item)" v-bind="attrs" v-on="on">
                            mdi-delete
                        </v-icon>
                    </template>
                    <span>Deletar</span>
                </v-tooltip>
            </template>
        </v-data-table>
        <v-dialog v-model="newFieldDialog" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    Cadastro de Campo Adicional
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-col cols="12">
                            <v-text-field v-model="customField.name" label="Nome" outlined></v-text-field>
                            <v-select v-model="customField.type" label="Tipo" :items="customFieldTypes"
                                outlined></v-select>
                            <v-switch v-model="customField.enabled" color="green" label="Ativo"></v-switch>
                        </v-col>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text outlined @click="closeDialog">
                        Fechar
                    </v-btn>
                    <v-btn color="green darken-1" outlined text @click="saveCustomField">
                        Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <DialogDelete message="Deseja realmente deletar essa Lista de Contatos?" :dialog="dialogDelete"
            :confirmDialog="handleConfirmDelete" :cancelDialog="handleCancelDelete" />
    </div>
</template>
<script>
import DialogDelete from "@/components/DialogDelete";

import api from "@/api/custom-field";
import { mapMutations, mapState } from "vuex";
export default {
    name: "CustomFieldsList",
    components: {
        DialogDelete,
    },
    data: () => ({
        headers: [
            { text: "Nome", value: "name", align: "start" },
            { text: "Tipo", value: "type", align: "start" },
            { text: "Ativo", value: "enabled", align: "start" },
            { text: "Data de Criação", value: "created_at" },
            {
                text: "Ações",
                value: "actions",
                align: "end",
                sortable: false,
                width: 100,
            }
        ],
        items: [],
        loading: false,
        search: '',
        sortBy: [],
        newFieldDialog: false,
        customFieldTypes: [
            { text: 'Texto', value: 'text' },
            { text: 'Data', value: 'date' },
            { text: 'E-mail', value: 'email' },
            { text: 'Numérico', value: 'number' },
            { text: 'Sim/Nao', value: 'checkbox' },
        ],
        customField: {
            name: null,
            type: null,
            enabled: true,
        },
        dialogDelete: false,
        _itemDelete: [],
    }),
    created() {
        this.initialize();
    },
    methods: {
        ...mapMutations(["showLoading", "setDrawer", "hideLoading", "showAlert"]),
        initialize() { this.getData(); },
        async getData() {
            this.showLoading();
            this.loading = true;
            this.items = [];

            try {
                let response = await api.get();
                this.items = response.data.data;
            } catch (error) {
                this.items = [];
                console.log(error);
                this.showAlert({ message: error.message, type: "error" });
            } finally {
                this.loading = false;
                this.hideLoading();
            }
        },
        async saveCustomField() {
            const customField = { ...this.customField };

            if (customField.name == "" && customField.type == "") {
                return;
            }
            this.showLoading();
            try {
                let response = null;
                if (customField.id != null && customField.id != undefined) {
                    response = await api.update(customField.id, customField);
                } else {
                    response = await api.store(customField);
                }

                this.showAlert({ message: response.message, type: "success" });
                this.customField = { name: null, type: null, enabled: true };
                this.getData();
                this.closeDialog();
            } catch (error) {
                this.showAlert({ message: error.message, type: "error" });
            } finally {
                this.hideLoading();
            }
        },
        prepareData(data) {
            return data.map(item => ({
                ...item,
                created_at: this.dateToBr(item.created_at),
            }))
        },
        editItem(item) {
            this.customField = item;
            this.openDialog();
        },
        deleteItem(item) {
            this.dialogDelete = true;
            this._itemDelete = item;
        },
        async handleConfirmDelete() {
            this.dialogDelete = false;
            this.showLoading();

            try {
                let response = await api.destroy(this._itemDelete.id);
                this.showAlert({
                    message: response.message,
                    type: "success"
                });
                this.getData();
            } catch (error) {
                this.showAlert({
                    message: error.message,
                    type: "error"
                });
            } finally {
                this._itemDelete = [];
                this.hideLoading();
            }
        },
        handleCancelDelete() {
            this.dialogDelete = false;
            this._itemDelete = [];;
        },
        getTypeField(type) {
            switch (type) {
                case 'text':
                    return 'Texto';
                case 'date':
                    return 'Data';
                case 'number':
                    return 'Número';
                case 'email':
                    return 'E-mail';
                case 'checkbox':
                    return 'Sim/Não';
                default:
                    return 'Não Informado';
            }

        },
        dateToBr(date) {
            if (!date) return date;
            let [fullDate, minutes] = date.split(" ");
            return `${fullDate
                .split("-")
                .reverse()
                .join("/")} ${minutes}`;
        },
        getColor(enabled) {
            if (enabled == 1)
                return 'green';
            else
                return 'red';
        },
        openDialog() {
            this.newFieldDialog = true;
        },
        closeDialog() {
            this.newFieldDialog = false;
        }
    }
}
</script>
