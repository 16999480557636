<template>
  <div>
    <div class="elevation-1">
      <HeaderForm
        title="Modelo de Email"
        icon="mdi-email-plus-outline"
        :id="`${layout.id}`"
        :name="layout.title"
        :onClickBackButton="goToBackList"
      />
      <v-form ref="form" v-model="valid" :lazy-validation="lazy">
        <v-container fluid>
          <v-row>
            <v-col cols="12" xs="12" md="6">
              <v-text-field
                v-model="layout.title"
                label="Titulo"
                outlined
                required
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" md="4">
              <v-select
                :items="companies"
                v-model="layout.companies_id"
                item-text="name"
                item-value="id"
                :rules="[rules.required]"
                label="Empresa"
                required
                outlined
              ></v-select>
            </v-col>
            <v-col cols="12" xs="12" md="2">
              <v-switch v-model="layout.enabled" label="Ativo"></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="blue-grey"
                    class="mr-2"
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="setSizeEditor(480)"
                  >
                    <v-icon>mdi-cellphone</v-icon>
                  </v-btn>
                </template>
                <span>Celular ( 480px )</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="blue-grey"
                    class="mr-2"
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="setSizeEditor(768)"
                  >
                    <v-icon>mdi-tablet</v-icon>
                  </v-btn>
                </template>
                <span>Tablet ( 768px )</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="blue-grey"
                    class="mr-2"
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="setSizeEditor(1366)"
                  >
                    <v-icon>mdi-laptop</v-icon>
                  </v-btn>
                </template>
                <span>Notebook ( 1366px )</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="blue-grey"
                    class="mr-2"
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="setSizeEditor('100%')"
                  >
                    <v-icon>mdi-monitor</v-icon>
                  </v-btn>
                </template>
                <span>Computador ( 100% )</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="blue-grey"
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="sizeFullSize()"
                  >
                    <v-icon>mdi-monitor-screenshot</v-icon>
                  </v-btn>
                </template>
                <span>Tela Inteira</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div :style="editorStyle">
                <ckeditor
                  :editor="editor"
                  class="editor"
                  :config="editorConfig"
                  v-model="layout.body"
                ></ckeditor>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn color="blue darken-3" dark class="mt-5" @click="validate">
                <v-icon left>mdi-send</v-icon> Salvar
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </div>
  </div>
</template>
<script>
import HeaderForm from "@/components/HeaderForm";
import { mapMutations } from "vuex";

import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@/ckeditor-custom/ckeditor.js";

import api from "@/api/layout";
import apiCompany from "@/api/company";

export default {
  components: {
    HeaderForm,
    ckeditor: CKEditor.component,
  },
  data: () => ({
    modelLayout: {
      id: "",
      title: "",
      body: "",
      enabled: 1,
    },
    layout: {},
    companies: [],
    dialog: false,
    editorStyle: {
      width: '100%',
    },
    valid: true,
    lazy: false,
    rules: {
      required: (value) => !!value || "Campo Obrigatorio",
    },
    editor: ClassicEditor,
    editorData: "",
    editorConfig: {
      placeholder: "Coloque aqui o Modelo de Email",
      extraPlugins: [ MyCustomUploadAdapterPlugin ],
      removePlugins: ['ImageCaption'],
      toolbar: {
        items: [
          'heading', '|',
            'fontfamily', 'fontsize', '|',
            'alignment', '|',
            'fontColor', 'fontBackgroundColor', '|',
            'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
            'link', '|',
            'outdent', 'indent', '|',
            'bulletedList', 'numberedList', 'todoList', '|',
            'code', 'codeBlock', '|',
            'insertTable', '|',
            'uploadImage', 'blockQuote', '|',
            'undo', 'redo'
        ],
      },
      image: {
        styles: [
          'full', 'side', 'alignLeft', 'alignCenter', 'alignRight'
        ],
        // You need to configure the image toolbar, too, so it shows the new style
        // buttons as well as the resize buttons.
        toolbar: [
            'imageStyle:full', 'imageStyle:side',
            '|',
            'imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight',
            '|',
            'imageTextAlternative'
        ]
      },
    },
  }),
  watch: {
    'layout.body': function (newValue, oldValue) {
      const getImagesTags = data => {
        return Array.from( new DOMParser().parseFromString( data, 'text/html' )
          .querySelectorAll( 'img' ) )
          .map( img => img.getAttribute( 'src' ) )
          .filter( img => img !== null )
      }
      let imagesOld = getImagesTags(oldValue);
      let imagesNew = getImagesTags(newValue);
      if (imagesNew.length < imagesOld.length) {
        this.deleteImage(imagesOld.filter(x => !imagesNew.includes(x)));
      }
    }
  },
  created() {
    this.layout = { ...this.modelLayout };
    this.initialize();
  },
  methods: {
    ...mapMutations(["showLoading", "hideLoading", "showAlert"]),
    async initialize() {
      this.getCompanies();
      if (this.$route.params.id) {
        this.getLayout(this.$route.params.id);
      }
    },
    async getLayout(id) {
      this.showLoading();
      try {
        let response = await api.show(id);
        this.layout = response.data;
      } catch (error) {
        this.layout = { ...this.modelLayout };
        this.showAlert({ message: error.message, type: "error" });
      } finally {
        this.hideLoading();
      }
    },
    async getCompanies() {
      this.showLoading();
      try {
        let response = await apiCompany.getAll();
        this.companies = response
          .filter((item) => item.enabled === 1)
          .sort((a, b) => a.name.localeCompare(b.name));
      } catch (error) {
        this.companies = [];
        this.showAlert({ message: error.message, type: "error" });
      } finally {
        this.hideLoading();
      }
    },
    goToBackList() {
      this.$router.push(`/admin/modelo-email`);
    },
    validate() {
      if (this.$refs.form.validate()) {
        let data = { ...this.layout };
        data.enabled = this.layout.enabled ? 1 : 0;
        if (this.layout.id === "") {
          this.post(data);
        } else {
          this.update(this.layout.id, data);
        }
      }
    },
    async post(data) {
      this.showLoading();
      try {
        let res = await api.store(data);
        if (res) {
          this.showAlert({
            message: "Modelo de Email salvo com sucesso",
            type: "success",
          });
          this.goToBackList();
        }
      } catch (error) {
        this.showAlert({ message: error.message, type: "error" });
      } finally {
        this.hideLoading();
      }
    },
    async update(id, data) {
      console.log(data);
      this.showLoading();
      try {
        let res = await api.update(id, data);
        if (res) {
          this.showAlert({
            message: "Modelo de Email atualizado com sucesso",
            type: "success",
          });
          this.goToBackList();
        }
      } catch (error) {
        this.showAlert({ message: error.message, type: "error" });
      } finally {
        this.hideLoading();
      }
    },
    async deleteImage(arrayData) {
      if (arrayData.length > 0) {
        let image = arrayData[0];
        image = image.split('/').reverse()[0];
        this.showLoading();
        try {
          let response = api.deleteImage(image);
          console.log(response);
        } catch (error) {
          this.showAlert({ message: error.message, type: "error" });
        } finally {
          this.hideLoading();
        }
      }
    },
    setSizeEditor(size) {
      if (size === '100%') {
        // Size = tamanho do monitor
        this.editorStyle = {width: size};
      } else {
        // size é igual ao pixel passado via parametro
        this.editorStyle = {width: `${size}px`};
      }
    },
    sizeFullSize() {
      this.dialog = true
    },
  },
};

function MyCustomUploadAdapterPlugin( editor ) {
      editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
        // Configure the URL to the upload script in your back-end here!
        return new MyUploadAdapter( loader );
      };
    };

class MyUploadAdapter {
    constructor( loader ) {
        // The file loader instance to use during the upload.
        this.loader = loader;
    }

    // Starts the upload process.
    upload() {
      return this.loader.file
        .then(file => new Promise ( async (resolve, reject ) => {
          let formData = new FormData();
          formData.append("image", file);
          try {
            let response = await api.storeFile(formData);
            console.log(response);
            resolve( {
                default: response.default
            } );
          } catch (error) {
            console.log(error);
          }
      } ) );
    }

    // Aborts the upload process.
    abort() {
      console.log('abort');
    }
}
</script>

<style scoped>
.ck-editor__editable {
  min-height: 300px;
  max-height: 400px;
}
</style>