<template>
  <div>
    <Table
      title="Usuário"
      icon="mdi-account-multiple-outline"
      textNewButton="Novo Usuário"
      linkNewButton="/usuarios/novo"
      :headers="headers"
      :items="prepareData(filterData(items))"
      :onSelectedShowDisabled="selectedShowDisabled"
      :refreshTable="onClickRefreshTable"
      :sortBy="['id']"
      :showDisabled="false"
    >
      <template v-slot:actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              medium
              class="mr-2"
              color="teal darken-2"
              @click="showTokens(item)"
              v-if="validateTypeUser(item)"
              v-bind="attrs"
              v-on="on"
            >
              mdi-form-textbox-password
            </v-icon>
          </template>
          <span>Tokens</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              medium
              class="mr-2"
              color="blue"
              @click="editItem(item)"
              v-bind="attrs"
              v-on="on"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              medium
              @click="deleteItem(item)"
              color="red"
              v-bind="attrs"
              v-on="on"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>Deletar</span>
        </v-tooltip>
      </template>
    </Table>
    <DialogDelete
      message="Deseja realmente deletar esse Usuário?"
      :dialog="dialogDelete"
      :confirmDialog="handleConfirmDelete"
      :cancelDialog="handleCancelDelete"
    />
  </div>
</template>

<script>
import Table from "@/components/Table";
import DialogDelete from "@/components/DialogDelete";
import api from "@/api/user";
import { mapMutations, mapState } from "vuex";
export default {
  name: "UserList",
  components: {
    Table,
    DialogDelete
  },
  data: () => ({
    showDisabled: false,
    headers: [
      { text: "ID", value: "id", align: "end", width: 120 },
      { text: "Nome", value: "name", align: "start" },
      { text: "Email", value: "email", align: "start" },
      { text: "Data de Criação", value: "created_at" },
      { text: "Data de Atualização", value: "updated_at" },
      // { text: "Status", value: "enabled", width: 100 },
      {
        text: "Ações",
        value: "actions",
        align: "end",
        sortable: false,
        width: 200
      }
    ],
    items: [],
    dialogDelete: false,
    _itemDelete: {}
  }),
  computed: {
    ...mapState({
      companySelected: state => state.companySelected
    })
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapMutations(["showLoading", "setDrawer", "hideLoading", "showAlert"]),
    initialize() {
      this.setDrawer(false);
      this.getData();
    },
    async getData() {
      this.showLoading();
      this.items = [];
      try {
        let response = await api.get();
        this.items = response.data
          .filter(item => item.user_types_id === 2)
          .filter(item =>
            item.companies.findIndex(company => company.id === this.companySelected.id) !== -1
          );
      } catch (error) {
        this.items = [];
        this.showAlert({ message: error.message, type: "error" });
      } finally {
        this.hideLoading();
      }
    },
    onClickRefreshTable() {
      this.getData();
    },
    filterData(data) {
      return data;
      /**
      if (!this.showDisabled) {
        return data.filter(item => item.enabled === 1);
      } else {
        return data.filter(item => item.enabled === 0);
      }
       */
    },
    selectedShowDisabled() {
      this.showDisabled = !this.showDisabled;
    },
    prepareData(data) {
      return data.map(item => ({
        ...item,
        user_type_name: item.user_types.name,
        created_at: this.dateToBr(item.created_at),
        updated_at: this.dateToBr(item.updated_at)
      }));
    },
    dateToBr(date) {
      if (!date) return date;
      let [fullDate, minutes] = date.split(" ");
      return `${fullDate
        .split("-")
        .reverse()
        .join("/")} ${minutes}`;
    },
    editItem(item) {
      this.$router.push(`/usuarios/${item.id}/editar`);
    },
    showCompanies(item) {
      if (item.user_types_id === 1) {
        return;
      } else {
        this.$router.push(`/usuarios/${item.id}/empresas`);
      }
    },
    showTokens(item) {
      console.log(item);
    },
    deleteItem(item) {
      this.dialogDelete = true;
      this._itemDelete = item;
    },
    async handleConfirmDelete() {
      this.dialogDelete = false;
      this.showLoading();

      try {
        let res = await api.destroy(this._itemDelete.id);
        this.showAlert({
          message: "Usuário deletado com sucesso",
          type: "success"
        });
        this.getData();
      } catch (error) {
        if (error.response.status === 400) {
          this.showAlert({
            message: error.response.data.message,
            type: "error"
          });
        } else {
          this.showAlert({
            message: error.message,
            type: "error"
          });
        }
      } finally {
        this._itemDelete = {};
        this.hideLoading();
      }
    },
    handleCancelDelete() {
      this.dialogDelete = false;
      this._itemDelete = {};
    },
    validateTypeUser(item) {
      return item.user_types_id !== 1;
    }
  }
};
</script>

<style></style>
