<template>
    <div>
        <v-data-table :headers="headers" fixed-header :loading="loading" no-results-text="Nenhum registro encontrado"
            loading-text="Carregando" class="elevation-1" :server-items-length="totalItems" :options.sync="options"
            :footer-props="{
                showFirstLastPage: true,
                itemsPerPageOptions: [10, 20, 30, 40, 50, 100, 200],
                itemsPerPageText: 'Itens por página',
                showCurrentPage: true
            }" :items="prepareData(filterData(items.data))">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>
                        <v-icon>mdi-send</v-icon>
                        Log de Disparos
                    </v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>


                    <v-row align="center">
                        <template>
                            <v-btn class="" @click="dialogFilters = true">
                                <v-icon medium class="mr-2">
                                    mdi-filter
                                </v-icon>
                                <span>Filtrar</span>
                            </v-btn>
                            <v-btn class="ml-2" color="red" dark @click="openDialogDelete"
                                v-if="(filters.dateStart != null || filters.dateEnd != null) && filters.filtered == true">
                                <v-icon medium class="mr-2">
                                    mdi-delete
                                </v-icon>
                                <span>Apagar Log</span>
                            </v-btn>
                        </template>
                        <v-spacer></v-spacer>
                        <v-text-field v-model="search" @keyup.enter="searchEmail" append-icon="mdi-magnify"
                            label="Buscar por E-mail (Enter para Buscar)" single-line hide-details></v-text-field>
                    </v-row>

                </v-toolbar>
            </template>
            <template v-slot:item.status="{ item }">
                <v-chip :color="getColor(item)" dark>
                    {{ item.status }}
                </v-chip>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip bottom v-if="item.error">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon medium class="mr-2" color="red" @click="showBody(item, 'error')" v-bind="attrs" v-on="on">
                            mdi-alert-circle
                        </v-icon>
                    </template>
                    <span>Visualizar Erro</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon medium class="mr-2" color="blue" @click="showBody(item, '')" v-bind="attrs" v-on="on">
                            mdi-eye
                        </v-icon>
                    </template>
                    <span>Visualizar Mensagem</span>
                </v-tooltip>
            </template>
            <template v-slot:footer.page-text="items">
                {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
            </template>
        </v-data-table>
        <template>
            <v-dialog v-model="dialog" width="100%" style="min-height: 600px" @click:outside="dialogClose">
                <v-card v-if="itemShow" max-width="100%">
                    <v-card-title>
                        <span class="text-h5">Para: {{ itemShow.to }}</span>
                    </v-card-title>
                    <v-card-subtitle>
                        <span class="text-h6">Status: {{ itemShow.status }}</span>
                    </v-card-subtitle>
                    <v-divider></v-divider>
                    <v-card-text class="py-8 px-4"
                        style="max-width: 100%; white-space: normal; overflow-wrap: break-word; word-wrap: break-word;">
                        <div v-if="!itemShow.showError">
                            <span v-html="itemShow.body"></span>
                        </div>
                        <div v-if="itemShow.showError" max-width="100%">
                            <p>{{ itemShow.error }}</p>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="dialogClose">
                            OK
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </template>
        <template>
            <v-dialog v-model="dialogFilters" width="500">
                <v-card>
                    <v-card-title>
                        <h4>Filtros</h4>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col md="6">
                                    <v-text-field type="date" label="Data Inicial" :error-messages="errors.dateStart"
                                        v-model="filters.dateStart" outlined />
                                </v-col>
                                <v-col md="6">
                                    <v-text-field type="date" label="Data Final" :error-messages="errors.dateEnd"
                                        v-model="filters.dateEnd" outlined />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-select :items="status" v-model="filters.status" item-text="text" item-value="value"
                                        clearable label="Status" outlined></v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-select :items="services" v-model="filters.service" item-text="text"
                                        item-value="value" clearable label="Serviços" outlined></v-select>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="error" text @click="clearFilters">
                            Limpar Filtros
                        </v-btn>
                        <v-btn color="primary" text @click="filterTransactions">
                            Filtrar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </template>
        <template>
            <DialogDelete message="Deseja realmente deletar o log deste período?" :dialog="dialogDelete"
                :confirmDialog="handleConfirmDelete" :cancelDialog="() => dialogDelete = false" />
        </template>
    </div>
</template>

<script>
import Table from "@/components/Table";
import DialogDelete from "@/components/DialogDelete";
import api from "@/api/transaction";
import { mapMutations, mapState } from "vuex";
export default {
    name: "TransactionList",
    components: {
        Table,
        DialogDelete
    },
    data: () => ({
        dialog: false,
        showDisabled: false,
        headers: [
            { text: "ID", value: "id" },
            { text: "CT", value: "ct" },
            { text: "Serviço", value: "queue" },
            { text: "Track", value: "track", width: 300 },
            { text: "Para", value: "to", align: "start" },
            { text: "Status", value: "status", align: "start" },
            { text: "Data de Criação", value: "created_at" },
            { text: "Data de Atualização", value: "updated_at" },
            {
                text: "Ações",
                value: "actions",
                align: "end",
                sortable: false,
                width: 100
            }
        ],
        options: {
            page: 1,
            search: '',
        },
        filters: {
            dateStart: null,
            dateEnd: null,
            filtered: false,
        },
        dialogFilters: false,
        items: {
            data: [],
        },
        totalItems: 0,
        itemShow: [],
        dialogDelete: false,
        _itemDelete: {},
        loading: false,
        search: '',
        sortBy: [],
        status: [
            { value: 'SEND', text: 'ENVIADO' },
            { value: 'DELIVERED', text: 'ENTREGUE' },
            { value: 'REJECTED', text: 'REJEITADO' },
            { value: 'PROCESSING', text: 'EM PROCESSAMENTO' },
            { value: 'ERROR', text: 'ERRO' }
        ],
        services: [
            { value: 'sga', text: 'SGA' },
            { value: 'sgaweb', text: 'SGAWEB' },
            { value: 'sgaweb_boletos', text: 'SGAWEB BOLETOS' },
            { value: 'sgaweb_pix', text: 'SGAWEB PIX' }
        ],
        errors: {},
    }),
    computed: {
        ...mapState({
            companySelected: state => state.companySelected
        })
    },
    created() {
        document.title = 'SGAMail - Log de Disparos';
        this.initialize();
    },
    watch: {
        options: {
            handler() {
                this.getData();
            },
            deep: false,
        },
    },
    methods: {
        ...mapMutations(["showLoading", "setDrawer", "hideLoading", "showAlert"]),
        initialize() {
            this.setDrawer(false);
        },
        async getData() {
            this.loading = true;
            this.showLoading();
            this.items = [];

            let options = this.options;
            let filters = this.filters;

            options = { ...options, ...filters };

            try {
                let response = await api.get(options);
                this.items = response.data;
                this.filters.filtered = response.filtered ?? false;
                this.totalItems = response.data.total;
            } catch (error) {
                this.items = [];
                this.showAlert({ message: error.message, type: "error" });
            } finally {
                this.hideLoading();
                this.loading = false;
            }
            return;
        },
        filterTransactions() {
            if (this.filters.dateStart != '' && this.filters.dateEnd != '') {
                console.log(this.filters);
                if (!this.compareDate(this.filters.dateStart, this.filters.dateEnd)) {
                    // this.showAlert({ message: 'Para filtrar o log, é necessário filtrar um período válido.', type: 'error' });
                    this.errors = { ...this.errors, dateEnd: 'Data final não pode ser maior que a inicial.' };
                    return;
                }
            }
            this.errors = {};
            this.getData();
            this.dialogFilters = false;
        },

        searchEmail() {
            if (this.search != '')
                this.options = {
                    ...this.options,
                    search: this.search
                };
            else
                this.getData();

            return;
        },

        clearFilters() {
            this.filters = {
                dateStart: null,
                dateEnd: null
            };
            this.errors = {};
            this.dialogFilters = false;
            this.search = '';
            this.options = { ...this.options, search: '' };
        },

        onClickRefreshTable() {
            this.getData();
        },
        filterData(data) {
            return data;
        },
        selectedShowDisabled() {
            this.showDisabled = !this.showDisabled;
        },
        prepareData(data) {
            if (data == undefined)
                return [];
            return data.map(item => ({
                ...item,
                status: this.getStatus(item),
                created_at: this.dateToBr(item.created_at),
                updated_at: this.dateToBr(item.updated_at)
            }));
        },
        dateToBr(date) {
            if (!date) return date;
            let [fullDate, minutes] = date.split(" ");
            return `${fullDate
                .split("-")
                .reverse()
                .join("/")} ${minutes}`;
        },
        showBody(item, error) {
            this.dialog = true;
            this.itemShow = item;
            if (error != '') {
                this.itemShow['showError'] = true;
            }
        },
        dialogClose() {
            this.dialog = false;
            this.itemShow = [];
        },
        openDialogDelete() {
            if (this.items.data.lenght == 0) {
                this.showAlert({ message: 'Não há nenhum log para ser deletado', type: 'error' });
                return;
            }

            if (this.filters.dateStart == '' && this.filters.dateEnd == '') {
                this.showAlert({ message: 'Para executar a exclusão do log, é necessário filtrar um período.', type: 'error' });
                return;
            }

            if (!this.validDate(this.filters.dateStart) || !this.validDate(this.filters.dateEnd)) {
                this.showAlert({ message: 'Para executar a exclusão do log, é necessário filtrar um período.', type: 'error' });
                return;
            }

            this.errors = {};
            this.dialogDelete = true;
        },
        async handleConfirmDelete() {
            this.showLoading();
            this.loading = true;

            try {
                await api.destroy(this.filters);
                this.showAlert({ message: 'Log excluido com sucesso', type: 'success' });
                this.getData();
            } catch (error) {
                this.showAlert({ message: 'Ocorreu um erro ao excluir os dados, tente novamente.', type: 'error' });
            } finally {
                this.hideLoading();
                this.loading = false;
                this.dialogDelete = false;
            }
        },
        getColor(item) {
            if (item.status == 'ENTREGUE') {
                return 'green';
            }

            if (item.status == 'REJEITADO' || item.status == 'ERRO') {
                return 'red';
            }

            if (item.type == 'sms') {
                return 'orange';
            } else {
                return 'green';
            }
        },
        getStatus(item) {
            switch (item.status) {
                case 'SENT':
                    return 'ENVIADO';
                    break;
                case 'SEND':
                    return 'ENVIADO';
                    break;
                case 'DELIVERED':
                    return 'ENTREGUE';
                    break;
                case 'REJECTED':
                    return 'REJEITADO';
                    break;
                case 'PROCESSING':
                    return 'EM PROCESSAMENTO';
                    break;
                case 'ERROR':
                    return 'ERRO';
                    break;
                default:
                    return 'SEM STATUS';
                    break;
            }
        },
        validDate(d) {
            let date = new Date(d);
            return !isNaN(date.getTime());
        },
        compareDate(dateA, dateB) {
            let a = new Date(dateA);
            let b = new Date(dateB);
            return b >= a;
        }
    }
};
</script>

<style>
</style>
