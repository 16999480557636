<template>
  <div>
    <div class="elevation-1">
      <HeaderForm
        title="Modelo de Email"
        icon="mdi-email-plus-outline"
        :id="`${layout.id}`"
        :name="layout.title"
        :onClickBackButton="goToBackList"
      />
      <v-form ref="form" v-model="valid" :lazy-validation="lazy">
        <v-container fluid>
          <v-row>
            <v-col cols="12" xs="12" md="6">
              <v-text-field
                v-model="layout.title"
                label="Titulo"
                dense
                outlined
                required
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" md="4" >
            </v-col>
            <v-col cols="12" xs="12" md="2">
              <v-switch v-model="layout.enabled" dense label="Ativo"></v-switch>
            </v-col>
          </v-row>
          <EmailEditor :body="layout.body" :updateBody="changeBodyEmail" />
          <v-row>
            <v-col>
              <v-btn color="blue darken-3" dark class="mt-5" @click="validate">
                <v-icon left>mdi-send</v-icon> Salvar
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </div>
  </div>
</template>
<script>
import HeaderForm from "@/components/HeaderForm";
import EmailEditor from "@/components/EmailEditor";
import { mapMutations, mapState } from "vuex";

import api from "@/api/layout";

export default {
  components: {
    HeaderForm,
    EmailEditor,
  },
  data: () => ({
    modelLayout: {
      id: "",
      title: "",
      companies_id: "",
      body: "",
      enabled: 1,
    },
    dialog: false,
    layout: {},
    companies: [],
    valid: true,
    lazy: false,
    rules: {
      required: (value) => !!value || "Campo Obrigatorio",
    },
  }),
  computed: {
    ...mapState({
      companySelected: state => state.companySelected
    }),
  },
  created() {
    this.layout = { ...this.modelLayout };
    this.initialize();
  },
  methods: {
    ...mapMutations(["showLoading", "hideLoading", "showAlert"]),
    async initialize() {
      if (this.$route.params.id) {
        this.getLayout(this.$route.params.id);
      }
    },
    async getLayout(id) {
      this.showLoading();
      try {
        let response = await api.show(id);
        this.layout = response.data;
      } catch (error) {
        this.layout = { ...this.modelLayout };
        this.showAlert({ message: error.message, type: "error" });
      } finally {
        this.hideLoading();
      }
    },
    goToBackList() {
      this.$router.push(`/modelo-email`);
    },
    validate() {
      if (this.$refs.form.validate()) {
        let data = { ...this.layout };
        data.enabled = this.layout.enabled ? 1 : 0;
        if (this.layout.id === "") {
          data.companies_id = this.companySelected.id;
          this.post(data);
        } else {
          this.update(this.layout.id, data);
        }
      }
    },
    async post(data) {
      this.showLoading();
      try {
        let res = await api.store(data);
        if (res) {
          this.showAlert({
            message: "Modelo de Email salvo com sucesso",
            type: "success",
          });
          this.goToBackList();
        }
      } catch (error) {
        this.showAlert({ message: error.message, type: "error" });
      } finally {
        this.hideLoading();
      }
    },
    async update(id, data) {
      console.log(data);
      this.showLoading();
      try {
        let res = await api.update(id, data);
        if (res) {
          this.showAlert({
            message: "Modelo de Email atualizado com sucesso",
            type: "success",
          });
          this.goToBackList();
        }
      } catch (error) {
        this.showAlert({ message: error.message, type: "error" });
      } finally {
        this.hideLoading();
      }
    },
    changeBodyEmail(newValue) {
      this.layout.body = newValue;
    }
  },
};
</script>
