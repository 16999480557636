<template>
    <div>
        <Table title="Produtos" enabledNewButton="false" :headers="headers" :items="prepareData(filterData(items))"
            :sortBy="['id']" :refreshTable="onClickRefreshTable" :onSelectedShowDisabled="selectedShowDisabled"
            :showDisabled="true" :loading="loadingTable">
            <template v-slot:actions="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon medium class="mr-2" color="teal darken-2" v-bind="attrs" v-on="on"
                            @click="companySync(item)">
                            mdi-domain
                        </v-icon>
                    </template>
                    <span>Empresas</span>
                </v-tooltip>
            </template>
        </Table>
    </div>
</template>

<script>

import api from "@/api/product";
import Table from '@/components/Table';
import { mapMutations } from "vuex";
export default {
    name: 'ProductList',
    data: () => ({
        showDisabled: false,
        loadingTable: false,
        headers: [
            {
                text: 'Nome', value: 'product_name', align: 'start',
            },
            {
                text: 'Ativo', value: 'enabled', align: 'start',
            },
            {
                text: "Ações",
                value: "actions",
                align: "end",
                sortable: false,
                width: 200,
            },
        ],
        items: []
    }),
    components: {
        Table,
    },
    created() {
        this.initialize();
    },
    methods: {
        ...mapMutations(["showLoading", "setDrawer", "hideLoading", "showAlert"]),
        initialize() {
            this.setDrawer(false);
            this.getData();
        },
        async getData() {
            this.loadingTable = true;
            this.showLoading();
            try {
                let response = await api.get();
                console.log(response);
                this.items = response.data;
            } catch (error) {
                this.items = [];
                this.showAlert({
                    message: error.message,
                    type: 'error'
                });
            } finally {
                this.loadingTable = false;
                this.hideLoading();
            }

        },
        onClickRefreshTable() {
            this.getData();
        },
        filterData(data) {
            if (!this.showDisabled) {
                return data.filter((item) => item.enabled == 1);
            } else {
                return data.filter((item) => item.enabled == 0);
            }
        },
        selectedShowDisabled() {
            this.showDisabled = !this.showDisabled;
        },
        prepareData(data) {
            return data.map((item) => ({
                ...item
            }));
        },
        companySync(item) {
            this.$router.push(`/admin/produtos/${item.id}/empresas`);
        },
    }
}
</script>
