<template>
  <div>
    <div class="elevation-1">
      <HeaderForm
        title="Appbox"
        icon="mdi-database-cog-outline"
        :id="`${appbox.id}`"
        :name="appbox.title"
        :onClickBackButton="goToBackList"
      />
      <v-form ref="form" v-model="valid" :lazy-validation="lazy">
        <v-container fluid>
          <v-row>
            <v-col cols="12" xs="12" md="5">
              <v-text-field
                v-model="appbox.title"
                label="Nome"
                outlined
                required
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="8" xs="8" md="5">
              <v-text-field
                v-model="appbox.hostname"
                :rules="[rules.required]"
                label="Host"
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col cols="4" xs="4" md="2">
              <v-select
                :items="encryptions"
                v-model="appbox.encryption"
                :rules="[rules.required]"
                label="Encriptação"
                required
                outlined
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xs="12" md="5">
              <v-text-field
                v-model="appbox.username"
                :rules="[rules.required]"
                label="Usuário"
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col cols="8" xs="8" md="5">
              <v-text-field
                v-model="appbox.password"
                :rules="[rules.required]"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                label="Senha"
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col cols="4" xs="4" md="2">
              <v-text-field
                v-model="appbox.port_number"
                :rules="[rules.required]"
                label="Porta"
                outlined
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xs="12" sm="4">
              <v-text-field
                v-model="appbox.from_name"
                :rules="[rules.required]"
                label="Nome Rementente"
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="4">
              <v-text-field
                v-model="appbox.from_address"
                :rules="[rules.required]"
                label="Email Remetente"
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="4">
              <v-text-field
                v-model="appbox.reply_to"
                :rules="[rules.required]"
                label="Endereço de Resposta"
                outlined
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-switch
                :disabled="disableHasCompanies()"
                v-model="appbox.enabled"
                label="Ativo"
                :messages="messageEnabled"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn color="blue darken-3" dark class="mt-5" @click="validate">
                <v-icon left>mdi-send</v-icon> Salvar
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </div>
  </div>
</template>
<script>
import HeaderForm from "@/components/HeaderForm";
import { mapMutations } from "vuex";
import api from "@/api/appbox";
export default {
  components: {
    HeaderForm,
  },
  data: () => ({
    modelAppbox: {
      id: "",
      title: "",
      hostname: "",
      encryption: "",
      port_number: "",
      username: "",
      password: "",
      from_name: "",
      from_address: "",
      reply_to: "",
      companies: [],
      enabled: 1,
    },
    appbox: {},
    showPassword: false,
    encryptions: ["tls", "ssl"],
    valid: true,
    lazy: false,
    rules: {
      required: (value) => !!value || "Campo Obrigatorio",
    },
    messageEnabled: '',
  }),
  created() {
    this.appbox = { ...this.modelAppbox };
    this.initialize();
  },
  methods: {
    ...mapMutations(["showLoading", "hideLoading", "showAlert"]),
    async initialize() {
      if (this.$route.params.id) {
        this.showLoading();
        try {
          let response = await api.show(this.$route.params.id);
          this.appbox = response.data;
        } catch (error) {
          this.appbox = { ...this.modelAppbox };
          this.showAlert({ message: error.message, type: "error" });
        } finally {
          this.hideLoading();
        }
      }
    },
    goToBackList() {
      this.$router.push(`/admin/appbox`);
    },
    validate() {
      console.log(this.appbox.enabled);
      let data = { ...this.appbox };
      data.enabled = this.appbox.enabled ? 1 : 0;
      if (this.appbox.id === "") {
        this.post(data);
      } else {
        this.update(this.appbox.id, data);
      }
    },
    async post(data) {
      this.showLoading();
      try {
        let res = await api.store(data);
        if (res) {
          this.showAlert({
            message: "Appbox salva com sucesso",
            type: "success",
          });
          this.goToBackList();
        }
      } catch (error) {
        this.showAlert({ message: error.message, type: "error" });
      } finally {
        this.hideLoading();
      }
    },
    async update(id, data) {
      console.log(data);
      this.showLoading();
      try {
        let res = await api.update(id, data);
        if (res) {
          this.showAlert({
            message: "Appbox atualizada com sucesso",
            type: "success",
          });
          this.goToBackList();
        }
      } catch (error) {
        this.showAlert({ message: error.message, type: "error" });
      } finally {
        this.hideLoading();
      }
    },
    disableHasCompanies() {
      if (this.appbox.companies.length > 0) {
        this.messageEnabled = 'Não é possível desativar uma appbox que possui empresas vinculadas';
        return true;
      } else {
        this.messageEnabled = '';
        return false;
      }
    },
  },
};
</script>
