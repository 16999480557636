<template>
    <v-container fluid d-flex style="height: 90vh">
        <v-layout class="d-flex justify-center align-center">
            <v-flex sm12 md4>
                <v-form ref="form">
                    <v-card class="px-4 py-4">
                        <v-card-title>
                            <v-flex class="d-flex justify-center">
                                <img src="img/logo-sgasoft.png" height="110" />
                            </v-flex>
                        </v-card-title>
                        <v-card-text>
                            <v-alert v-if="showError" color="red" dark>{{ messageError }}</v-alert>
                            <v-text-field ref="email" :rules="emailRules" v-model="email" label="E-mail" required
                                autocomplete="no"></v-text-field>
                            <v-text-field ref="senha" :rules="[() => !!password || 'O campo é obrigatório']"
                                v-model="password" label="Senha" type="password" required autocomplete="no"
                                @keydown.enter.prevent="logar"></v-text-field>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-space-between flex-row-reverse">
                            <v-btn color="primary" @click="logar">
                                Entrar
                            </v-btn>
                            <v-btn depressed plain small class="pt-2" @click="recuperarSenha">
                                Esqueceu sua senha?
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-flex>
        </v-layout>
    </v-container>
</template>



<script>
import api from "@/api/auth";
import { mapMutations } from "vuex";
export default {
    props: {
        source: String
    },
    data: () => ({
        loading: false,
        showError: false,
        messageError: '',
        valid: true,
        email: '',
        password: '',
        emailRules: [
            v => !!v || 'E-mail é obrigatório',
            v => /.+@.+\..+/.test(v) || 'E-mail é inválido',
        ]
    }),
    created() {
        this.setDrawer(false);
        this.hideLoading();
        document.title = 'SGAMail - Login';
    },
    methods: {
        ...mapMutations([
            "setIsAdmin",
            "setCompanies",
            "setUser",
            "setDrawer",
            "setCompanySelected",
            "showLoading",
            "hideLoading",
            "showAlert"
        ]),
        async logar() {
            if (!this.$refs.form.validate()) {
                return;
            }

            this.showLoading();
            this.loading = true;
            this.showError = false;

            try {
                let res = await api.login({
                    email: this.email,
                    password: this.password
                });
                res = await res;
                await this.getAuthUser();
            } catch (error) {
                this.messageError = 'E-mail ou senha incorretos.';
                this.hideLoading();
                this.showError = true;
            } finally {
                this.loading = false;
            }
        },
        async getAuthUser() {
            try {
                let response = await api.authUser();
                this.setUser(response.data);
                this.setCompanies(response.data.companies);
                this.setIsAdmin(response.data.user_types_id === 1);
                this.setCompanySelected = {};
                if (response.data.user_types_id === 1) {
                    this.$router.push("/admin/dashboard");
                } else {
                    this.$router.push("/escolher-empresa");
                }
            } catch (error) {
                throw error;
            }
        },
        recuperarSenha() {
            console.log('recuperar senha');
            this.$router.push("/recuperar-senha");
        }
    }
};
</script>
