<template>
  <v-app-bar
    app
    dense
    dark
    :class="!isAdmin ? 'green darken-1' : 'grey darken-4'"
    :clipped-left="$vuetify.breakpoint.lgAndUp"
  >
    <v-app-bar-nav-icon v-if="loggedIn" @click="clickNavIcon"></v-app-bar-nav-icon>
    <div class="d-flex align-center">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
    </div>
  </v-app-bar>
</template>

<script>
export default {
  props: {
    title: String,
    isAdmin: Boolean,
    loggedIn: Boolean,
    clickNavIcon: Function
  }
};
</script>
