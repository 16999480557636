<template>
  <div>
    <div class="elevation-1">
      <HeaderForm title="SMS Appbox" icon="mdi-database-cog-outline" :id="`${appbox.id}`" :name="appbox.name"
        :onClickBackButton="goToBackList" />
      <v-form ref="form" v-model="valid" :lazy-validation="lazy">
        <v-container fluid>
          <v-row>
            <v-col cols="12" xs="12" md="4">
              <v-text-field v-model="appbox.name" label="Nome" outlined required
                :rules="[rules.required]"></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" md="4">
              <v-text-field v-model="appbox.from" label="Telefone" outlined required
                :rules="[rules.required]"></v-text-field>
            </v-col>
            <v-col cols="8" xs="8" md="4">
              <v-text-field v-model="appbox.from_whatsapp" label="Telefone WhatsApp" outlined></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-text-field v-model="appbox.url" :rules="[rules.required]" label="URL" outlined required></v-text-field>
            </v-col>
            <v-col cols="8">
              <v-text-field v-model="appbox.token" :rules="[rules.required]" label="Token" outlined
                required></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-template>
                <v-subheader> Webhook </v-subheader>
                <v-divider></v-divider>
              </v-template>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xs="12" md="10">
              <v-select :items="listWebhooks" v-model="appbox.webhook" :rules="[rules.required]" label="Webhook URL"
                outlined required></v-select>
            </v-col>
          </v-row>
          <v-row v-if="appbox.webhook_token">
            <v-col cols="12" md="10">
              <v-text-field v-model="appbox.webhook_token" label="Token" outlined readonly filled></v-text-field>
            </v-col>
            <v-col>
              <v-btn color="primary darken-3" large dark class="mt-1" @click="updateToken">
                <v-icon>mdi-reload</v-icon> Atualizar Token
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-switch :disabled="disableHasCompanies()" v-model="appbox.enabled" label="Ativo"
                :messages="messageEnabled"></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn color="blue darken-3" dark class="mt-5" @click="validate">
                <v-icon left>mdi-send</v-icon> Salvar
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </div>
  </div>
</template>
<script>
import HeaderForm from "@/components/HeaderForm";
import { mapMutations } from "vuex";
import api from "@/api/smsappbox";
export default {
  components: {
    HeaderForm,
  },
  data: () => ({
    modelAppbox: {
      id: "",
      from: "",
      from_whatsapp: "",
      name: "",
      url: "",
      token: "",
      webhook: "",
      webhook_token: "",
      companies: [],
      enabled: 1,
    },
    listWebhooks: [{
      text: 'http://api.sgamail.com.br/api/webhook',
      value: 'http://api.sgamail.com.br/api/webhook',
      selected: true,
    }],
    appbox: {},
    showPassword: false,
    valid: true,
    lazy: false,
    rules: {
      required: (value) => !!value || "Campo Obrigatorio",
    },
    messageEnabled: '',
  }),
  created() {
    this.appbox = { ...this.modelAppbox };
    this.initialize();
  },
  methods: {
    ...mapMutations(["showLoading", "hideLoading", "showAlert"]),
    async initialize() {
      if (this.$route.params.id) {
        this.showLoading();
        try {
          let response = await api.show(this.$route.params.id);
          this.appbox = response.data;
        } catch (error) {
          this.appbox = { ...this.modelAppbox };
          this.showAlert({ message: error.message, type: "error" });
        } finally {
          this.hideLoading();
        }
      }
    },
    goToBackList() {
      this.$router.push(`/admin/sms/appbox`);
    },
    validate() {
      console.log(this.appbox.enabled);
      let data = { ...this.appbox };
      data.enabled = this.appbox.enabled ? 1 : 0;
      if (this.appbox.id === "") {
        this.post(data);
      } else {
        this.update(this.appbox.id, data);
      }
    },
    async post(data) {
      this.showLoading();
      try {
        let res = await api.store(data);
        if (res) {
          this.showAlert({
            message: "Appbox salva com sucesso",
            type: "success",
          });
          this.goToBackList();
        }
      } catch (error) {
        this.showAlert({ message: error.message, type: "error" });
      } finally {
        this.hideLoading();
      }
    },
    async update(id, data) {
      this.showLoading();
      try {
        let res = await api.update(id, data);
        if (res) {
          this.showAlert({
            message: "Appbox atualizada com sucesso",
            type: "success",
          });
          this.goToBackList();
        }
      } catch (error) {
        this.showAlert({ message: error.message, type: "error" });
      } finally {
        this.hideLoading();
      }
    },
    disableHasCompanies() {
      if (this.appbox.companies.length > 0) {
        this.messageEnabled = 'Não é possível desativar uma appbox que possui empresas vinculadas';
        return true;
      } else {
        this.messageEnabled = '';
        return false;
      }
    },
    async updateToken() {
      this.showLoading();

      const id = this.appbox.id;
      if (id == "") {
        return;
      }
      try {
        let res = await api.updateToken(id);
        if (res) {
          if (res.webhook_token != "") {
            this.appbox.webhook_token = res.webhook_token;

            this.showAlert({
              message: 'Token atualizado com sucesso',
              type: 'success',
            });
          } else {
            this.showAlert({
              message: 'Ocorreu um erro ao atualizar o token, tente novamente',
              type: 'error'
            });
          }
        }
      } catch (error) {
        this.showAlert({
          message: error.message,
          type: error,
        });
      } finally {
        this.hideLoading();
      }
    }
  },
};
</script>
