import baseApi from "./baseApi";

const endpoint = "layout-sms";

export default {
  get: page => baseApi.get(endpoint, page),
  show: id => baseApi.show(endpoint, id),
  store: data => baseApi.store(endpoint, data),
  update: (id, data) => baseApi.update(endpoint, id, data),
  destroy: id => baseApi.destroy(endpoint, id),
};
