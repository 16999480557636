import baseApi from "./baseApi";

const endpoint = "transaction";

export default {
    // get: page => baseApi.get(endpoint, page),
    get: options => {
        let page = options.page != undefined ? options.page : 1;
        let itemsPerPage = options.itemsPerPage != undefined ? options.itemsPerPage : 10;
        let link = endpoint + `?page=${page}&itemsPerPage=${itemsPerPage}`;
        if (options.status != undefined)
            link = link + '&status=' + options.status;
        if (options.search != '')
            link = link + '&search=' + options.search;
        if (options.dateStart != null && options.dateStart != '')
            link = link + '&dateStart=' + options.dateStart;
        if (options.dateEnd != null && options.dateEnd != '')
            link = link + '&dateEnd=' + options.dateEnd;

        if (options.service != null && options.service != '')
            link = link + '&service=' + options.service;

        return baseApi.get(link);
    },

    destroy: options => baseApi.destroy(endpoint, 0, options)
};
