<template>
    <v-navigation-drawer :value="drawer" app dark :class="!isAdmin ? 'green darken-1' : 'grey darken-4'"
        :clipped="$vuetify.breakpoint.lgAndUp">
        <template v-slot:prepend>
            <v-list-item two-line>
                <v-list-item-content>
                    <v-list-item-title class="text-center">{{
                        user.name
                    }}</v-list-item-title>
                    <v-list-item-subtitle class="text-center">
                        <v-tooltip bottom v-if="!isAdmin">
                            <template v-slot:activator="{ on }">
                                <v-btn text small v-on="on" to="/escolher-empresa">
                                    {{ company.name }}
                                </v-btn>
                            </template>
                            <span>Trocar Empresa</span>
                        </v-tooltip>
                        <span v-else>Administrador</span>
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </template>

        <v-divider></v-divider>

        <v-list dense nav>
            <v-list-item v-for="item in getItemsMenu()" :key="item.title" :to="item.link" link>
                <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>

        <template v-slot:append>
            <div class="pa-2">
                <v-btn block light v-if="loggedIn" @click="clickLogout">
                    <v-icon left color="red darken-4">
                        mdi-logout-variant
                    </v-icon>
                    Sair
                </v-btn>
            </div>
        </template>
    </v-navigation-drawer>
</template>

<script>
export default {
    props: {
        drawer: Boolean,
        isAdmin: Boolean,
        loggedIn: Boolean,
        application: Object,
        user: Object,
        clickLogout: Function,
        company: Object
    },
    data() {
        return {
            itemsAdmin: [
                {
                    title: "Dashboard",
                    link: "/admin/dashboard",
                    icon: "mdi-home-outline"
                },
                {
                    title: "Appbox",
                    link: "/admin/appbox",
                    icon: "mdi-database-cog-outline"
                },
                {
                    title: 'Appbox SMS',
                    link: '/admin/sms/appbox',
                    icon: 'mdi-message-outline'
                },
                { title: "Empresas", link: "/admin/empresas", icon: "mdi-domain" },
                {
                    title: "Modelo de Email",
                    link: "/admin/modelo-email",
                    icon: "mdi-email-plus-outline"
                },
                {
                    title: "Modelo de SMS",
                    link: "/admin/modelo-sms",
                    icon: "mdi-message-alert-outline"
                },
                {
                    title: "Serviços",
                    link: "/admin/servicos",
                    icon: "mdi-message-cog-outline"
                },
                {
                    title: "Produtos",
                    link: "/admin/produtos",
                    icon: "mdi-cart-outline"
                },
                {
                    title: "Usuários",
                    link: "/admin/usuarios",
                    icon: "mdi-account-multiple-outline"
                },
                {
                    title: "Token API",
                    link: "/admin/api-token",
                    icon: "mdi-key"
                },
                {
                    title: "Falhas de Envio",
                    link: "/admin/falhas-envio",
                    icon: "mdi-close-octagon"
                },
                {
                    title: 'Log de Disparos',
                    link: '/admin/log-disparos',
                    icon: 'mdi-send'
                },
                {
                    title: "Configurações",
                    link: "/admin/configuracoes",
                    icon: "mdi-cog-outline"
                }
            ],
            itemsNotAdmin: [
                {
                    title: 'Dashboard',
                    link: '/dashboard',
                    icon: 'mdi-home-outline',
                },
                {
                    title: "Lista de Contatos",
                    link: "/lista-contatos",
                    icon: "mdi-contacts-outline"
                },
                {
                    title: "Modelo de Email",
                    link: "/modelo-email",
                    icon: "mdi-email-plus-outline"
                },
                {
                    title: "Modelo de SMS",
                    link: "/modelo-sms",
                    icon: "mdi-message-alert-outline"
                },
                {
                  title: "Modelo de WhatsApp",
                  link: "/modelo-whatsapp",
                  icon: ""
                },
                { title: "Campanha", link: "/campanhas", icon: "mdi-bullhorn-outline" },
                {
                    title: "Usuários",
                    link: "/usuarios",
                    icon: "mdi-account-multiple-outline"
                },
                {
                    title: "Log de Disparos",
                    link: "/log-disparos",
                    icon: "mdi-send"
                },
                {
                    title: "Campos Adicionais",
                    link: "/campos-adicionais",
                    icon: "mdi-fields"
                }
                // {
                //     title: "Configurações",
                //     link: "/configuracoes",
                //     icon: "mdi-cog-outline"
                // }
            ]
        };
    },
    methods: {
        getItemsMenu() {
            return this.isAdmin ? this.itemsAdmin : this.itemsNotAdmin;
        },
    }
};
</script>
