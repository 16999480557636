<template>
  <div>
    <div class="elevation-1">
      <HeaderForm title="Modelo de SMS" icon="mdi-message-alert-outline" :id="`${layout.id}`" :name="layout.title" :onClickBackButton="goToBackList" />
      <v-form ref="form" v-model="valid" :lazy-validation="lazy">
        <v-container fluid>
          <v-row>
            <v-col cols="12" xs="12" md="8">
              <v-text-field
                v-model="layout.title"
                label="Titulo"
                outlined
                required
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" md="4">
              <v-select
                :items="companies"
                v-model="layout.companies_id"
                item-text="name"
                item-value="id"
                :rules="[rules.required]"
                label="Empresa"
                required
                outlined
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xs="12" lg="12">
               <v-textarea
                  counter
                  rows="4"
                  auto-grow
                  outlined
                  v-model="layout.body"
                  name="input-7-1"
                  label="Corpo do SMS"
                ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-switch v-model="layout.enabled" label="Ativo"></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                color="blue darken-3"
                dark
                class="mt-5"
                @click="validate"
              >
                <v-icon left>mdi-send</v-icon> Salvar
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </div>
  </div>
</template>
<script>
import HeaderForm from '@/components/HeaderForm';
import { mapMutations } from 'vuex';
import api from '@/api/layout-sms';
import apiCompany from "@/api/company";
export default {
  components: {
    HeaderForm,
  },
  data: () => ({
    modelLayout: {
      id: "",
      title: "",
      body: "",
      enabled: 1
    },
    layout: {},
    companies: [],
    valid: true,
    lazy: false,
    rules: {
      required: value => !!value || "Campo Obrigatorio"
    },
  }),
  created() {
    this.layout = {...this.modelLayout};
    this.initialize();
  },
  methods: {
    ...mapMutations([
      'showLoading',
      'hideLoading',
      'showAlert',
    ]),
    async initialize() {
      this.getCompanies();
      if (this.$route.params.id) {
        this.getLayout(this.$route.params.id);
      }
    },
    async getLayout(id) {
      this.showLoading();
      try {
        let response = await api.show(id);
        this.layout = response.data;
      } catch (error) {
        this.layout = {...this.modelLayout};
        this.showAlert({message: error.message, type: 'error'});
      } finally {
        this.hideLoading();
      }
    },
    async getCompanies() {
      this.showLoading();
      try {
        let response = await apiCompany.getAll();
        this.companies = response.filter(item => item.enabled === 1).sort((a,b) => a.name.localeCompare(b.name));
      } catch (error) {
        this.companies = [];
        this.showAlert({message: error.message, type: 'error'});
      } finally {
        this.hideLoading();
      }
    },
    goToBackList() {
      this.$router.push(`/admin/modelo-sms`);
    },
    validate() {
      console.log(this.layout.enabled);
      let data = {...this.layout};
      data.enabled = (this.layout.enabled ? 1 : 0);
      if (this.layout.id === "") {
        this.post(data);
      } else {
        this.update(this.layout.id, data);
      }
    },
    async post(data) {
      this.showLoading();
      try {
        let res = await api.store(data);
        if (res) {
          this.showAlert({message: 'Modelo de SMS salvo com sucesso', type: 'success'});
          this.goToBackList();
        }
      } catch (error) {
        this.showAlert({message: error.message, type: 'error'});
      } finally {
        this.hideLoading();
      }
    },
    async update(id, data) {
      console.log(data);
      this.showLoading();
      try {
        let res = await api.update(id, data);
        if (res) {
          this.showAlert({message: 'Modelo de SMS atualizado com sucesso', type: 'success'});
          this.goToBackList();
        }
      } catch (error) {
        this.showAlert({message: error.message, type: 'error'});
      } finally {
        this.hideLoading();
      }
    }
  }
};
</script>