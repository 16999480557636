<template>
    <v-app id="inspire">
        <v-main>
            <Header v-if="(!unsubscribe && loggedIn)" :title="application.name" :drawer="drawer"
                :clickNavIcon="toogleDrawer" :isAdmin="isAdmin" :loggedIn="loggedIn" />
            <Sidebar v-if="loggedIn" :drawer="drawer" :application="application" :user="user" :company="company"
                :isAdmin="isAdmin" :loggedIn="loggedIn" :clickLogout="logout" />
            <v-container fluid>
                <v-row>
                    <v-col>
                        <Alert :alert="alert" :timer="5" type="success" :text="messageAlert" />
                        <v-overlay z-index="10" :value="overlay" opacity="0.85" color="white">
                            <v-progress-circular color="green darken-2" indeterminate size="120"></v-progress-circular>
                        </v-overlay>
                        <router-view></router-view>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import api from "@/api/auth";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import Alert from "@/components/Alert";
export default {
    name: "App",
    components: {
        Header,
        Sidebar,
        Alert
    },
    data: () => ({
        loggedIn: false,
        unsubscribe: false,
        alert: false,
        messageAlert: "",
        closeAlert: "",
        application: {
            name: "SGAMail"
        },
    }),
    computed: {
        ...mapState({
            overlay: state => state.loading,
            user: state => state.user,
            isAdmin: state => state.isAdmin,
            company: state => state.companySelected,
            drawer: state => state.drawer,
        })
    },
    created() {
        document.title = "SGAMail";
        this.loggedIn = api.loggedIn();
        if (!this.loggedIn) {
            this.setDrawer(false);
            if (
                this.$router.currentRoute.name != 'Unsubscribe' &&
                this.$router.currentRoute.name != 'RememberPassword' &&
                this.$router.currentRoute.name != 'RememberPasswordReset')
                this.goToLogin();
            else
                this.unsubscribe = true;
        }

    },
    updated() {
        this.loggedIn = api.loggedIn();
        this.pageTitles();

    },
    methods: {
        ...mapMutations(["setDrawer"]),
        toogleDrawer() {
            this.setDrawer(!this.drawer);
        },
        goToLogin() {
            this.$router.push("/login").catch(() => { });
            return;
        },
        logout() {
            api.logout();
            this.setDrawer(false);
            this.goToLogin();
        },
        pageTitles() {
            let $prefixTitle = 'SGAMail - ';
            const { title } = this.$route.meta;
            if (title == '' || title == undefined)
                document.title = 'SGAMail';
            else
                document.title = $prefixTitle + title;

        }
    },
};
</script>
