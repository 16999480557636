import { HTTP, getAuthorization, getCompanyToken } from "./config-axios";

export default {
    get: function (url, page) {
        return new Promise((res, rej) => {
            HTTP.get(`/${url}`, {
                headers: {
                    Authorization: getAuthorization(),
                    'token-company': getCompanyToken()
                },
                params: {
                    page,
                }
            })
                .then(response => res(response.data))
                .catch(error => rej(error));
        });
    },

    show: function (url, id) {
        return new Promise((res, rej) => {
            HTTP.get(`/${url}/${id}`, {
                headers: {
                    Authorization: getAuthorization(),
                    'token-company': getCompanyToken()
                },
            })
                .then(response => res(response.data))
                .catch(error => rej(error));
        });
    },

    store: function (url, data) {
        const dataPost = JSON.stringify(data);
        return new Promise((res, rej) => {
            HTTP.post(`/${url}`, dataPost, {
                headers: {
                    Authorization: getAuthorization(),
                    'token-company': getCompanyToken()
                }
            })
                .then(response => res(response.data))
                .catch(error => rej(error));
        });
    },

    post: function (url, data = []) {
        const dataPost = JSON.stringify(data);
        return new Promise((res, rej) => {
            HTTP.post(`/${url}`, dataPost, {
                headers: {
                    Authorization: getAuthorization(),
                    'token-company': getCompanyToken()
                }
            })
                .then(response => res(response.data))
                .catch(error => rej(error));
        });
    },

    storeFile: function (url, data) {
        return new Promise((res, rej) => {
            HTTP.post(`/${url}`, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: getAuthorization(),
                    'token-company': getCompanyToken()
                }
            })
                .then(response => res(response.data))
                .catch(error => rej(error));
        });
    },

    update: function (url, id, data) {
        let dataUpdate = JSON.stringify(data);
        return new Promise((res, rej) => {
            HTTP.put(`/${url}/${id}`, dataUpdate, {
                headers: {
                    Authorization: getAuthorization(),
                    'token-company': getCompanyToken()
                }
            })
                .then(response => res(response.data))
                .catch(error => rej(error));
        });
    },

    patch: function (url, id, data) {
        let dataPatch = JSON.stringify(data);
        return new Promise((res, rej) => {
            HTTP.patch(`/${url}/${id}`, dataPatch, {
                headers: {
                    Authorization: getAuthorization(),
                    'token-company': getCompanyToken()
                }
            })
                .then(response => res(response.data))
                .catch(error => rej(error));
        });
    },

    destroy: function (url, id, props = {}) {
        return new Promise((res, rej) => {
            HTTP.delete(`/${url}/${id}`, {
                headers: {
                    Authorization: getAuthorization(),
                    'token-company': getCompanyToken()
                },
                params: props
            })
                .then(response => res(response.data))
                .catch(error => rej(error));
        });
    },

    search: function (url, term, page) {
        let data = JSON.stringify({ searchTerm: term });
        return new Promise((res, rej) => {
            HTTP.post(`/${url}/search`, data, {
                headers: {
                    Authorization: getAuthorization(),
                    'token-company': getCompanyToken()
                },
                params: {
                    page
                }
            })
                .then(response => res(response.data))
                .catch(error => rej(error));
        });
    },

    download: function (url) {
        return new Promise((res, rej) => {
            HTTP.get(`/${url}`, {
                headers: {
                    Authorization: getAuthorization(),
                    'token-company': getCompanyToken()
                },
                responseType: "arraybuffer"
            })
                .then(response => res(response.data))
                .catch(error => rej(error));
        });
    },
};
