<template>
    <div>

    </div>
</template>

<script>

export default {
    name: "SettingsList",
    components: {},
    data: () => ({}),
    methods: {}
}
</script>
