import baseApi from "./baseApi";

const endpoint = "campaign";

export default {
    get: page => baseApi.get(endpoint, page),
    show: id => baseApi.show(endpoint, id),
    store: data => baseApi.store(endpoint, data),
    shoot: id => baseApi.store(`${endpoint}/shoot/${id}`, {}),
    update: (id, data) => baseApi.update(endpoint, id, data),
    updateStopped: (id, data) => baseApi.update(`${endpoint}/updateStopped`, id, data),
    reactive: (id) => baseApi.update(`${endpoint}/reactive`, id, {}),
    shoots: id => baseApi.get(`${endpoint}/${id}/shoots`, 0),
    emailsShoot: (id, shoots_id) => baseApi.get(`${endpoint}/${id}/shoots/${shoots_id}/emails`, 0),
    detailEmail: (contact_lists_id, email) => baseApi.store(`${endpoint}/detail-email/${contact_lists_id}`, { to: email }),
    destroy: id => baseApi.destroy(endpoint, id),
};
