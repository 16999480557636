<template>
    <v-container fluid d-flex style="height: 90vh">
        <v-layout class="d-flex justify-center align-center">
            <v-flex sm12 md4>
                <v-form ref="form">
                    <v-card class="px-4 py-4">
                        <v-card-title>
                            <v-flex class="d-flex justify-center">
                                <img src="img/logo-sgasoft.png" height="110" />
                            </v-flex>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-subtitle class="text-center">
                            <h3>Recuperar Senha</h3>
                        </v-card-subtitle>
                        <v-card-text class="mb-0 pb-0">
                            <v-text-field ref="email" :rules="[() => !!email || 'O campo é obrigatório']"
                                :error-messages="errorMessages" v-model="email" label="E-mail" required
                                autocomplete="no"></v-text-field>

                            <v-text-field v-if="token" ref="password" :rules="rulesPassword" v-model="password"
                                label="Senha" required autocomplete="no" type="password"
                                :error-messages="passwordErrorMessages" append-icon="mdi-lock"></v-text-field>

                            <v-text-field v-if="token" ref="passwordConfirm" :rules="rulesPasswordConfirm"
                                v-model="passwordConfirm" label="Confirmar Senha" required autocomplete="no"
                                type="password" :error-messages="passwordConfirmErrorMessages"
                                append-icon="mdi-lock"></v-text-field>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-space-between flex-column">
                            <v-btn v-if="!token" color="primary" @click="recuperar" block>
                                Recuperar
                            </v-btn>
                            <v-btn v-if="token" color="primary" @click="alterar" block>
                                Alterar Senha
                            </v-btn>
                            <v-btn class="mt-4" depressed plain small @click="voltar">
                                <v-icon small>mdi-arrow-left-bold</v-icon> &nbsp;
                                Voltar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import api from "@/api/auth";
import { mapMutations } from "vuex";
export default {
    props: {
        source: String
    },
    data: () => ({
        loading: false,
        showError: false,
        errorMessages: '',
        email: "",
        token: "",
        password: "",
        passwordConfirm: "",
        rulesPassword: [
            v => !!v || 'O campo é obrigatório',
        ],
        rulesPasswordConfirm: [
            v => !!v || 'O campo é obrigatório',
        ],
        passwordErrorMessages: '',
        passwordConfirmErrorMessages: '',
    }),
    computed: {
        passwordConfirmationRule() {
            return () => (this.password === this.passwordConfirm) || 'As senhas devem ser iguais';
        },
    },
    created() {
        this.hideLoading();
        this.setDrawer(false);
        this.hideAlert();
        this.token = this.$route.params.token;
        document.title = 'SGAMail - Recuperar Senha';
    },
    methods: {
        ...mapMutations([
            "setIsAdmin",
            "setCompanies",
            "setUser",
            "setDrawer",
            "setCompanySelected",
            "showAlert",
            "hideAlert",
            "showLoading",
            "hideLoading",
        ]),
        async recuperar() {
            this.showLoading();
            try {
                let resp = await api.remember(this.email);
                if (resp.status == 200) {
                    this.hideLoading();
                    this.showAlert({
                        message: 'E-mail enviado com sucesso, verifique sua conta de e-mail.',
                        type: 'success'
                    });
                    this.email = '';

                } else {
                    this.hideLoading();
                    this.showAlert({
                        message: 'Ocorreu um erro ao tentar recuperar a senha, tente novamente.',
                        type: 'error'
                    });

                }
            } catch (error) {
                this.hideLoading();
                this.showAlert({
                    message: 'Ocorreu um erro ao tentar recuperar a senha, tente novamente.',
                    type: 'error'
                });
            }
        },
        async alterar() {
            this.$refs.form.resetValidation();

            const valid = this.$refs.form.validate();
            if (!valid) {
                return;
            }

            this.showLoading();
            if (this.password != this.passwordConfirm) {
                this.passwordErrorMessages = 'As senhas devem ser iguais.';
                this.passwordConfirmErrorMessages = 'As senhas devem ser iguais.';
                this.hideLoading();
                return false;
            }
            try {
                const resp = await api.changePassword(this.email, this.password, this.passwordConfirm, this.token);
                if (resp.status == 200) {
                    this.hideLoading();
                    this.showAlert({
                        message: 'Senha alterada com sucesso',
                        type: 'success'
                    });
                    this.email = '';
                    this.password = '';
                    this.passwordConfirm = '';
                    this.$refs.form.resetValidation();
                } else {
                    this.showAlert({
                        message: 'Ocorreu um erro ao tentar alterar a senha, tente novamente.',
                        type: 'error',
                    });
                }

            } catch (error) {
                this.hideLoading();
                this.showAlert({
                    message: 'Ocorreu um erro ao tentar alterar a senha, tente novamente.',
                    type: 'error',
                });
            }
        },
        voltar() {
            window.location.href = '/login';
        }
    }
};
</script>
