<template>
    <div>
        <div class="elevation-1">
            <v-toolbar flat color="white">
                <v-toolbar-title>
                    <v-icon>mdi-domain</v-icon>
                    Escolher Empresa
                </v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-container fluid>
                <v-row class="mb-10">
                    <v-col v-for="company in companies" :key="company.id">
                        <v-card color="grey lighten-3" class="mx-auto" outline max-width="344" elevation="5">
                            <v-list-item three-line>
                                <v-list-item-content>
                                    <div class="overline mb-2">
                                        Empresa
                                    </div>
                                    <v-list-item-title class="title mb-1">
                                        {{ company.name }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle></v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-avatar size="80">
                                    <v-icon x-large color="blue">mdi-domain</v-icon>
                                </v-list-item-avatar>
                            </v-list-item>
                            <v-card-actions>
                                <v-btn block color="green darken-3 white--text" @click="chooseCompany(company)">
                                    Escolher
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
            </v-container>
        </div>
    </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import api from "@/api/company";

export default {
    components: {},
    data: () => ({}),
    created() {
        this.initialize();
    },
    computed: {
        ...mapState({
            companies: state => state.companies
        })
    },
    methods: {
        ...mapMutations([
            "showLoading",
            "hideLoading",
            "showAlert",
            "setCompanySelected",
            "setCompanyToken",
            "setDrawer"
        ]),
        async initialize() {
            this.setDrawer(false);
            this.showLoading();
            if (this.companies.length == 1) {
                this.chooseCompany(this.companies[0]);
            } else {
                setTimeout(() => this.hideLoading(), 300);
            }

        },
        async chooseCompany(company) {
            const { token } = await api.getToken(company.id);
            if (token != null) {
                this.setCompanySelected(company);
                localStorage.setItem('company-token', token);
                this.$router.push("/dashboard");
            } else {
                this.$router.push("/escolher-empresa");
            }
        }
    }
};
</script>
