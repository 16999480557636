<template>
    <div>
        <div class="elevation-1">
            <v-toolbar flat color="white">
                <v-toolbar-title>
                    <v-icon>mdi-bullhorn-outline</v-icon>
                    Campanha
                </v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-container fluid>
                <div class="elevation-1">
                    <v-tabs v-model="tabActive">
                        <v-tab :key="1">
                            <v-icon left>mdi-play</v-icon>
                            Ativas
                        </v-tab>
                        <v-tab :key="2">
                            <v-icon left>mdi-pause</v-icon>
                            Pausadas
                        </v-tab>
                        <v-tab :key="3">
                            <v-icon left>mdi-bell-off</v-icon>
                            Inativas
                        </v-tab>
                        <v-tab-item>
                            <v-card>
                                <v-card-title>
                                    Campanhas Ativas
                                    <v-spacer></v-spacer>
                                    <v-text-field v-model="searchActive" append-icon="mdi-magnify" label="Procurar"
                                        single-line hide-details></v-text-field>
                                    <v-divider class="mx-4" inset vertical></v-divider>
                                    <v-btn color="green" dark class="mb-2" to="/campanhas/novo">
                                        <v-icon left>
                                            mdi-plus
                                        </v-icon>
                                        Nova Campanha
                                    </v-btn>
                                </v-card-title>
                                <v-data-table :headers="headersActive" :items="prepareData(campaignsActive)"
                                    :search="searchActive" :loading="loadingTable">
                                    <template v-slot:item.stopped="{ item }">
                                        <v-chip :color="getColor(item)" dark>
                                            {{ getTextEnabled(item) }}
                                        </v-chip>
                                    </template>
                                    <template v-slot:item.actions="{ item }">
                                        <v-tooltip bottom v-if="item.manual">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon medium :disabled="getSendingStatus(item) || item.stopped === 1"
                                                    class="mr-2" color="green darken-2" @click="shoot(item)"
                                                    v-bind="attrs" v-on="on">
                                                    mdi-send
                                                </v-icon>
                                            </template>
                                            <span>Disparo Avulso </span>
                                        </v-tooltip>
                                        <v-tooltip bottom v-if="item.stopped === 1">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon medium :disabled="getSendingStatus(item)" class="mr-2"
                                                    color="green" @click="changeStopped(item.id, 0)" v-bind="attrs"
                                                    v-on="on">
                                                    mdi-play
                                                </v-icon>
                                            </template>
                                            <span>Continar Campanha</span>
                                        </v-tooltip>
                                        <v-tooltip bottom v-if="item.stopped === 0">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon medium :disabled="getSendingStatus(item)" class="mr-2"
                                                    color="orange" @click="changeStopped(item.id, 1)" v-bind="attrs"
                                                    v-on="on">
                                                    mdi-pause
                                                </v-icon>
                                            </template>
                                            <span>Pausar</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon medium class="mr-2" color="blue darken-4" @click="report(item)"
                                                    v-bind="attrs" v-on="on">
                                                    mdi-file
                                                </v-icon>
                                            </template>
                                            <span>Relatorio da Campanha</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon medium :disabled="getSendingStatus(item)" class="mr-2"
                                                    color="blue" @click="editItem(item)" v-bind="attrs" v-on="on">
                                                    mdi-pencil
                                                </v-icon>
                                            </template>
                                            <span>Editar</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon :disabled="getSendingStatus(item)" medium
                                                    @click="deleteItem(item)" color="red" v-bind="attrs" v-on="on">
                                                    mdi-delete
                                                </v-icon>
                                            </template>
                                            <span>Deletar</span>
                                        </v-tooltip>
                                    </template>
                                </v-data-table>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item>
                            <v-card>
                                <v-card-title>
                                    Campanhas Pausadas
                                    <v-spacer></v-spacer>
                                    <v-text-field v-model="searchInactive" append-icon="mdi-magnify" label="Procurar"
                                        single-line hide-details></v-text-field>
                                </v-card-title>
                                <v-data-table :headers="headersPaused" :items="campaignsPaused" :search="searchPaused"
                                    :loading="loadingTablePaused">
                                    <template v-slot:item.actions="{ item }">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon medium class="mr-2" color="green" @click="reactiveItem(item)"
                                                    v-bind="attrs" v-on="on">
                                                    mdi-play
                                                </v-icon>
                                            </template>
                                            <span>Reativar</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon medium class="mr-2" color="blue darken-4" @click="report(item)"
                                                    v-bind="attrs" v-on="on">
                                                    mdi-file
                                                </v-icon>
                                            </template>
                                            <span>Relatorio da Campanha</span>
                                        </v-tooltip>
                                    </template>
                                </v-data-table>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item>
                            <v-card>
                                <v-card-title>
                                    Campanhas Inativas
                                    <v-spacer></v-spacer>
                                    <v-text-field v-model="searchInactive" append-icon="mdi-magnify" label="Procurar"
                                        single-line hide-details></v-text-field>
                                </v-card-title>
                                <v-data-table :headers="headersInactive" :items="campaignsInactive"
                                    :search="searchInactive" :loading="loadingTableInactive">
                                    <template v-slot:item.actions="{ item }">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon medium class="mr-2" color="green" @click="reactiveItem(item)"
                                                    v-bind="attrs" v-on="on">
                                                    mdi-play
                                                </v-icon>
                                            </template>
                                            <span>Reativar</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon medium class="mr-2" color="blue darken-4" @click="report(item)"
                                                    v-bind="attrs" v-on="on">
                                                    mdi-file
                                                </v-icon>
                                            </template>
                                            <span>Relatorio da Campanha</span>
                                        </v-tooltip>
                                    </template>
                                </v-data-table>
                            </v-card>
                        </v-tab-item>
                    </v-tabs>
                </div>
            </v-container>
        </div>
        <DialogDelete message="Deseja realmente deletar essa Campanha?" :dialog="dialogDelete"
            :confirmDialog="handleConfirmDelete" :cancelDialog="handleCancelDelete" />
    </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import DialogDelete from "@/components/DialogDelete";
import api from '@/api/campaign';

export default {
    components: {
        DialogDelete
    },
    data: () => ({
        searchActive: '',
        loadingTable: false,
        headersActive: [
            { text: 'ID', align: 'end', value: 'id' },
            { text: 'Titulo', value: 'title' },
            { text: 'Tipo', value: 'type' },
            { text: 'Assunto', value: 'subject' },
            { text: 'Status', value: 'stopped' },
            { text: 'Tipo de Envio', value: 'type_send' },
            { text: 'Próximo Envio', value: 'next_send' },
            { text: "Data de Criação", value: "created_at" },
            { text: "Data de Atualização", value: "updated_at" },
            { text: "Ações", value: "actions", align: 'end', sortable: false, width: 200 }
        ],
        campaignsActive: [],

        headersPaused: [
            { text: 'ID', align: 'end', value: 'id' },
            { text: 'Titulo', value: 'title' },
            { text: 'Tipo', value: 'type' },
            { text: 'Assunto', value: 'subject' },
            { text: "Data de Criação", value: "created_at" },
            { text: "Data de Atualização", value: "updated_at" },
            { text: "Ações", value: "actions", align: 'end', sortable: false, width: 200 }
        ],
        campaignsPaused: [],
        searchPaused: '',
        loadingTablePaused: false,
        headersInactive: [
            { text: 'ID', align: 'end', value: 'id' },
            { text: 'Titulo', value: 'title' },
            { text: 'Tipo', value: 'type' },
            { text: 'Assunto', value: 'subject' },
            { text: "Data de Criação", value: "created_at" },
            { text: "Data de Atualização", value: "updated_at" },
            { text: "Ações", value: "actions", align: 'end', sortable: false, width: 200 }
        ],
        campaignsInactive: [],
        searchInactive: '',
        loadingTableInactive: false,
        dialogDelete: false,
        tabActive: 0,
        _itemDelete: {},
    }),
    created() {
        this.initialize();
    },
    computed: {
        ...mapState({
            companySelected: state => state.companySelected
        })
    },
    methods: {
        ...mapMutations([
            "showLoading",
            "hideLoading",
            "showAlert",
            "setDrawer",
        ]),
        async initialize() {
            this.setDrawer(false);
            this.getData();
        },
        async getData() {
            this.showLoading();
            this.campaignsActive = [];
            this.campaignsInactive = [];
            this.loadingTable = true;
            this.loadingTableInactive = true;
            try {
                let response = await api.get();
                let items = response.data.filter(item => item.company_id === this.companySelected.id);
                this.campaignsActive = items.filter(item => (item.enabled === 1 && item.stopped === 0));
                this.campaignsPaused = items.filter(item => item.stopped === 1);
                this.campaignsInactive = items.filter(item => item.enabled === 0);
            } catch (error) {
                this.campaignsActive = [];
                this.campaignsInactive = [];
                this.showAlert({ message: error.message, type: "error" });
            } finally {
                this.loadingTable = false;
                this.loadingTableInactive = false;
                this.hideLoading();
            }
        },
        getColor(item) {
            if (item.stopped === 1) {
                return 'orange';
            } else if (item.sending !== null && Object.keys(item.sending).length > 0) {
                return 'blue';
            } else {
                return 'green';
            }
            return (stopped === 1 ? 'orange' : 'green');
        },
        getTextEnabled(item) {
            if (item.stopped === 1) {
                return 'Pausada';
            } else if (item.sending !== null && Object.keys(item.sending).length > 0) {
                return 'Enviando';
            } else {
                return 'Ativa';
            }
        },
        prepareData(data) {
            return data.map(item => ({
                ...item,
                manual: item.type_send == 'manual' ? true : false,
                type_send: this.typeSend(item.type_send),
                next_send: item.next_send != null ? this.dateToBr(item.next_send) : '--/--/----',
                created_at: this.dateToBr(item.created_at, false),
                updated_at: this.dateToBr(item.updated_at, false)
            }));
        },
        dateToBr(date, type = true) {
            if (!date) return date;
            let [fullDate, minutes] = date.split(" ");
            if (type) {
                return `${fullDate
                    .split("-")
                    .reverse()
                    .join("/") + ' ' + minutes}`;
            } else {
                return `${fullDate
                    .split("-")
                    .reverse()
                    .join("/")}`;
            }
        },
        typeSend(name) {
            if (name == 'unique')
                return 'Único';
            else if (name == 'recurrence')
                return 'Recorrente';

            return 'Manual';
        },
        editItem(item) {
            this.$router.push(`/campanhas/${item.id}/editar`);
        },
        report(item) {
            this.$router.push(`/campanhas/${item.id}/detalhes`);
        },
        deleteItem(item) {
            this.dialogDelete = true;
            this._itemDelete = item;
        },
        async handleConfirmDelete() {
            this.dialogDelete = false;
            this.showLoading();

            try {
                let res = await api.destroy(this._itemDelete.id);
                this.showAlert({
                    message: "Modelo de Email deletado com sucesso",
                    type: "success",
                });
                this.getData();
            } catch (error) {
                if (error.response.status === 400) {
                    this.showAlert({
                        message: error.response.data.message,
                        type: "error",
                    });
                } else {
                    this.showAlert({
                        message: error.message,
                        type: "error",
                    });
                }
            } finally {
                this._itemDelete = {};
                this.hideLoading();
            }
        },
        handleCancelDelete() {
            this.dialogDelete = false;
            this._itemDelete = {};
        },
        async changeStopped(id, stopped) {
            this.showLoading();
            try {
                let res = await api.updateStopped(id, { stopped: stopped });
                if (res) {
                    this.showAlert({
                        message: "Campanha Atualizada com sucesso",
                        type: "success",
                    });
                    this.getData();
                }
            } catch (error) {
                this.showAlert({ message: error.message, type: "error" });
            } finally {
                this.hideLoading();
            }
        },
        async reactiveItem(item) {
            this.showLoading();
            try {
                let res = await api.reactive(item.id);
                if (res) {
                    this.showAlert({
                        message: "Campanha Reativada com sucesso",
                        type: "success",
                    });
                    this.tabActive = 1;
                    this.getData();
                }
            } catch (error) {
                this.showAlert({ message: error.message, type: "error" });
            } finally {
                this.hideLoading();
            }
        },
        async shoot(item) {
            this.showLoading();
            try {
                let res = await api.shoot(item.id);
                if (res) {
                    this.showAlert({
                        message: "Campanha Disparada com sucesso",
                        type: "success",
                    });
                    //this.getData();
                }
            } catch (error) {
                this.showAlert({ message: error.message, type: "error" });
            } finally {
                this.hideLoading();
            }
        },
        getSendingStatus(item) {
            return (item.sending !== null && Object.keys(item.sending).length > 0) ? true : false;
        }
    }
};
</script>
