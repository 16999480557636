import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import { getCompanyToken } from "../api/config-axios";

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [createPersistedState()],
    state: {
        alert: false,
        messageAlert: '',
        typeAlert: 'error',
        timerAlert: 5,
        loading: false,
        isAdmin: false,
        drawer: false,
        companies: [],
        user: {},
        companySelected: {},
        isSidebarShow: getCompanyToken() != null
    },
    mutations: {
        showAlert(state, { message, type, timer = 5 }) {
            state.messageAlert = message;
            state.typeAlert = type;
            state.timerAlert = timer;
            state.alert = true;
        },
        hideAlert(state) {
            state.alert = false;
            state.messageAlert = '';
            state.typeAlert = 'error';
        },
        showLoading(state) {
            state.loading = true;
        },
        hideLoading(state) {
            state.loading = false;
        },
        setUser(state, user) {
            state.user = user;
        },
        setIsAdmin(state, isAdmin) {
            state.isAdmin = isAdmin;
        },
        setCompanies(state, companies) {
            state.companies = companies;
        },
        setCompanySelected(state, company) {
            state.companySelected = company;
        },
        setCompanyToken(token) {
            state.companyToken = token;
        },
        setDrawer(state, drawer) {
            state.drawer = drawer;
        },
    },
    actions: {
    },
    modules: {
    }
})
