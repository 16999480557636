<template>
  <div>
    <div class="elevation-1">
      <HeaderForm
        title="Vincular Appbox"
        icon="mdi-domain"
        :id="`${company.id}`"
        :name="company.name"
        :onClickBackButton="goToBackList"
      />
      <v-container fluid>
        <v-row>
          <v-col cols="12" xs="12" md="6">
            <v-autocomplete
              :disabled="isUpdating"
              :loading="isLoading"
              :items="hideAppboxesExist(appboxes)"
              v-model="appboxSelected"
              outlined
              dense
              no-data-text="Nenhuma Appbox para vincular"
              :error-messages="errorMessageCompanyAutocomplete"
              color="blue-grey lighten-2"
              label="Selecione uma Appbox"
              item-text="title"
              item-value="id"
            >
              <template v-slot:item="data">
                <template>
                  <v-list-item-avatar>
                    {{ data.item.id }}
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.title">
                    </v-list-item-title>
                    <v-list-item-subtitle
                      v-html="data.item.hostname"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" xs="12" md="2">
            <v-btn color="success" @click="handleAddAppbox">
              <v-icon left>mdi-plus</v-icon>
              Adicionar
            </v-btn>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col>
            <simple-table
              title="Appbox Vinculadas"
              :headers="headers"
              :items="company.appboxes"
              :sortBy="['title']"
            >
              <template v-slot:actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      medium
                      @click="deleteAppbox(item)"
                      color="red"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>Desvincular</span>
                </v-tooltip>
              </template>
            </simple-table>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <DialogDelete
      message="Deseja realmente desvincular essa appbox?"
      :dialog="dialogDelete"
      textBntConfirm="Desvincular"
      :confirmDialog="handleConfirmDelete"
      :cancelDialog="handleCancelDelete"
    />
  </div>
</template>
<script>
import HeaderForm from "@/components/HeaderForm";
import SimpleTable from "@/components/SimpleTable";
import DialogDelete from "@/components/DialogDelete";
import { mapMutations } from "vuex";
import api from "@/api/company";
import apiAppbox from "@/api/appbox";

export default {
  components: {
    HeaderForm,
    SimpleTable,
    DialogDelete,
  },
  data: () => ({
    modelCompany: {
      id: "",
      name: "",
      email: "",
      appboxes: [],
      users: [],
      enabled: 1,
    },
    headers: [
      { text: "ID", value: "id", align: "end", width: 120 },
      { text: "Nome", value: "title", align: "start" },
      { text: "Host", value: "hostname", align: "start" },
      { text: "Encriptação", value: "encryption", align: "end" },
      { text: "Porta", value: "port_number", align: "end" },
      { text: "Usuario", value: "username", align: "start" },
      {
        text: "Ações",
        value: "actions",
        align: "end",
        sortable: false,
        width: 200
      }
    ],
    company: {},
    appboxSelected: null,
    appboxes: [],
    isLoading: true,
    isUpdating: true,
    dialogDelete: false,
    _appboxDelete: {},
    errorMessageCompanyAutocomplete: '',
  }),
  created() {
    this.company = { ...this.modelCompany };
    this.initialize();
  },
  methods: {
    ...mapMutations(["showLoading", "hideLoading", "showAlert"]),
    async initialize() {
      if (this.$route.params.id) {
        await this.getCompany(this.$route.params.id);
        await this.getAppbox();
      } else {
        this.showAlert({ message: 'Acesso incorreto', type: "error" });
        this.$router.push(`/admin/empresas`)
      }
    },
    async getCompany(id) {
      try {
        this.showLoading();
        let response = await api.show(id);
        this.company = response.data;
      } catch (error) {
        this.company = { ...this.modelCompany };
        this.showAlert({ message: error.message, type: "error" });
      } finally {
        this.hideLoading();
      }
    },
    async getAppbox() {
      this.isLoading = true;
      this.isUpdating = true;
      try {
        let response = await apiAppbox.getAll();
        response = response.filter(item => item.enabled === 1).sort((a,b) => a.title.localeCompare(b.title));
        this.appboxes = response;
      } catch (error) {
        this.appboxes = [];
        this.showAlert({ message: error.message, type: "error" });
      } finally {
        this.isLoading = false;
        this.isUpdating = false;
      }
    },
    goToBackList() {
      this.$router.push(`/admin/empresas`);
    },
    async handleAddAppbox() {
      if (this.appboxSelected === null) {
        this.errorMessageCompanyAutocomplete = 'Selecione uma Appbox para poder Adicionar';
        return;
      }
      this.showLoading();
      let appboxesSync = [...this.company.appboxes.map(item => item.id)];
      appboxesSync.push(this.appboxSelected);
      try {
        let response = await api.postAppboxes(this.company.id, {appboxes: appboxesSync});
        this.company.appboxes = response;
        this.appboxSelected = null;
        this.showAlert({ message: 'Appbox Vinculada com sucesso', type: "success" });
      } catch (error) {
        this.showAlert({ message: error.message, type: "error" });
      } finally {
        this.hideLoading();
      }
    },
    hideAppboxesExist(data){
      let idsAppboxes = this.company.appboxes.map(item => item.id);
      return data.filter(item => !idsAppboxes.includes(item.id));
    },
    deleteAppbox(item) {
      this._appboxDelete = item;
      this.dialogDelete = true;
    },
    async handleConfirmDelete() {
      this.dialogDelete = false;
      let appboxesSync = [...this.company.appboxes.map(item => item.id).filter(item => item !== this._appboxDelete.id)];
      this.showLoading();
      try {
        let response = await api.postAppboxes(this.company.id, {appboxes: appboxesSync});
        this.company.appboxes = response;
        this.appboxSelected = null;
        this.showAlert({ message: 'Appbox Desvinculada com sucesso', type: "success" });
      } catch (error) {
        this.showAlert({ message: error.message, type: "error" });
      } finally {
        this.hideLoading();
      }
    },
    handleCancelDelete() {
      this.dialogDelete = false;
      this._appboxDelete = {};
    }
  },
};
</script>
