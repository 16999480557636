import { HTTP, getAuthorization } from "./config-axios";

export default {
    login: async model => {
        let data = JSON.stringify(model);
        let res = await HTTP.post("auth", data);
        if (res.status === 200) {
            localStorage.removeItem('company-token');
            localStorage.setItem('user-token', res.data.token);
            return res.data;
        } else {
            throw new Error(res);
        }
    },
    authUser: async () => {
        let res = await HTTP.get("auth-user", {
            headers: {
                Authorization: getAuthorization(),
            },
        });
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error(res.data.error);
        }
    },
    logout: () => {
        localStorage.removeItem('company-token');
        localStorage.removeItem("user-token");
    },
    loggedIn: function () {
        return (localStorage.getItem("user-token") || null) !== null;
    },
    remember: async email => {
        return await HTTP.post('password/remember', { email });
    },
    changePassword: async (email, password, passwordConfirm, token) => {
        return await HTTP.post('password/reset', { email, password, passwordConfirm, token });
    }
};
