<template>
  <v-toolbar flat color="white">
    <v-toolbar-title>
      <v-icon>{{icon}}</v-icon>
      {{title}}
    </v-toolbar-title>
    <v-divider class="mx-4" inset vertical></v-divider>
    <v-toolbar-title v-if="id">{{id}} - {{ name }}</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn outlined color="grey darken-3" @click="onClickBackButton">
      <v-icon left>mdi-chevron-left</v-icon> Voltar
    </v-btn>
  </v-toolbar>
</template>

<script>
export default {
  name: 'HeaderForm',
  props: {
    title: { type: String, require: true, },
    icon: String,
    id: { type: String, default: "", },
    name: String,
    onClickBackButton: { type: Function, require: true, }
  }
}
</script>