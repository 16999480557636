export  const contactFields = [

  {
    key: "doc",
    name: "CPF/CNPJ",
    description: "Campo do documento de identificação do contato",
    visible: false,
    required: false,
    group: "Identificação",
    type: "Cpf",
    options: "",
    default: ""
  },
  {
    key: "name",
    name: "Nome",
    description: "Nome ou razão social do contato",
    visible: true,
    required: true,
    group: "Identificação",
    type: "String",
    options: "",
    default: ""
  },
  {
    key: "birthdate",
    name: "Nascimento",
    description: "Data de Nascimento",
    visible: false,
    required: false,
    group: "Identificação",
    type: "Date",
    options: "",
    default: ""
  },
  {
    key: "note",
    name: "Observação",
    description: "Observações sobre o contato",
    visible: false,
    required: false,
    group: "Identificação",
    type: "String",
    options: "",
    default: ""
  },
  {
    key: "email",
    name: "Email",
    description: "Email principal",
    visible: true,
    required: true,
    group: "Contato",
    type: "Email",
    options: "",
    default: ""
  },
  {
    key: "second_email",
    name: "Email Secundário",
    description: "Email secundario",
    visible: false,
    required: false,
    group: "Contato",
    type: "Email",
    options: "",
    default: ""
  },
  {
    key: "cell_number",
    name: "Celular",
    description: "Número do celular",
    visible: true,
    required: true,
    group: "Contato",
    type: "Cellphone",
    options: "",
    default: ""
  },
  {
    key: "phone_number",
    name: "Telefone",
    description: "Número do telefone fixo",
    visible: false,
    required: false,
    group: "Contato",
    type: "Telephone",
    options: "",
    default: ""
  },
  {
    key: "zip_code",
    name: "CEP",
    description: "CEP do Contato",
    visible: false,
    required: false,
    group: "Endereço",
    type: "Cep",
    options: "",
    default: ""
  },
  {
    key: "address",
    name: "Endereço",
    description: "Endereço do Contato",
    visible: false,
    required: false,
    group: "Endereço",
    type: "String",
    options: "",
    default: ""
  },
  {
    key: "number",
    name: "Número",
    description: "Número da residência ou empresa",
    visible: false,
    required: false,
    group: "Endereço",
    type: "Number",
    options: "",
    default: ""
  },
  {
    key: "complement",
    name: "Complemento",
    description: "Complemento ex: Apto 10",
    visible: false,
    required: false,
    group: "Endereço",
    type: "String",
    options: "",
    default: ""
  },
  {
    key: "neighborhood",
    name: "Bairro",
    description: "Nome do Bairro",
    visible: false,
    required: false,
    group: "Endereço",
    type: "String",
    options: "",
    default: ""
  },
  {
    key: "city",
    name: "Cidade",
    description: "Nome da Cidade",
    visible: false,
    required: false,
    group: "Endereço",
    type: "String",
    options: "",
    default: ""
  },
  {
    key: "state",
    name: "Estado",
    description: "Nome do Estado",
    visible: false,
    required: false,
    group: "Endereço",
    type: "String",
    options: "",
    default: ""
  },
  {
    key: "country",
    name: "País",
    description: "Nome do País",
    visible: false,
    required: false,
    group: "Endereço",
    type: "String",
    options: "",
    default: ""
  }
];