import baseApi from "./baseApi";

const endpoint = "job";

export default {
    get: page => baseApi.get(endpoint, page),
    retry: id => baseApi.update(endpoint + `/retry`, id, {}),
    retryAll: id => baseApi.update(endpoint + `/retry/all`, id, {}),
    delete: id => baseApi.destroy(endpoint, id),
    deleteAll: id => baseApi.destroy(endpoint + '/delete/all', id)
};
