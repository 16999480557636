<template>
    <div>
        <div class="elevation-1">
            <HeaderForm title="API Token" icon="mdi-account-multiple-outline" :id="`${apiToken.id}`"
                :name="apiToken.name" :onClickBackButton="goToBackList" />
            <v-form ref="form" v-model="valid" :lazy-validation="lazy">
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" xs="12" md="12">
                            <v-text-field v-model="apiToken.name" label="Nome" outlined required
                                :rules="[rules.required]"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row v-if="apiToken.api_token">
                        <v-col cols="12" xs="12" md="10">
                            <v-text-field v-model="apiToken.api_token" label="Token" outlined filled></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" md="1">
                            <v-btn color="primary" class="mt-1" large @click.prevent="updateToken">
                                <v-icon class="mr-1" small>mdi-reload</v-icon>
                                Atualizar Token
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-switch v-model="apiToken.enabled" label="Ativo"></v-switch>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn color="blue darken-3" dark class="mt-5" @click="validate">
                                <v-icon left>mdi-send</v-icon> Salvar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </div>
    </div>
</template>
<script>
import HeaderForm from '@/components/HeaderForm';
import { mapMutations } from 'vuex';
import api from '@/api/apiToken';
export default {
    components: {
        HeaderForm
    },
    data: () => ({
        modelApiToken: {
            id: "",
            name: "",
            api_token: "",
            enabled: true,
        },
        apiToken: {},
        valid: true,
        lazy: false,
        rules: {
            required: value => !!value || "Campo Obrigatorio"
        }
    }),
    created() {
        this.apiToken = { ...this.modelApiToken };
        this.initialize();
    },
    methods: {
        ...mapMutations([
            'showLoading',
            'hideLoading',
            'showAlert',
        ]),
        async initialize() {
            if (this.$route.params.id) {
                this.getApiToken(this.$route.params.id);
            }
        },
        async getApiToken(id) {
            this.showLoading();
            try {
                let response = await api.show(id);
                this.apiToken = response.data;
            } catch (error) {
                this.apiToken = { ...this.modelApiToken };
                this.showAlert({ message: error.message, type: 'error' });
            } finally {
                this.hideLoading();
            }
        },

        async updateToken() {
            this.showLoading();
            const id = this.apiToken.id;
            try {
                let response = await api.updateToken(id);
                this.apiToken.api_token = response.data.token;
                this.showAlert({
                    message: response.message,
                    type: 'success'
                });
            } catch (error) {
                this.apiToken = { ...this.modelApiToken };
                this.showAlert({ message: error.message, type: 'error' });
            } finally {
                this.hideLoading();
            }
        },
        goToBackList() {
            this.$router.push(`/admin/api-token`);
        },
        validate() {
            if (this.$refs.form.validate()) {
                let data = { ...this.apiToken };
                if (this.apiToken.id === "") {
                    this.post(data);
                } else {
                    this.update(this.apiToken.id, data);
                }
            }
        },
        async post(data) {
            this.showLoading();
            try {
                let res = await api.store(data);
                if (res) {
                    this.showAlert({ message: 'Token salvo com sucesso', type: 'success' });
                    this.goToBackList();
                }
            } catch (error) {
                this.showAlert({ message: error.message, type: 'error' });
            } finally {
                this.hideLoading();
            }
        },
        async update(id, data) {
            this.showLoading();
            try {
                let res = await api.update(id, data);
                if (res) {
                    this.showAlert({ message: 'Token atualizado com sucesso', type: 'success' });
                    this.goToBackList();
                }
            } catch (error) {
                this.showAlert({ message: error.message, type: 'error' });
            } finally {
                this.hideLoading();
            }
        }
    }
};
</script>
