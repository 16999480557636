<template>
  <div>
    <Table title="Appbox" icon="mdi-database-cog-outline" textNewButton="Nova Appbox" linkNewButton="/admin/appbox/novo"
      :headers="headers" :items="prepareData(filterData(items))" :onSelectedShowDisabled="selectedShowDisabled"
      :refreshTable="onClickRefreshTable" :sortBy="['id']" :showDisabled="true" :loading="loadingTable">
      <template v-slot:actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon medium class="mr-2" color="teal darken-2" @click="companySync(item)" v-bind="attrs" v-on="on">
              mdi-domain
            </v-icon>
          </template>
          <span>Empresas</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon medium class="mr-2" color="blue" @click="editItem(item)" v-bind="attrs" v-on="on">
              mdi-pencil
            </v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon medium @click="deleteItem(item)" color="red" v-bind="attrs" v-on="on">
              mdi-delete
            </v-icon>
          </template>
          <span>Deletar</span>
        </v-tooltip>
      </template>
    </Table>
    <DialogDelete message="Deseja realmente deletar essa Appbox?" :dialog="dialogDelete"
      :confirmDialog="handleConfirmDelete" :cancelDialog="handleCancelDelete" />
  </div>
</template>

<script>
import Table from "@/components/Table";
import DialogDelete from "@/components/DialogDelete";
import api from "@/api/appbox";
import { mapMutations } from "vuex";
export default {
  name: "AppboxList",
  components: {
    Table,
    DialogDelete,
  },
  data: () => ({
    showDisabled: false,
    loadingTable: false,
    headers: [
      { text: "ID", value: "id", align: "end", width: 120 },
      { text: "Nome", value: "title", align: "start" },
      { text: "Host", value: "hostname", align: "start" },
      { text: "Encriptação", value: "encryption", align: "end" },
      { text: "Porta", value: "port_number", align: "end" },
      { text: "Usuario", value: "username", align: "start" },
      { text: "Data de Criação", value: "created_at" },
      { text: "Data de Atualização", value: "updated_at" },
      { text: "Status", value: "enabled", width: 100 },
      {
        text: "Ações",
        value: "actions",
        align: "end",
        sortable: false,
        width: 200,
      },
    ],
    items: [],
    dialogDelete: false,
    _itemDelete: {},
  }),
  created() {
    this.initialize();
  },
  methods: {
    ...mapMutations(["showLoading", "setDrawer", "hideLoading", "showAlert"]),
    initialize() {
      this.setDrawer(false);
      this.getData();
    },
    async getData() {
      this.loadingTable = true;
      this.showLoading();
      this.items = [];
      try {
        let response = await api.get();
        this.items = response.data;
      } catch (error) {
        this.items = [];
        this.showAlert({ message: error.message, type: "error" });
      } finally {
        this.loadingTable = false;
        this.hideLoading();
      }
    },
    onClickRefreshTable() {
      this.getData();
    },
    filterData(data) {
      if (!this.showDisabled) {
        return data.filter((item) => item.enabled === 1);
      } else {
        return data.filter((item) => item.enabled === 0);
      }
    },
    selectedShowDisabled() {
      this.showDisabled = !this.showDisabled;
    },
    prepareData(data) {
      return data.map((item) => ({
        ...item,
        created_at: this.dateToBr(item.created_at),
        updated_at: this.dateToBr(item.updated_at),
      }));
    },
    dateToBr(date) {
      if (!date) return date;
      let [fullDate, minutes] = date.split(" ");
      return `${fullDate.split("-").reverse().join("/")} ${minutes}`;
    },
    editItem(item) {
      this.$router.push(`/admin/appbox/${item.id}/editar`);
    },
    companySync(item) {
      this.$router.push(`/admin/appbox/${item.id}/empresas`);
    },
    deleteItem(item) {
      this.dialogDelete = true;
      this._itemDelete = item;
    },
    async handleConfirmDelete() {
      this.dialogDelete = false;
      this.showLoading();

      try {
        let res = await api.destroy(this._itemDelete.id);
        this.showAlert({
          message: "Appbox deletada com sucesso",
          type: "success",
        });
        this.getData();
      } catch (error) {
        if (error.response.status === 400) {
          this.showAlert({
            message: error.response.data.message,
            type: "error",
          });
        } else {
          this.showAlert({
            message: error.message,
            type: "error",
          });
        }
      } finally {
        this._itemDelete = {};
        this.hideLoading();
      }
    },
    handleCancelDelete() {
      this.dialogDelete = false;
      this._itemDelete = {};
    }
  },
};
</script>

<style>

</style>
