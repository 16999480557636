<template>
    <div>
        <div class="elevation-1">
            <v-toolbar flat color="white">
                <v-toolbar-title>
                    <v-icon>mdi-bullhorn-outline</v-icon>
                    Relatório do Contato da lista: {{ contactList.id }} - {{ contactList.name }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn outlined color="grey darken-3" @click="goToBackList">
                    <v-icon left>mdi-chevron-left</v-icon> Voltar
                </v-btn>
            </v-toolbar>
            <v-container fluid>
                <div class="elevation-1">
                    <template>
                        <v-card class="mx-auto mb-6" color="green lighten-5">
                            <v-card-title class="grey--text text--darken-4">
                                <v-avatar color="indigo" class="mr-5">
                                    <v-icon dark>
                                        mdi-account-circle
                                    </v-icon>
                                </v-avatar>
                                {{ contact.name }}
                            </v-card-title>
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <label for=""
                                            class="indigo--text text--darken-5 font-weight-black text-subtitle-2">Nome</label><br>
                                        <p class="font-weight-bold grey--text text--darken-4 text-subtitle-1">
                                            {{ contact.name }}</p>
                                    </v-col>
                                    <v-col>
                                        <label for=""
                                            class="indigo--text text--darken-5 font-weight-black text-subtitle-2">Email</label><br>
                                        <p class="font-weight-bold grey--text text--darken-4 text-subtitle-1">
                                            {{ contact.email }}</p>
                                    </v-col>
                                    <v-col>
                                        <label for=""
                                            class="indigo--text text--darken-5 font-weight-black text-subtitle-2">Telefone</label><br>
                                        <p class="font-weight-bold grey--text text--darken-4 text-subtitle-1">
                                            {{ contact.cell_number }}</p>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </template>
                </div>
                <div class="elevation-1">
                    <v-card>
                        <v-card-title>
                            Registro de Envios
                            <v-spacer></v-spacer>
                            <v-text-field v-model="searchActive" append-icon="mdi-magnify" label="Procurar" single-line
                                hide-details></v-text-field>
                            <v-divider class="mx-4" inset vertical></v-divider>
                        </v-card-title>
                        <v-data-table :headers="headersContact" :items="prepareData(shoots)" :search="searchActive"
                            sort-by="id">
                        </v-data-table>
                    </v-card>
                </div>
            </v-container>
        </div>
        <DialogDelete message="Deseja realmente deletar essa Camapnha?" :dialog="dialogDelete"
            :confirmDialog="handleConfirmDelete" :cancelDialog="handleCancelDelete" />
    </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import DialogDelete from "@/components/DialogDelete";
import api from '@/api/campaign';
import apiContactList from '@/api/contact-list';
import apiContact from '@/api/contact';

export default {
    components: {
        DialogDelete
    },
    data: () => ({
        shoot_selected: {},
        contact_list_id: '',
        contact: '',
        keyTabReport: 0,
        searchActive: '',
        headersContact: [
            { text: 'ID', align: 'end', value: 'id' },
            { text: 'Campanha', value: 'campaign.title' },
            { text: 'Disparo', value: 'shoots.sequentialCode' },
            { text: 'Data Envio', value: 'date_send' },
            { text: 'Lido', value: 'readed_format' },
            { text: 'Data Leitura', value: 'date_readed_format' },
            { text: 'Desinscreveu?', value: 'unsubscribe_format' },
            { text: "Data Desinscrito", value: "date_unsubscribe_format" },
            { text: 'Erro', value: 'error' },
        ],
        shoots: [],
        contactList: {
            id: '',
            name: '',
        },
        dialogDelete: false,
        _itemDelete: {},
    }),
    created() {
        this.contact_list_id = this.$route.params.id;
        this.contact_id = this.$route.params.contato;
        this.initialize();
    },
    computed: {
        ...mapState({
            companySelected: state => state.companySelected
        })
    },
    methods: {
        ...mapMutations([
            "showLoading",
            "hideLoading",
            "showAlert",
            "setDrawer",
        ]),
        async initialize() {
            const { id, contato } = this.$route.params;

            this.setDrawer(false);
            this.getContactList(id);
            this.getContact(contato);
        },
        async detailEmail(contact_lists_id, email) {
            this.showLoading();
            try {
                let response = await api.detailEmail(contact_lists_id, email);
                this.shoots = response;
            } catch (error) {
                this.campaign = { ...this.modelCampaign };
                this.showAlert({
                    message: 'Ocorreu um erro ao buscar os dados, tente novamente.',
                    type: "error"
                });
            } finally {
                this.hideLoading();
            }
        },
        async getShoots(id) {
            this.showLoading();
            try {
                let response = await api.shoots(id);
                this.shoots = response;
            } catch (error) {
                this.showAlert({
                    message: 'Ocorreu um erro ao buscar os dados, tente novamente.',
                    type: "error"
                });
            } finally {
                this.hideLoading();
            }
        },
        async getEmailsShoot(id, shoots_id) {
            this.showLoading();
            try {
                let response = await api.emailsShoot(id, shoots_id);
                this.emails = response;
            } catch (error) {
                console.log(error);
                this.showAlert({
                    message: 'Ocorreu um erro ao buscar os dados, tente novamente.',
                    type: "error"
                });
            } finally {
                this.hideLoading();
            }
        },
        async getContactList(id) {
            if (id == '' || id == undefined) {
                return;
            }
            try {
                let response = await apiContactList.show(id);
                this.contactList = response.data;
            } catch (error) {
                this.showAlert({
                    message: 'Ocorreu um erro ao buscar os dados, tente novamente.',
                    type: "error"
                });
            }
        },
        async getContact(id) {
            if (id == '' || id == undefined) {
                return;
            }
            try {
                let response = await apiContact.show(id);
                this.contact = response.data;

                this.detailEmail(id, this.contact.email);
            } catch (error) {
                this.showAlert({
                    message: 'Ocorreu um erro ao buscar os dados, tente novamente.',
                    type: "error"
                });
            }
        },
        getColor(stopped) {
            return (stopped === 1 ? 'orange' : 'green');
        },
        getTextEnabled(stopped) {
            return (stopped === 1 ? 'Pausada' : 'Ativa');
        },
        prepareData(data) {
            console.log(data);
            return data.map(item => ({
                ...item,
                date_send: this.dateToBr(item.created_at),
                date_readed_format: this.dateToBr(item.date_readed),
                unsubscribe_format: item.unsubscribe === 1 ? 'Sim' : 'Não',
                readed_format: item.readed === 1 ? 'Sim' : 'Não',
                date_unsubscribe_format: this.dateToBr(item.date_unsubscribe),
                error: item.error != '' && item.error != null ? 'Sim' : 'Não'
            }));
        },
        dateToBr(date) {
            if (!date) return date;
            let [fullDate, minutes] = date.split(" ");
            return `${fullDate
                .split("-")
                .reverse()
                .join("/")} ${minutes}`;
        },
        dateToBrTDate(date) {
            if (!date) return date;
            let [fullDate, minutes] = date.split("T");
            minutes = minutes.split('.')[0];
            return `${fullDate
                .split("-")
                .reverse()
                .join("/")} ${minutes}`;
        },
        editItem(item) {
            this.$router.push(`/campanhas/${item.id}/editar`);
        },
        deleteItem(item) {
            this.dialogDelete = true;
            this._itemDelete = item;
        },
        async handleConfirmDelete() {
            this.dialogDelete = false;
            this.showLoading();

            try {
                let res = await api.destroy(this._itemDelete.id);
                this.showAlert({
                    message: "Modelo de Email deletado com sucesso",
                    type: "success",
                });
                this.getData();
            } catch (error) {
                if (error.response.status === 400) {
                    this.showAlert({
                        message: error.response.data.message,
                        type: "error",
                    });
                } else {
                    this.showAlert({
                        message: error.message,
                        type: "error",
                    });
                }
            } finally {
                this._itemDelete = {};
                this.hideLoading();
            }
        },
        handleCancelDelete() {
            this.dialogDelete = false;
            this._itemDelete = {};
        },
        async changeStopped(id, stopped) {
            this.showLoading();
            try {
                let res = await api.updateStopped(id, { stopped: stopped });
                if (res) {
                    this.showAlert({
                        message: "Campanha Atualizada com sucesso",
                        type: "success",
                    });
                    this.getData();
                }
            } catch (error) {
                this.showAlert({ message: error.message, type: "error" });
            } finally {
                this.hideLoading();
            }
        },
        goToBackList() {
            this.$router.push(`/lista-contatos/${this.contact.contact_list_id}/contatos`);
        },
        detail(item) {
            this.shoot_selected = { ...item };
            this.keyTabReport = 1;
            this.getEmailsShoot(this.campaign.id, item.id);
        }
    }
};
</script>
