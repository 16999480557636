<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="550"
    >
      <v-card>
        <v-card-title class="headline red lighten-3">
          
        </v-card-title>
        <v-card-text class="mt-10 mb-5 headline text-center">{{message}}</v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            outlined
            @click="confirmDialog"
          >
            <v-icon left>
              mdi-delete
            </v-icon>
            {{textBntConfirm}}
          </v-btn>
          <v-btn
            color="gray darken-1"
            outlined
            @click="cancelDialog"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
  export default {
    name: 'DialogDelete',
    props: {
      message: String,
      dialog: Boolean,
      confirmDialog: Function,
      textBntConfirm: {
        type: String,
        default: 'Deletar'
      },
      cancelDialog: Function
    },
    data: function () {
      return {
        localDialog: this.dialog,
      }
    },
  }
</script>