import Vue from 'vue'
import VueRouter from 'vue-router'

import adminRoutes from './admin';

// Pages of not Admin
import Dashboard from '@/views/Dashboard';
import LayoutEmail from '@/views/layout-email/LayoutEmail';
import LayoutEmailForm from '@/views/layout-email/LayoutEmailForm';

import LayoutSms from '@/views/layout-sms/LayoutSms';
import LayoutSmsForm from '@/views/layout-sms/LayoutSmsForm';

import LayoutWhatsapp from '../views/layout-whatsapp/LayoutWhatsapp.vue';
import LayoutWhatsappForm from '../views/layout-whatsapp/LayoutWhatsappForm.vue';

import User from '@/views/user/User';
import UserForm from '@/views/user/UserForm';

import ChooseCompany from '@/views/ChooseCompany';

import ContactList from '@/views/contact-list/ContactList';
import ContactListWizard from '@/views/contact-list/ContactListWizard';

import Contacts from '@/views/contact/Contacts';
import ContactsReport from '@/views/contact/ContactsReport';

import Campaign from '@/views/campaign/Campaign';
import CampaignWizard from '@/views/campaign/CampaignWizard';
import CampaignReport from '@/views/campaign/CampaignReport';

import Unsubscribe from '@/views/Unsubscribe';
import Transactions from '@/views/transaction/Transactions';

import CustomFieldsList from '@/views/custom-fields/CustomFields.vue';

import SettingsList from '@/views/settings/Settings';



import Login from '@/views/auth/Login.vue';
import RememberPassword from '@/views/auth/RememberPassword';

Vue.use(VueRouter)

const routes = [
    ...adminRoutes,
    {
        path: '/',
        redirect: '/dashboard',
        meta: {
            title: 'Dashboard'
        }
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
            title: 'Dashboard'
        }
    },
    {
        path: '/escolher-empresa',
        name: 'ChooseCompany',
        component: ChooseCompany,
        meta: {
            title: 'Escolher Empresa'
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            title: 'Login'
        }
    },
    {
        path: '/recuperar-senha',
        name: 'RememberPassword',
        component: RememberPassword,
        meta: {
            title: 'Recuperação de Senha'
        }
    },
    {
        path: '/recuperar-senha/:token',
        name: 'RememberPasswordReset',
        component: RememberPassword,
        meta: {
            title: 'Recuperação de Senha'
        }
    },
    {
        path: '/lista-contatos',
        name: 'ContactList',
        component: ContactList,
        meta: {
            title: 'Lista de Contatos'
        }
    },
    {
        path: '/lista-contatos/novo',
        name: 'ContactListWizard',
        component: ContactListWizard,
        meta: {
            title: 'Cadastro de Lista de Contato'
        }
    },
    {
        path: '/lista-contatos/:id/editar',
        name: 'ContactListWizardEditar',
        component: ContactListWizard,
        meta: {
            title: 'Edição de Lista de Contato'
        }
    },
    {
        path: '/lista-contatos/:id/contatos',
        name: 'Contacts',
        component: Contacts,
        meta: {
            title: 'Contatos'
        }
    },
    {
        path: '/lista-contatos/:id/contatos/:contato/detalhes',
        name: 'ContactsReport',
        component: ContactsReport,
        props: true,
        meta: {
            title: 'Detalhes do Contato',
        }
    },
    {
        path: '/modelo-email',
        name: 'LayoutEmailList',
        component: LayoutEmail,
        meta: {
            title: 'Modelos de E-mail'
        }
    },
    {
        path: '/modelo-email/novo',
        name: 'LayoutEmailForm',
        component: LayoutEmailForm,
        meta: {
            title: 'Cadastro de Modelo de E-mail'
        }
    },
    {
        path: '/modelo-email/:id/editar',
        name: 'LayoutEmailEdit',
        component: LayoutEmailForm,
        meta: {
            title: 'Edição de Modelo de E-mail'
        }
    },
    {
        path: '/modelo-sms',
        name: 'LayoutSmsList',
        component: LayoutSms,
        meta: {
            title: 'Modelos de SMS'
        }
    },
    {
        path: '/modelo-sms/novo',
        name: 'LayoutSmsForm',
        component: LayoutSmsForm,
        meta: {
            title: 'Cadastro de Modelo de SMS'
        }
    },
    {
        path: '/modelo-sms/:id/editar',
        name: 'LayoutSmsEdit',
        component: LayoutSmsForm,
        meta: {
            title: 'Edição de Modelo de SMS'
        }
    },
    {
      path: '/modelo-whatsapp',
      name: 'LayoutWhatsappList',
      component: LayoutWhatsapp,
      meta: {
        title: 'Modelos de Whatsapp - Templates'
      }
    },
    {
      path: '/modelo-whatsapp/novo',
      name: 'LayoutWhatsappForm',
      component: LayoutWhatsappForm,
      meta: {
          title: 'Cadastro de Modelo de Whatsapp'
      }
  },
  {
      path: '/modelo-whatsapp/:id/editar',
      name: 'LayoutWhatsappEdit',
      component: LayoutWhatsappForm,
      meta: {
          title: 'Edição de Modelo de Whatsapp'
      }
  },
    {
        path: '/usuarios',
        name: 'UserList',
        component: User,
        meta: {
            title: 'Usuários'
        }
    },
    {
        path: '/usuarios/novo',
        name: 'UserForm',
        component: UserForm,
        meta: {
            title: 'Cadastro de Usuário'
        }
    },
    {
        path: '/usuarios/:id/editar',
        name: 'UserEdit',
        component: UserForm,
        meta: {
            title: 'Edição de Usuário'
        }
    },
    {
        path: '/campanhas',
        name: 'Campaign',
        component: Campaign,
        meta: {
            title: 'Campanhas'
        }
    },
    {
        path: '/campanhas/novo',
        name: 'CampaignWizard',
        component: CampaignWizard,
        meta: {
            title: 'Cadastro de Campanha'
        }
    },
    {
        path: '/campanhas/:id/editar',
        name: 'CampaignWizardEdit',
        component: CampaignWizard,
        meta: {
            title: 'Edição de Campanha'
        }
    },
    {
        path: '/campanhas/:id/detalhes',
        name: 'CampaignReport',
        component: CampaignReport,
        meta: {
            title: 'Detalhes da Campanha'
        }
    },
    {
        path: '/unsubscribe/:token',
        name: 'Unsubscribe',
        component: Unsubscribe,
        mega: {
            title: 'Desinscrever-se'
        }
    },
    {
        path: '/log-disparos',
        name: 'TransactionList',
        component: Transactions,
        meta: {
            title: 'Log de Disparos'
        }
    },
    {
        path: '/campos-adicionais',
        name: 'CustomFieldList',
        component: CustomFieldsList,
        meta: {
            title: 'Campos Adicionais'
        }
    },
    {
        path: '/configuracoes',
        name: 'SettingsList',
        component: SettingsList,
        meta: {
            title: 'Configurações'
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
