<template>
    <div>
        <Table title="Empresa" icon="mdi-domain" textNewButton="Nova Empresa" linkNewButton="/admin/empresas/novo"
            :headers="headers" :items="prepareData(filterData(items))" :onSelectedShowDisabled="selectedShowDisabled"
            :refreshTable="onClickRefreshTable" :sortBy="['id']" :showDisabled="true" :loading="loadingTable">

            <template v-slot:actions="{ item }">
                <div class="text-center">
                    <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                            <v-btn color="primary" dark small fab title="Ações">
                                <v-icon small color="white" v-on="on">mdi-dots-vertical</v-icon>
                            </v-btn>
                        </template>
                        <v-list dense>
                            <v-list-item :key="item.id + 'showUsers'" link @click="showUsers(item)" title="Usuários">
                                <v-list-item-title dense>
                                    <v-icon small class="mr-2" color="teal darken-2">
                                        mdi-account-multiple-outline
                                    </v-icon>
                                    Usuários
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item :key="item.id + 'showAppbox'" link @click="showAppbox(item)"
                                title="Appbox E-mail">
                                <v-list-item-title dense>
                                    <v-icon small class="mr-2" color="teal darken-2">
                                        mdi-database-cog-outline
                                    </v-icon>
                                    Appbox E-mail
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item :key="item.id + 'showAppboxSms'" link @click="showAppboxSms(item)"
                                title="Appbox SMS">
                                <v-list-item-title dense>
                                    <v-icon small class="mr-2" color="teal darken-2">
                                        mdi-database-cog-outline
                                    </v-icon>
                                    Appbox SMS
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item :key="item.id + 'showProducts'" link @click="showProducts(item)"
                                title="Produtos">
                                <v-list-item-title dense>
                                    <v-icon small class="mr-2" color="teal darken-2">
                                        mdi-database-cog-outline
                                    </v-icon>
                                    Produto
                                </v-list-item-title>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item :key="item.id + 'edit'" link @click="editItem(item)" title="Editar">
                                <v-list-item-title dense>
                                    <v-icon small class="mr-2" color="blue">
                                        mdi-pencil
                                    </v-icon>
                                    Editar
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item :key="item.id + 'deleteItem'" link @click="deleteItem(item)" title="Deletar">
                                <v-list-item-title dense>
                                    <v-icon small class="mr-2" color="red">
                                        mdi-delete
                                    </v-icon>
                                    Deletar
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </template>
        </Table>
        <DialogDelete message="Deseja realmente deletar essa Empresa?" :dialog="dialogDelete"
            :confirmDialog="handleConfirmDelete" :cancelDialog="handleCancelDelete" />
    </div>
</template>

<script>
import Table from "@/components/Table";
import DialogDelete from "@/components/DialogDelete";
import api from "@/api/company";
import { mapMutations } from "vuex";
export default {
    name: "CompanyList",
    components: {
        Table,
        DialogDelete,
    },
    data: () => ({
        showDisabled: false,
        loadingTable: false,
        headers: [
            { text: "ID", value: "id", align: "end", width: 120 },
            { text: "Nome", value: "name", align: "start" },
            { text: "Email", value: "email", align: "start" },
            { text: "Data de Criação", value: "created_at" },
            { text: "Data de Atualização", value: "updated_at" },
            { text: "Status", value: "enabled", width: 100 },
            {
                text: "Ações",
                value: "actions",
                align: "center",
                sortable: false,
                width: 200
            }
        ],
        items: [],
        dialogDelete: false,
        _itemDelete: {},
    }),
    created() {
        this.initialize();
    },
    methods: {
        ...mapMutations(["showLoading", "setDrawer", "hideLoading", "showAlert"]),
        initialize() {
            this.setDrawer(false);
            this.getData();
        },
        async getData() {
            this.showLoading();
            this.loadingTable = true;
            this.items = [];
            try {
                let response = await api.getAll();
                this.items = response;
            } catch (error) {
                this.items = [];
                this.showAlert({ message: error.message, type: "error" });
            } finally {
                this.loadingTable = false;
                this.hideLoading();
            }
        },
        onClickRefreshTable() {
            this.getData();
        },
        filterData(data) {
            if (!this.showDisabled) {
                return data.filter(item => item.enabled === 1);
            } else {
                return data.filter(item => item.enabled === 0);
            }
        },
        selectedShowDisabled() {
            this.showDisabled = !this.showDisabled;
        },
        prepareData(data) {
            return data.map(item => ({
                ...item,
                created_at: this.dateToBr(item.created_at),
                updated_at: this.dateToBr(item.updated_at)
            }));
        },
        dateToBr(date) {
            if (!date) return date;
            let [fullDate, minutes] = date.split(" ");
            return `${fullDate
                .split("-")
                .reverse()
                .join("/")} ${minutes}`;
        },
        editItem(item) {
            this.$router.push(`/admin/empresas/${item.id}/editar`);
        },
        showUsers(item) {
            this.$router.push(`/admin/empresas/${item.id}/usuarios`);
        },
        showAppbox(item) {
            this.$router.push(`/admin/empresas/${item.id}/appbox`);
        },
        showAppboxSms(item) {
            this.$router.push(`/admin/empresas/${item.id}/appbox-sms`);
        },
        showProducts(item) {
            this.$router.push(`/admin/empresas/${item.id}/produtos`);
        },
        deleteItem(item) {
            this.dialogDelete = true;
            this._itemDelete = { ...item };
        },
        async handleConfirmDelete() {
            this.dialogDelete = false;
            this.showLoading();
            try {
                let response = await api.destroy(this._itemDelete.id);
                this.showAlert({
                    message: "Empresa deletada com sucesso",
                    type: "success",
                });
                this.getData();
            } catch (error) {
                if (error.response.status === 400) {
                    this.showAlert({
                        message: error.response.data.message,
                        type: "error",
                    });
                } else {
                    this.showAlert({
                        message: error.message,
                        type: "error",
                    });
                }
            } finally {
                this._itemDelete = {};
                this.hideLoading();
            }
        },
        handleCancelDelete() {
            this.dialogDelete = false;
            this._itemDelete = {};
        }
    }
};
</script>

<style>

</style>
