<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h2>Dashboard</h2>
                <hr />
            </div>
        </div>
        <div class="row">
            <div class="col">
                <v-card elevation="2">
                    <v-card-title>{{ countCompanies }}</v-card-title>
                    <v-card-subtitle>Empresas Ativas</v-card-subtitle>
                </v-card>
            </div>
            <div class="col">
                <v-card elevation="2">
                    <v-card-title>{{ countAppboxes }}</v-card-title>
                    <v-card-subtitle>App Boxes Ativos</v-card-subtitle>
                </v-card>
            </div>
            <div class="col">
                <v-card elevation="2">
                    <v-card-title>{{ countProducts }}</v-card-title>
                    <v-card-subtitle>Produtos Ativos</v-card-subtitle>
                </v-card>
            </div>
            <div class="col">
                <v-card elevation="2">
                    <v-card-title>{{ countUsers }}</v-card-title>
                    <v-card-subtitle>Usuários Cadastrados</v-card-subtitle>
                </v-card>
            </div>
            <div class="col">
                <v-card elevation="2" dark color="red">
                    <v-card-title>{{ failedJobs }}</v-card-title>
                    <v-card-subtitle>Falhas no Envio</v-card-subtitle>
                </v-card>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations } from "vuex";
import api from '@/api/dashboard';

export default {
    name: 'DashboardAdmin',
    created() {
        this.initialize();
    },
    methods: {
        ...mapMutations([
            "setDrawer",
            "showLoading",
            "hideLoading"
        ]),
        initialize() {
            this.setDrawer(false);
            this.getDashboard();
        },
        async getDashboard() {
            try {
                this.showLoading();
                const response = await api.dashboard();
                const {
                    countAppBoxes,
                    countCompanies,
                    countProducts,
                    countUsers,
                    failedJobs
                } = response.data;

                this.countCompanies = countCompanies ?? 0;
                this.countProducts = countProducts ?? 0;
                this.countAppboxes = countAppBoxes ?? 0;
                this.countUsers = countUsers ?? 0;
                this.failedJobs = failedJobs ?? 0;

                this.hideLoading();
            } catch (error) {
                this.hideLoading();
            }
        }
    },
    data: () => ({
        countCompanies: 0,
        countProducts: 0,
        countAppboxes: 0,
        countUsers: 0,
        failedJobs: 0,
    })
}
</script>
