<template>
  <v-text-field
    :label="label"
    dense
    v-mask="'##/##/####'"
    outlined
    :rules="rules"
    :value="value"
    @input="handleInput"
  ></v-text-field>
</template>
<script>
export default {
  props: {
    label: String,
    value: String,
    rules: {
      type: Array,
      required: false,
      default: function () {
        return []
      },
    },
    handleInput: Function,
  },
  data: () => ({}),
}
</script>