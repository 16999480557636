<template>
    <div>
        <v-row>
            <v-col cols="12" xs="6" md="2">
                <v-select :items="fields_replace" dense class="seelctFieldsReplace" item-text="text" item-value="value"
                    label="Campo para substituição" @change="insertDataEditor" v-model="field_replace" outlined></v-select>
            </v-col>
            <v-col cols="12" xs="6" md="8">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="blue-grey" class="mr-2" icon v-bind="attrs" v-on="on" @click="setSizeEditor(480)">
                            <v-icon>mdi-cellphone</v-icon>
                        </v-btn>
                    </template>
                    <span>Celular ( 480px )</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="blue-grey" class="mr-2" icon v-bind="attrs" v-on="on" @click="setSizeEditor(768)">
                            <v-icon>mdi-tablet</v-icon>
                        </v-btn>
                    </template>
                    <span>Tablet ( 768px )</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="blue-grey" class="mr-2" icon v-bind="attrs" v-on="on" @click="setSizeEditor(1366)">
                            <v-icon>mdi-laptop</v-icon>
                        </v-btn>
                    </template>
                    <span>Notebook ( 1366px )</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="blue-grey" class="mr-2" icon v-bind="attrs" v-on="on" @click="setSizeEditor('100%')">
                            <v-icon>mdi-monitor</v-icon>
                        </v-btn>
                    </template>
                    <span>Computador ( 100% )</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="blue-grey" icon v-bind="attrs" v-on="on" @click="sizeFullSize()">
                            <v-icon>mdi-monitor-screenshot</v-icon>
                        </v-btn>
                    </template>
                    <span>Tela Inteira</span>
                </v-tooltip>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div :style="editorStyle">
                    <ckeditor :editor="editor" class="editor" @ready="onEditorReady" :config="editorConfig"
                        v-model="bodyLayout"></ckeditor>
                </div>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-dialog v-model="dialog" fullscreen hide-overlay class="dialogEditor" transition="dialog-bottom-transition">
                <v-card>
                    <v-toolbar dark color="primary" dense>
                        <v-btn icon dark @click="dialog = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>Editor Tela Cheia</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-row>
                        <v-col cols="12" xs="6" md="2">
                            <v-select :items="fields_replace" dense class="seelctFieldsReplace mt-5" item-text="text"
                                item-value="value" label="Campo para substituição" @change="insertDataEditor"
                                v-model="field_replace" :hide-details="true" outlined></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <div :style="editorStyle" class="editorFull">
                                <ckeditor :editor="editor" class="editorFull2" @ready="onEditorReady" :config="editorConfig"
                                    v-model="bodyLayout"></ckeditor>
                            </div>
                        </v-col>
                    </v-row>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>
<script>

import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@/ckeditor-custom/ckeditor.js";

import api from "@/api/layout";

export default {
    name: 'EmailEditor',
    components: {
        ckeditor: CKEditor.component,
    },
    props: {
        body: String,
        placeholder: {
            type: String,
            default: 'Coloque aqui o Modelo de Email',
            required: false
        },
        updateBody: Function,
        required_fields: [],
    },
    computed: {
        bodyLayout: {
            // getter
            get: function () {
                return this.body
            },
            // setter
            set: function (newValue) {
                this.updateBody(newValue);
            }
        },
        requiredFields: {
            get: function () {
                return this.required_fields;
            },
            set: function (value) {
                console.log(value, 'aqui');
            },
        }
    },
    data: function () {
        return {
            dialog: false,
            editor: ClassicEditor,
            editorData: "",
            //body: "",
            editorObject: {},
            editorStyle: {
                width: '100%',
            },
            editorConfig: {
                placeholder: this.placeholder,
                extraPlugins: [MyCustomUploadAdapterPlugin],
                removePlugins: ['ImageCaption'],
                image: {
                    // You need to configure the image toolbar, too, so it shows the new style
                    // buttons as well as the resize buttons.
                    toolbar: [
                        'imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight',
                        '|',
                        'imageTextAlternative',
                        '|',
                        'resizeImage',
                        '|',
                        'linkImage',
                    ]
                },
            },
            fields_replace: [
                { value: '0', header: "Indentificação", divider: true },
                { value: '{{nome}}', text: "Nome" },
                { value: '{{documento}}', text: "Documento" },
                { value: '{{nascimento}}', text: "Nascimento" },
                { value: '0', header: "Contato", divider: true },
                { value: '{{celular}}', text: "Celular" },
                { value: '{{telefone}}', text: "Telefone" },
                { value: '{{email}}', text: "Email" },
                { value: '{{email2}}', text: "Email Secundario" },
                { value: '0', header: "Endereço", divider: true },
                { value: '{{cep}}', text: "CEP" },
                { value: '{{endereco}}', text: "Endereço" },
                { value: '{{numero}}', text: "Número" },
                { value: '{{complemento}}', text: "Complemento" },
                { value: '{{bairro}}', text: "Bairro" },
                { value: '{{estado}}', text: "Estado" },
                { value: '{{cidade}}', text: "Cidade" },
            ],
            field_replace: '',
        }
    },
    watch: {
        'bodyLayout': function (newValue, oldValue) {
            const getImagesTags = data => {
                return Array.from(new DOMParser().parseFromString(data, 'text/html')
                    .querySelectorAll('img'))
                    .map(img => img.getAttribute('src'))
                    .filter(img => img !== null)
            }
            let imagesOld = getImagesTags(oldValue);
            let imagesNew = getImagesTags(newValue);
            if (imagesNew.length < imagesOld.length) {
                this.deleteImage(imagesOld.filter(x => !imagesNew.includes(x)));
            }
        },
        'requiredFields': function (valueA, valueB) {
            const fields = valueA.map(item => ({ value: `{{${item.key}}}`, text: `${item.name}` }))
            this.updateFieldsReplace(fields)
        },
    },
    created() {
    },
    methods: {
        setSizeEditor(size) {
            if (size === '100%') {
                // Size = tamanho do monitor
                this.editorStyle = { width: size };
            } else {
                // size é igual ao pixel passado via parametro
                this.editorStyle = { width: `${size}px` };
            }
        },
        sizeFullSize() {
            this.dialog = true
        },
        insertDataEditor() {
            const content = `&nbsp;<strong> ${this.field_replace} </strong>&nbsp;`;
            const viewFragment = this.editorObject.data.processor.toView(content);
            const modelFragment = this.editorObject.data.toModel(viewFragment);
            this.editorObject.model.insertContent(modelFragment);
            this.editorObject.editing.view.focus();
            this.field_replace = null;
        },
        onEditorReady(editor) {
            this.editorObject = editor;
        },
        async deleteImage(arrayData) {
            if (arrayData.length > 0) {
                let image = arrayData[0];
                image = image.split('/').reverse()[0];
                this.showLoading();
                try {
                    let response = api.deleteImage(image);
                } catch (error) {
                    this.showAlert({ message: error.message, type: "error" });
                } finally {
                    this.hideLoading();
                }
            }
        },
        async updateFieldsReplace(fields) {
            this.fields_replace = [];
            this.fields_replace = fields;
        }
    }
}

function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        // Configure the URL to the upload script in your back-end here!
        return new MyUploadAdapter(loader);
    };
};

class MyUploadAdapter {
    constructor(loader) {
        // The file loader instance to use during the upload.
        this.loader = loader;
    }

    // Starts the upload process.
    upload() {
        return this.loader.file
            .then(file => new Promise(async (resolve, reject) => {
                let formData = new FormData();
                formData.append("image", file);
                try {
                    let response = await api.storeFile(formData);
                    resolve({
                        default: response.default
                    });
                } catch (error) {
                    console.error(error);
                }
            }));
    }

    // Aborts the upload process.
    abort() {
    }
}
</script>

<style>
.ck-editor__editable {
    min-height: calc(100vh - 500px);
    max-height: calc(100vh - 450px);
}

.editorFull .ck-editor__editable {
    min-height: calc(100vh - 210px) !important;
    max-height: calc(100vh - 210px) !important;
}
</style>
