import baseApi from "./baseApi";

const endpoint = "contact-list";

export default {
  get: page => baseApi.get(endpoint, page),
  show: id => baseApi.show(endpoint, id),
  getAll: () => baseApi.get(`${endpoint}/all`),
  store: data => baseApi.store(endpoint, data),
  update: (id, data) => baseApi.update(endpoint, id, data),
  storeFile: (data) => baseApi.storeFile(`${endpoint}/upload`, data),
  postContacts: (id, data) => baseApi.store(`${endpoint}/${id}/contacts`, data),
  getContacts: (id) => baseApi.get(`${endpoint}/${id}/contacts`),
  export: (id) => baseApi.get(`${endpoint}/${id}/export-contacts`),
  destroy: id => baseApi.destroy(endpoint, id),
};
