<template>
    <v-data-table :headers="headers" :items="items" :search="search" fixed-header multi-sort :loading="loading"
        no-results-text="Nenhum registro encontrado" loading-text="Carregando" :sort-by="sortBy" class="elevation-1"
        :footer-props="{
            itemsPerPageOptions: [10, 20, 30, 40, 50, 100, 200],
            itemsPerPageText: 'Itens por página',
            showCurrentPage: true
        }">
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>
                    <v-icon>{{ icon }}</v-icon>
                    {{ title }}
                </v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-row align="center">
                    <v-checkbox v-if="showDisabled" v-model="selectedShowDisabled" @change="onSelectedShowDisabled"
                        label="Mostrar Inativos" hide-details class="shrink mr-2 mt-0" value="1"></v-checkbox>
                    <v-spacer></v-spacer>
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Procurar" single-line
                        hide-details></v-text-field>
                </v-row>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-btn color="green" dark class="mb-2" :to="linkNewButton" v-if="textNewButton">
                    <v-icon left>
                        mdi-plus
                    </v-icon>
                    {{ (textNewButton || textButton) }}
                </v-btn>
            </v-toolbar>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:footer.page-text="items"> {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength
        }}
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.actions="{ item }">
            <slot name="actions" v-bind:item="item"></slot>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.enabled="{ item }">
            <v-chip :color="getColor(item.enabled)" dark>
                {{ getTextEnabled(item.enabled) }}
            </v-chip>
        </template>

        <template v-slot:no-data>
            <v-btn color="primary" @click="refreshTable">
                Recarregar
            </v-btn>
        </template>
    </v-data-table>
</template>
<script>
export default {
    props: {
        title: String,
        icon: String,
        textButton: String,
        textNewButton: String,
        linkNewButton: String,
        headers: Array,
        sortBy: Array,
        items: Array,
        showDisabled: Boolean,
        onSelectedShowDisabled: Function,
        refreshTable: Function,
        loading: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({ search: '', selectedShowDisabled: false }),

    computed: {},

    watch: {},

    created() {
    },

    methods: {
        getColor(enabled) {
            return (enabled == 1 ? 'green' : 'red');
        },
        getTextEnabled(enabled) {
            return (enabled == 1 ? 'Ativo' : 'Inativo');
        }
    },
}
</script>
